export const UPLOAD_IMAGES_REQ = 'UPLOAD_IMAGES_REQ';

export const ADD_IMAGE_TO_QUEUE = 'ADD_IMAGE_TO_QUEUE';
export const REMOVE_IMAGE_FROM_QUEUE = 'REMOVE_IMAGE_FROM_QUEUE';

export const UPLOAD_START = 'UPLOAD_START';
export const UPLOAD_PENDING = 'UPLOAD_PENDING';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE';
export const UPLOAD_FAILED = 'UPLOAD_FAILED';
export const UPLOAD_FINISHED_WITH_FAIL = 'UPLOAD_FINISHED_WITH_FAIL';
export const REUPLOAD_FAILDED_IMAGES = 'REUPLOAD_FAILDED_IMAGES';
export const UPLOAD_WARNING = 'UPLOAD_WARNING';
