const convertToBool = env => {
    const flag = process.env[env] || null;
    return flag === 'yes' || flag === 'true';
};

const isMobius = () => {
    return convertToBool('REACT_APP_IS_MOBIUS');
};

export { isMobius };
