export const hasAuthCheck = state => state.auth.authCheckComplete;
export const isLogged = state => state.auth.isAuthenticated;
export const isAuthProgress = state => state.auth.isLoggingIn;
export const getAuthFailMessage = state => state.auth.authFailMessage;
export const isAuthFail = state => state.auth.hasAuthFail;
export const getCurrentUser = state => state.auth.currentUser;

export const isConsentGiven = state => {
    return state.auth.currentUser.consentGiven;
};

export const selectors = {
    hasAuthCheck,
    isLogged,
    isAuthProgress,
    getAuthFailMessage,
    isAuthFail,
    getCurrentUser,
    isConsentGiven
};
