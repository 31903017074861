import log from 'loglevel';
import remote from 'loglevel-plugin-remote';

const API_HOST = process.env.REACT_APP_API_URL || '/api';

function format(logData) {
    return logData;
}

remote.apply(log, { level: 'error', format, url: `${API_HOST}/logging` });

log.enableAll();

export { log as logger };
