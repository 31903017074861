export const AUTH_LOGIN_REQ = 'AUTH_LOGIN_REQ';
export const AUTH_LOGOUT_REQ = 'AUTH_LOGOUT_REQ';
export const AUTH_CHECK_REQ = 'AUTH_CHECK_REQ';
export const AUTH_GOOGLE_LOGIN_REQ = 'AUTH_GOOGLE_LOGIN_REQ';
export const AUTH_GOOGLE_LOGIN_REDIRECT = 'AUTH_GOOGLE_LOGIN_REDIRECT';

export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

export const AUTH_CHECK_START = 'AUTH_CHECK_START';
export const AUTH_CHECK_SUCCESS = 'AUTH_CHECK_SUCCESS';
export const AUTH_CHECK_ERROR = 'AUTH_CHECK_ERROR';

export const AUTH_LOGIN_START = 'AUTH_LOGIN_START';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_SET_TOKENS = 'AUTH_SET_TOKENS';

export const ADD_CURRENT_USER_UPLOAD = 'ADD_CURRENT_USER_UPLOAD';
export const SET_CONSENT = 'SET_CONSENT';
export const UPDATE_CONSENT = 'UPDATE_CONSENT';
export const ONBOARD_USER = 'ONBOARD_USER';
export const ONBOARD_USER_SUCCESS = 'ONBOARD_USER_SUCCESS';
export const ONBOARD_USER_ERROR = 'ONBOARD_USER_ERROR';
