import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import { logger } from '../../utils/logging';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error) {
        logger.error(error);
    }

    render() {
        const { hasError } = this.state;
        const { children, rootBoundary = false } = this.props;
        return hasError ? (
            <Paper style={{ padding: 24, backgroundColor: rootBoundary ? 'white' : '#222326' }}>
                <Typography variant="h4" style={{ color: rootBoundary ? 'black' : 'white' }}>
                    An error has occurred during rendering the page.
                </Typography>
            </Paper>
        ) : (
            children
        );
    }
}

export { ErrorBoundary };
