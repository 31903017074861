/* eslint-disable react/prefer-stateless-function */
import { PropTypes } from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { RouterContainer } from './RouterContainer';

const Root = ({ store, history }) => {
    return (
        <Provider store={store}>
            <RouterContainer history={history} />
        </Provider>
    );
};

Root.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export { Root };
