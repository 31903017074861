/* eslint-disable no-restricted-syntax */
import { all, call, put, select, take } from 'redux-saga/effects';
import {
    createUser,
    deactivateUser,
    getAdminUserCount,
    getAdminUserList,
    getUser,
    updateUser,
} from '../../api';
import { USER_CREATE } from '../../routes/routes';
import { USER_ITEMS_PER_PAGE } from '../../utils/config';
import { actions } from '../actions';
import {
    CREATE_USER_REQUEST,
    DEACTIVATE_USER,
    GET_USER_LIST,
    GET_USER_REQUEST,
    SET_USER_REQUEST,
} from '../actions/actionTypes/user';

function* getUserList({ page, filter }) {
    const params = {
        offset: page !== 0 ? (page - 1) * USER_ITEMS_PER_PAGE : 0,
        limit: USER_ITEMS_PER_PAGE,
        ...filter,
    };

    const [{ count }, userList] = yield all([
        call(getAdminUserCount, filter),
        call(getAdminUserList, params),
    ]);
    yield put(
        actions.updateUserList(userList, count, Math.ceil(count / USER_ITEMS_PER_PAGE), {
            page,
            filter: {
                page,
                ...filter,
            },
        })
    );
}

function* watchGetUserList() {
    while (true) {
        const {
            payload: { page, filter },
        } = yield take(GET_USER_LIST);

        yield call(getUserList, { page, filter });
    }
}

function* watchGetUser() {
    while (true) {
        const {
            payload: { id },
        } = yield take(GET_USER_REQUEST);
        try {
            const user = yield call(getUser, id);
            yield put(actions.getUserSuccess(user));
        } catch (e) {
            yield put(actions.getUserError(e));
        }
    }
}

function* watchSetUser() {
    while (true) {
        const { payload } = yield take(SET_USER_REQUEST);
        const { user } = payload;
        try {
            if (user.id) {
                yield call(updateUser, user);
            } else {
                yield call(createUser, user);
            }
            yield put(actions.setUserSuccess());
        } catch (e) {
            yield put(actions.setUserError(e));
        }
    }
}

function* watchCreateUserRequest(params) {
    while (true) {
        const { history } = params;
        yield take(CREATE_USER_REQUEST);
        history.push(USER_CREATE.pathname);
    }
}

function* watchDeactivateUser() {
    while (true) {
        const {
            payload: { id },
        } = yield take(DEACTIVATE_USER);
        yield call(deactivateUser, id);
        const { filter } = yield select(state => state.user.filters);
        yield call(getUserList, filter);
    }
}

export const sagas = params =>
    all([
        watchGetUserList(),
        watchGetUser(),
        watchSetUser(),
        watchCreateUserRequest(params),
        watchDeactivateUser(),
    ]);
