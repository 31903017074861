import { isArray } from 'lodash';
import { PREDICTION_COLOR_THRESHOLDS } from './config';
import { PEOPLE_TAGS } from './peopleTags';

export function groupConcepts(predict) {
    const custom = predict.tags.customConcepts || [];
    const standard = predict.tags.standardConcepts;
    const customCategory =
        custom && custom.length > 0
            ? [
                  {
                      category: 'custom',
                      items: [...custom].sort((a, b) => a?.name?.localeCompare(b?.name)),
                  },
              ]
            : [];
    if (predict.params.tags?.standardConcepts.categoriesEnabled) {
        return [...customCategory, ...standard];
    }
    const standardCategory =
        standard && standard.length > 0
            ? [
                  {
                      category: 'standard',
                      items: standard,
                  },
              ]
            : [];
    return [...customCategory, ...standardCategory];
}

// tag: string
export function isPersonTag(tag) {
    return PEOPLE_TAGS.includes(tag.toLowerCase());
}

export function orderBoundingBoxes(faces, selectedFace) {
    const boxes = faces.map((face, i) => ({ boundingBox: face.boundingBox, i }));
    if (boxes.length > 0 && selectedFace !== -1 && selectedFace < boxes.length) {
        // place active bounding box on top
        return [
            ...boxes.slice(0, selectedFace),
            ...boxes.slice(selectedFace + 1),
            boxes[selectedFace],
        ];
    }
    return boxes;
}

const getOffsets = (container, width, height) => {
    let imageHeight = 0,
        imageWidth = 0;
    if (width >= height) {
        imageHeight = Math.min(container.clientHeight, (container.clientWidth / width) * height);
        imageWidth = (imageHeight / height) * width;
    } else {
        imageWidth = Math.min(container.clientWidth, (container.clientHeight / height) * width);
        imageHeight = (imageWidth / width) * height;
    }
    return {
        left: (container.clientWidth - imageWidth) / 2,
        width: imageWidth,
        top: (container.clientHeight - imageHeight) / 2,
        height: imageHeight,
    };
};

export const getFaceChipPosition = (box, container, width, height) => {
    const offsets = getOffsets(container, width, height);
    const top = (box.lower / height) * offsets.height + offsets.top;
    const left = Math.max(0, (box.left / width) * offsets.width + offsets.left - 20);
    return {
        top,
        left,
    };
};

export const getTagsCount = tags => {
    if (!tags || tags.length === 0) {
        return 0;
    }

    return tags.standardConcepts.reduce((acc, conceptGroup) => {
        return acc + conceptGroup.items.length;
    }, tags.customConcepts?.length || 0);
};

export const getPredictionColor = score => {
    return PREDICTION_COLOR_THRESHOLDS.find(step => {
        return step.threshold <= score;
    }).color;
};

export const renderAge = age => {
    if (isArray(age)) {
        return age.join('-');
    } else {
        return age;
    }
};
