import React from 'react';
import { dialogTypes } from '../../utils/constants';
import { ConclusionDialog } from './ConclusionDialog';
import { ConsentForm } from './ConsentForm';
import { CreateConceptDialog } from './CreateConceptDialog';
import { DeleteConceptConfirmation } from './DeleteConceptConfirmation';
import { DeletePredictionConfirmation } from './DeletePredictionConfirmation';
import { DeleteSearchImagesConfirmation } from './DeleteSearchImagesConfirmation';
import { DeleteVideoConfirmation } from './DeleteVideoConfirmation';
import { DemoThresholdInfo } from './DemoThresholdInfo';
import { ErrorInfo } from './ErrorInfo';
import { MoveImagesDialog } from './MoveImagesDialog';
import { PublishConceptConfirmation } from './PublishConceptConfirmation';
import { RemoveImagesDialog } from './RemoveImagesDialog';
import { SuccessInfo } from './SuccessInfo';
import { ThresholdConceptConfirmation } from './ThresholdConceptConfirmation';
import { TutorialDialog } from './TutorialDialog';
import { UploadForm } from './UploadForm';
import { UploadVideoForm } from './UploadVideoForm';
import { WarningInfo } from './WarningInfo';

const config = {
    [dialogTypes.deleteConfigConfirmation]: DeleteConceptConfirmation,
    [dialogTypes.predictionDeleteConfirmation]: DeletePredictionConfirmation,
    [dialogTypes.conceptPublishConfirmation]: PublishConceptConfirmation,
    [dialogTypes.conceptThresholdConfirmation]: ThresholdConceptConfirmation,
    [dialogTypes.conceptCreate]: CreateConceptDialog,
    [dialogTypes.deleteImages]: RemoveImagesDialog,
    [dialogTypes.deleteVideoConfirmation]: DeleteVideoConfirmation,
    [dialogTypes.moveImages]: MoveImagesDialog,
    [dialogTypes.success]: SuccessInfo,
    [dialogTypes.error]: ErrorInfo,
    [dialogTypes.warning]: WarningInfo,
    [dialogTypes.consent]: ConsentForm,
    [dialogTypes.demoUpload]: UploadForm,
    [dialogTypes.demoThresholdInfo]: DemoThresholdInfo,
    [dialogTypes.uploadVideo]: UploadVideoForm,
    [dialogTypes.tutorialDialog]: TutorialDialog,
    [dialogTypes.conclusionDialog]: ConclusionDialog,
    [dialogTypes.deleteSearchImagesConfirmation]: DeleteSearchImagesConfirmation,
};

export const renderDialog = dialogProps => {
    const Component = config[dialogProps.type];
    if (Component) {
        return <Component {...dialogProps} />;
    }

    return null;
};
