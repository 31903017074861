import { findIndex } from 'lodash';
import { UPLOADS_PAGE_SHOWN_ITEMS } from '../../utils/config';
import {
    NEW_VIDEO_UPLOAD,
    SET_VIDEO_UPLOADS,
    UPDATE_VIDEO_GALLERY,
    UPDATE_VIDEO_GALLERY_ITEM,
    UPDATE_VIDEO_UPLOADS,
    videoPrefixes,
} from '../actions/actionTypes/video';

export const initialState = {
    tagging: {
        gallery: null,
        uploads: [],
    },
    faceRecognition: {
        gallery: null,
        uploads: [],
    },
    shotDetection: {
        gallery: null,
        uploads: [],
    },
    highlights: {
        gallery: null,
        uploads: [],
    },
};

function updateUpload(uploads, i, update) {
    const updatedUpload = update(uploads[i]);
    let newUploads;
    if (updatedUpload) {
        newUploads = [...uploads.slice(0, i), updatedUpload, ...uploads.slice(i + 1)];
    } else {
        newUploads = [...uploads.slice(0, i), ...uploads.slice(i + 1)];
    }
    if (newUploads.length > UPLOADS_PAGE_SHOWN_ITEMS) {
        for (let j = 0; j < newUploads.length - UPLOADS_PAGE_SHOWN_ITEMS; j++) {
            newUploads[j] = {
                ...newUploads[j],
                src: null,
            };
        }
    }
    return newUploads;
}

function updateGalleryItem(gallery, { folder, name, prediction }) {
    const folderIndex = findIndex(gallery[folder], item => item.name === name);
    const newGallery = { ...gallery };
    newGallery[folder][folderIndex] = { ...newGallery[folder][folderIndex], predict: prediction };
    return newGallery;
}

const videoReducer = (state = initialState, action) => {
    const { payload } = action;
    let key = null;
    switch (action.type) {
        case `${videoPrefixes.tagging}_${UPDATE_VIDEO_UPLOADS}`:
        case `${videoPrefixes.tagging}_${SET_VIDEO_UPLOADS}`:
        case `${videoPrefixes.tagging}_${UPDATE_VIDEO_GALLERY_ITEM}`:
        case `${videoPrefixes.tagging}_${UPDATE_VIDEO_GALLERY}`:
        case `${videoPrefixes.tagging}_${NEW_VIDEO_UPLOAD}`:
            key = 'tagging';
            break;
        case `${videoPrefixes.faceRecognition}_${UPDATE_VIDEO_UPLOADS}`:
        case `${videoPrefixes.faceRecognition}_${SET_VIDEO_UPLOADS}`:
        case `${videoPrefixes.faceRecognition}_${UPDATE_VIDEO_GALLERY_ITEM}`:
        case `${videoPrefixes.faceRecognition}_${UPDATE_VIDEO_GALLERY}`:
        case `${videoPrefixes.faceRecognition}_${NEW_VIDEO_UPLOAD}`:
            key = 'faceRecognition';
            break;
        case `${videoPrefixes.shotDetection}_${UPDATE_VIDEO_UPLOADS}`:
        case `${videoPrefixes.shotDetection}_${SET_VIDEO_UPLOADS}`:
        case `${videoPrefixes.shotDetection}_${UPDATE_VIDEO_GALLERY_ITEM}`:
        case `${videoPrefixes.shotDetection}_${UPDATE_VIDEO_GALLERY}`:
        case `${videoPrefixes.shotDetection}_${NEW_VIDEO_UPLOAD}`:
            key = 'shotDetection';
            break;
        case `${videoPrefixes.highlights}_${UPDATE_VIDEO_UPLOADS}`:
        case `${videoPrefixes.highlights}_${SET_VIDEO_UPLOADS}`:
        case `${videoPrefixes.highlights}_${UPDATE_VIDEO_GALLERY_ITEM}`:
        case `${videoPrefixes.highlights}_${UPDATE_VIDEO_GALLERY}`:
        case `${videoPrefixes.highlights}_${NEW_VIDEO_UPLOAD}`:
            key = 'highlights';
            break;
        default:
            break;
    }

    if (!key) {
        return state;
    }

    switch (action.type) {
        case `${videoPrefixes[key]}_${UPDATE_VIDEO_UPLOADS}`:
            return {
                ...state,
                [key]: {
                    ...state[key],
                    uploads: updateUpload(state[key].uploads, payload.index, payload.update),
                },
            };
        case `${videoPrefixes[key]}_${NEW_VIDEO_UPLOAD}`:
            return {
                ...state,
                [key]: {
                    ...state[key],
                    uploads: [payload.upload, ...state[key].uploads],
                },
            };
        case `${videoPrefixes[key]}_${SET_VIDEO_UPLOADS}`:
            return {
                ...state,
                [key]: {
                    ...state[key],
                    uploads: [...payload.uploads],
                },
            };
        case `${videoPrefixes[key]}_${UPDATE_VIDEO_GALLERY_ITEM}`:
            return {
                ...state,
                [key]: {
                    ...state[key],
                    gallery: updateGalleryItem(state[key].gallery, payload),
                },
            };

        case `${videoPrefixes[key]}_${UPDATE_VIDEO_GALLERY}`:
            return {
                ...state,
                [key]: {
                    ...state[key],
                    gallery: { ...payload.gallery },
                },
            };
        default:
            return state;
    }
};

export { videoReducer };
