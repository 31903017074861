import { all } from 'redux-saga/effects';
import * as app from './app';
import * as auth from './auth';
import * as concept from './concept';
import * as concepts from './concepts';
import * as dialog from './dialog';
import { imageFaceRecognitionSaga, imageLogoDetectionSaga, imageTaggingSaga } from './imageDemo';
import * as search from './search';
import * as share from './share';
import * as upload from './upload';
import * as user from './user';
import {
    videoFaceRecognitionSaga,
    videoHighlightsSaga,
    videoShotDetectionSaga,
    videoTaggingSaga,
} from './video';

const composeSagas = (params, src) => all(src.map(d => d.sagas(params)));

export const sagas = params => {
    return function* root() {
        yield all([
            composeSagas(params, [
                app,
                concepts,
                concept,
                auth,
                dialog,
                upload,
                imageTaggingSaga,
                imageFaceRecognitionSaga,
                imageLogoDetectionSaga,
                videoTaggingSaga,
                videoFaceRecognitionSaga,
                videoShotDetectionSaga,
                videoHighlightsSaga,
                user,
                share,
                search,
            ]),
        ]);
    };
};
