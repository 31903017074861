import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    logo: {
        height: 26,
        marginBottom: theme.spacing(2),
    },
}));

function CompanyName() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <img src="/assets/mobius_logo.svg" alt="" className={classes.logo} />
        </div>
    );
}

export { CompanyName };
