import { FormControl, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const UsernameFormControl = ({ disabled, onEnter, username, onChangeUsername, skipValidation }) => {
    const setUsername = e => {
        onChangeUsername({
            value: e.currentTarget.value,
            error: skipValidation ? false : !e.currentTarget.value,
            message: username.message,
        });
    };

    return (
        <FormControl margin="dense" disabled={disabled} fullWidth>
            <TextField
                data-test="login-username"
                inputProps={{
                    onKeyUp: onEnter,
                }}
                id="username"
                name="username"
                placeholder="Username"
                autoComplete="username"
                autoFocus
                value={username.value}
                onChange={setUsername}
                helperText={username.message}
                error={username.error}
            />
        </FormControl>
    );
};

UsernameFormControl.propTypes = {
    onEnter: PropTypes.func.isRequired,
    onChangeUsername: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    username: PropTypes.object.isRequired,
    skipValidation: PropTypes.bool,
};

UsernameFormControl.defaultProps = {
    disabled: false,
    skipValidation: false,
};

export { UsernameFormControl };
