import * as app from './app';
import * as auth from './auth';
import * as concepts from './concepts';
import * as dialog from './dialog';
import * as upload from './upload';

const composeExports = (src, key) =>
    src.map(d => ({ ...d[key] })).reduce((acc, curr) => ({ ...acc, ...curr }), {});

export const selectors = composeExports(
    [app, concepts, auth, dialog, upload],
    'selectors'
);
