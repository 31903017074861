export const FETCH_RESOURCES_REQ = 'FETCH_RESOURCES_REQ';
export const CREATE_CONCEPT_REQ = 'CREATE_CONCEPT_REQ';

export const PUBLISH_CONCEPT_REQ = 'PUBLISH_CONCEPT_REQ';
export const PUBLISH_CONCEPT_CONFIRMED_REQ = 'PUBLISH_CONCEPT_CONFIRMED_REQ';

export const THRESHOLD_CONCEPT_REQ = 'THRESHOLD_CONCEPT_REQ';
export const THRESHOLD_CONCEPT_CONFIRMED_REQ = 'THRESHOLD_CONCEPT_CONFIRMED_REQ';

export const CREATE_CONCEPT_CONFIRMED_REQ = 'CREATE_CONCEPT_CONFIRMED_REQ';
export const REMOVE_CONCEPT_REQ = 'REMOVE_CONCEPT_REQ';
export const REMOVE_CONCEPT_CONFIRMED_REQ = 'REMOVE_CONCEPT_CONFIRMED_REQ';
