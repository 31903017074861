import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React from 'react';
import { ErrorBoundary } from '../components/ui/ErrorBoundary';
import { AppContainer } from '../containers/AppContainer';
import { darkTheme } from '../theme';
import { useHubspotPageView } from '../utils/hubspot';
import { QueryClientProvider, QueryClient } from 'react-query';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();

const App = ({ children, ...props }) => {
    useHubspotPageView();
    return (
        <>
            <MuiThemeProvider theme={darkTheme}>
                <CssBaseline />
                <ErrorBoundary rootBoundary>
                    <QueryClientProvider client={queryClient}>
                        <AppContainer {...props}>{children}</AppContainer>
                        <ToastContainer position="bottom-right" limit={5} autoClose={3000} />
                    </QueryClientProvider>
                </ErrorBoundary>
            </MuiThemeProvider>
        </>
    );
};

App.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
};

export { App };
