import {
    GET_SHARED_PREDICTIONS_SUCCESS,
    SET_SHARE_DRAWER_VISIBILITY,
    SET_SHARE_POPOVER_VISIBILITY
} from '../actions/actionTypes/share';

const initialState = {
    sharedData: null,
    isPopoverShown: false,
    isShareDrowerShown: false,
    latestShareLink: ''
};

const shareReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_SHARED_PREDICTIONS_SUCCESS:
            return { ...state, sharedData: payload };

        case SET_SHARE_POPOVER_VISIBILITY:
            return {
                ...state,
                isPopoverShown: payload.opened,
                latestShareLink: payload.opened ? payload.latestShareLink : state.latestShareLink
            };

        case SET_SHARE_DRAWER_VISIBILITY: {
            return { ...state, isShareDrowerShown: payload.opened };
        }

        default:
            return state;
    }
};

export { shareReducer };
