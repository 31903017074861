// MATERIAL UI
import React from 'react';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { VisibilityOutlined } from '@material-ui/icons';
import { actions } from '../../redux/actions';
import { colors } from '../../theme/colors';

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        maxWidth: 590,
        padding: theme.spacing(0),
        overflow: 'hidden',
    },

    title: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(3, 4, 3),
        '&>h4': {
            marginLeft: theme.spacing(2),
        },
    },

    image: {
        width: '100%',
    },

    bodyContainer: {
        margin: theme.spacing(0),
        padding: theme.spacing(0),
    },

    description: {
        margin: theme.spacing(0, 4, 7),
    },

    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(9, 4, 3),
        '&>p': {
            color: colors.GREY,
        },
    },
    buttonSkip: {
        minWidth: 59,
        marginLeft: theme.spacing(9),
    },
    buttonContinue: {
        minWidth: 89,
        marginLeft: theme.spacing(2),
    },
}));

type TutorialDialogProps = {
    onClose: (action: string) => void;
    action: string;
    startTutorial: (params: boolean) => void;
    onboardUser: (params: { type: string; step: string }) => void;
};

const TutorialDialogBase = (props: TutorialDialogProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { onClose, action, startTutorial, onboardUser } = props;

    const handleClose = () => {
        onboardUser({ type: 'image_tagging', step: 'done' });
        onClose(action);
    };

    const handleContinue = () => {
        startTutorial(true);
        onClose(action);
    };

    return (
        <div data-test="demo-tutorial-dialog" className={classes.dialogContainer}>
            <div className={classes.title}>
                <VisibilityOutlined />
                <Typography variant="h4">{t('imageDemo.tutorial.dialog.title')}</Typography>
            </div>
            <div className={classes.bodyContainer}>
                <Typography variant="h5" className={classes.description}>
                    <Trans
                        i18nKey="imageDemo.tutorial.dialog.container"
                        components={{
                            vResult: <b />,
                        }}
                    />
                </Typography>
                <Box>
                    <img className={classes.image} src={'/assets/tutorial-dialog.png'} alt="" />
                </Box>
            </div>
            <div className={classes.buttonContainer}>
                <Typography variant="body2">{t('imageDemo.tutorial.dialog.footer')}</Typography>
                <Button
                    className={classes.buttonSkip}
                    variant="outlined"
                    onClick={handleClose}
                    data-test="tutorial-dialog-skip-button"
                >
                    {t('imageDemo.tutorial.dialog.skip')}
                </Button>
                <Button
                    className={classes.buttonContinue}
                    variant="contained"
                    onClick={handleContinue}
                >
                    {t('imageDemo.tutorial.dialog.continue')}
                </Button>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onboardUser: (params: { type: string; step: string }) =>
            dispatch(actions.onboardUser(params)),
        startTutorial: (params: boolean) =>
            dispatch(actions.imageTaggingActions.startTutorialRequest(params)),
    };
};

export const TutorialDialog = connect(null, mapDispatchToProps)(TutorialDialogBase);
