import { DIALOG_CLOSE_REQ, DIALOG_OPEN_REQ } from './actionTypes/dialog';

export const showDialog = params => ({ type: DIALOG_OPEN_REQ, params });
export const closeDialog = ({ dialogAction, dialogActionParams }) => ({
    type: DIALOG_CLOSE_REQ,
    dialogAction,
    dialogActionParams
});

export const actions = {
    showDialog,
    closeDialog
};
