export const industriesEn = {
    explore: 'Explore',
    press: {
        title: 'Our recommended features list for <strong>Press & Broadcasting</strong> agencies.',
        tagging: {
            description: 'Detect socio-political trends, movements, concepts, and more. ',
            card1: {
                title: 'Precise Analysis',
                description:
                    'From specific objects to abstract concepts like body positivity or gender equality.',
            },
            card2: {
                title: 'Easy <br/>to train',
                description: 'Keep up with changing trends by easily creating custom models.',
            },
        },
        id: {
            description: 'Recognize personalities in huge archives with just 1 reference image.',
            card1: {
                title: 'Facial Recognition',
                description: 'Find visuals based on specific public figures and personas.',
            },
            card2: {
                title: '1-shot learning',
                description: 'Add new faces to your systems with only 1 reference image.',
            },
        },
        search: {
            description:
                'Search for specific demographics, brands, historical & political events.  ',
            card1: {
                title: 'Perfect Match',
                description: 'Easily find creators who match your brand and campaigns.',
            },
            card2: {
                title: 'Hybrid Search',
                description:
                    'Find aesthetically similar visuals by searching with a reference image.',
            },
        },
    },

    internet2print: {
        title: 'Our recommended features list to optimize <strong>Internet to Print</strong> platforms.',
        tagging: {
            description:
                'Detect memorable events, emotional portraits, printable moments, and more.',
            card1: {
                title: 'Sort by theme',
                description: 'Auto categorize customer images by emotion, events, aesthetics.',
            },
            card2: {
                title: 'Suggest & Recommend',
                description: 'Gather insights to recommend customized products.',
            },
        },
        id: {
            description: "Recognize everyone who's important.",
            card1: {
                title: 'Tags loved ones',
                description:
                    'Automatically recognize specific people in hundreds of customer images.',
            },
            card2: {
                title: 'Just 1 reference',
                description: 'Add new faces to your systems with only 1 reference image.',
            },
        },
    },
    imageArchives: {
        title: 'Our recommended features list to supercharge <strong>Image Archives</strong>.',
        concepts: {
            description: 'Easily build custom solutions that suit your exact business needs.',
            card1: {
                title: 'No-code Platform',
                description:
                    'Create custom tagging models on-the-go with a zero code user interface. ',
            },
            card2: {
                title: 'Minimal input required',
                description: 'New models can be created using only 20-40 reference images.',
            },
        },
        highlight: {
            description: 'Process videos and efficiently by capturing the best scenes.',
            card1: {
                title: 'Attractive Clips',
                description: 'Identify the best places in videos to place commercials.',
            },
            card2: {
                title: 'Shot Changes',
                description: 'Obtain automated time-stamps wherever a shot change occurs.',
            },
        },
        tagging: {
            description: 'Recognise concepts, trends, demographics, objects, and much more.',
            card1: {
                title: 'Precise Analysis',
                description: 'Built in with over 10,000 pre-trained, standard concepts.',
            },
            card2: {
                title: 'Easy to Install',
                description: 'Simple to integrate with your system and operations.',
            },
        },
        id: {
            description: 'Facial recognition with 11,000 public figures built in.',
            card1: {
                title: 'Facial Recognition',
                description: 'Find visuals based on specific public figures and personas.',
            },
            card2: {
                title: '1-shot learning',
                description: 'Add new faces to your systems with only 1 reference image.',
            },
        },
    },
};
