import {
    CREATE_USER_REQUEST,
    DEACTIVATE_USER,
    GET_USER_ERROR,
    GET_USER_LIST,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    SET_USER_ERROR,
    SET_USER_REQUEST,
    SET_USER_SUCCESS,
    UPDATE_USER_LIST,
    USER_REGISTERED,
    USER_VERIFIED,
} from './actionTypes/user';

export const getUserList = (page, filter) => {
    return { type: GET_USER_LIST, payload: { page, filter } };
};

export const updateUserList = (userList, count, pages, filter) => ({
    type: UPDATE_USER_LIST,
    payload: { userList, count, pages, filter },
});

export const setUser = user => {
    return {
        type: SET_USER_REQUEST,
        payload: { user },
    };
};

export const setUserSuccess = () => {
    return {
        type: SET_USER_SUCCESS,
    };
};

export const setUserError = error => {
    return {
        type: SET_USER_ERROR,
        payload: { error },
    };
};

export const getUser = id => {
    return {
        type: GET_USER_REQUEST,
        payload: { id },
    };
};

export const getUserSuccess = user => {
    return {
        type: GET_USER_SUCCESS,
        payload: { user },
    };
};

export const getUserError = error => {
    return {
        type: GET_USER_ERROR,
        payload: { error },
    };
};

export const deactivateUser = id => {
    return {
        type: DEACTIVATE_USER,
        payload: { id },
    };
};

export const createUserRequest = () => {
    return { type: CREATE_USER_REQUEST };
};

export const userRegistered = () => {
    return { type: USER_REGISTERED };
};

export const userVerified = () => {
    return { type: USER_VERIFIED };
};

export const actions = {
    updateUserList,
    getUserList,
    setUser,
    setUserSuccess,
    setUserError,
    deactivateUser,
    createUserRequest,
    userRegistered,
    userVerified,
    getUserSuccess,
    getUser,
    getUserError,
};
