import { Box, Button, Typography } from '@material-ui/core';
/* eslint-disable react/button-has-type */
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../redux/actions';
import { selectors } from '../../redux/selectors';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4, 6),
        fontSize: 18
    },
    guarantee: {
        fontWeight: 700
    },
    buttonFooter: {
        display: 'flex',
        justifyContent: 'center;'
    },
    button: {
        marginLeft: '24px'
    },
    link: {
        padding: 0,
        textDecoration: 'underline',
        fontWeight: 700,
        backgroundColor: 'transparent',
        fontSize: 16,
        color: theme.palette.secondary.main
    }
}));

const ConsentFormBase = ({ onClose, action, actionParams, setConsent, isConsentGiven }) => {
    const classes = useStyles();

    const handleConfirm = () => {
        setConsent();
    };

    useEffect(() => {
        if (isConsentGiven) {
            onClose(action, actionParams);
        }
    }, [action, actionParams, onClose, isConsentGiven]);

    return (
        <Box className={classes.root}>
            <Typography variant="h3">Usage of Mobius Vision API</Typography>
            <Typography paragraph />
            <Typography paragraph>
                <Typography variant="inherit">
                    By clicking the accept button, you agree that we,{' '}
                </Typography>
                <a
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                    href="https://mobiuslabs.com/imprint/">
                    Mobius Labs GmbH
                </a>
                <Typography variant="inherit">
                    , may analyze uploaded images or videos and process related personal data for
                    the purpose of using the Mobius Vision API.
                </Typography>
            </Typography>
            <Typography paragraph>
                <Typography variant="inherit">
                    This consent also refers to the processing of special categories of personal
                    data (‘sensitive data’), such as ethic origin or health data. However, metadata
                    (like dates or geotags etc.) contained in the uploaded file are not processed.
                    You may prevent the processing of personal data with effect for the future via
                    email to{' '}
                </Typography>
                <a className={classes.link} href="mailto:datenschutz@mobius.ml">
                    datenschutz@mobius.ml
                </a>
                . Uploaded data is not stored and we do not provide any backup for such data. For
                more information on data processing please refer to our{' '}
                <a
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                    href="https://mobiuslabs.com/privacy/">
                    privacy policy
                </a>
                .
            </Typography>
            <Typography paragraph>
                <Typography variant="inherit">
                    By uploading an image or video, you grant Mobius Labs GmbH the necessary rights
                    to use the uploaded content.
                </Typography>
            </Typography>
            <Box>
                <Typography className={classes.guarantee} variant="body1">
                    You guarantee that:{' '}
                </Typography>
                <Typography className={classes.guarantee} variant="body1">
                    1. you have gained the necessary consent from any and all affected individual
                    data subjects within the necessary scope,
                </Typography>
                <Typography className={classes.guarantee} variant="body1">
                    2. the uploaded file does not infringe any applicable laws, and
                </Typography>
                <Typography className={classes.guarantee} variant="body1">
                    3. the use of the Mobius Vision API does not violate any third-party rights.
                </Typography>
            </Box>
            <Typography paragraph />
            <Typography paragraph>
                <Typography variant="inherit">
                    Uploading any content that is unlawful, sexually obscene, discriminatory,
                    abusive, libelous, defamatory, fraudulent, harassing, threatening or infringes
                    proprietary rights of any third party is strictly prohibited. Third party claims
                    addressed to us which result from your interactions or violation to the above
                    shall be redirected to you and you shall hold us harmless and indemnified us for
                    any damages, claims, liabilities and expenses provided you are held responsible
                    for such.
                </Typography>
            </Typography>
            <div className={classes.buttonFooter}>
                <Button className={classes.button} variant="contained" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    data-test="image-demo-consent-accept"
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={handleConfirm}>
                    I accept
                </Button>
            </div>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        isConsentGiven: selectors.isConsentGiven(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setConsent: () => dispatch(actions.setConsent({ consent: true }))
    };
};

export const ConsentForm = connect(mapStateToProps, mapDispatchToProps)(ConsentFormBase);
