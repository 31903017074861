export const TRAIN_CONCEPT_REQ = 'TRAIN_CONCEPT_REQ_V2';
export const IMPROVE_CONCEPT_REQ = 'IMPROVE_CONCEPT_REQ_V2';
export const VALIDATE_CONCEPT_REQ = 'VALIDATE_CONCEPT_REQ_V2';

export const IMAGE_RATE_REQ = 'IMAGE_RATE_REQ_V2';

export const IMAGE_DELETE_REQ = 'IMAGE_DELETE_REQ_V2';
export const DELETE_IMAGES_CONFIRMED_REQ = 'DELETE_IMAGES_CONFIRMED_REQ_V2';

export const CT_TASK_START = 'TASK_TRAIN_START_V2';
export const CT_TASK_COMPLETE = 'TASK_TRAIN_COMPLETE_V2';

export const ASSIGN_SEARCH_IMAGES_REQ = 'ASSIGN_SEARCH_IMAGES_REQ_V2';

export const GET_CONCEPT_INFO_REQ = 'GET_CONCEPT_INFO_REQ_V2';
export const GET_CONCEPT_INFO_SUCCESS = 'GET_CONCEPT_INFO_SUCCESS_V2';
