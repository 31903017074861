import { all, put, take } from 'redux-saga/effects';
import {
    DIALOG_CLOSE,
    DIALOG_CLOSE_REQ,
    DIALOG_OPEN_REQ,
    DIALOG_SHOW,
} from '../actions/actionTypes/dialog';

function* watchDialogShow() {
    while (true) {
        const { params } = yield take(DIALOG_OPEN_REQ);
        yield put({ type: DIALOG_SHOW, payload: params });
    }
}
function* watchDialogClose() {
    while (true) {
        const { dialogAction, dialogActionParams } = yield take(DIALOG_CLOSE_REQ);
        yield put({ type: DIALOG_CLOSE });

        if (dialogAction && dialogAction.type) {
            yield put({ ...dialogAction, params: dialogActionParams });
        }
    }
}

/* function* watchAndLog() {
    while (true) {
        const action = yield take('*');
        console.log('watchAndLog.action:', action)
    }
} */

export const sagas = () => all([watchDialogShow(), watchDialogClose()]);
