import axios from 'axios';
import { every } from 'lodash';
import { all, call, delay, put, select, take, takeEvery } from 'redux-saga/effects';
import {
    deletePrediction,
    getGalleryImagePrediction,
    getPredictionsTasks,
    getVideoGallery,
    predictVideo,
} from '../../api';
import i18n from '../../i18n';
import {
    VIDEO_FACIAL_RECOGNITION_UPLOADS,
    VIDEO_HIGHLIGHTS_UPLOADS,
    VIDEO_SHOT_DETECTION_UPLOADS,
    VIDEO_TAGGING_UPLOADS,
} from '../../routes/routes';
import { dialogTypes, predictionTypes, STATIC_PATH, UPLOADS_PATH } from '../../utils/constants';
import { localStorageFacade } from '../../utils/localStorage';
import { actions as videoActions } from '../actions';
import {
    DELETE_VIDEO,
    DELETE_VIDEO_CONFIRMED,
    GET_VIDEO_GALLERY,
    GET_VIDEO_UPLOADS,
    PREDICT_GALLERY_VIDEO,
    UPLOAD_VIDEO,
    UPLOAD_VIDEO_REQUEST,
    videoPrefixes,
} from '../actions/actionTypes/video';
import { showDialog } from '../actions/dialog';

const wrapAction = (acion, prefix) => {
    return `${prefix}_${acion}`;
};

const predictionFactory = (
    type,
    uploadType,
    galleryUploadType,
    actions,
    localStorageGallery,
    getStoredUploads,
    getStoredGallery,
    uploadsPagePathname,
    responseGalleryPath,
    defaultVideoPath
) => {
    const GET_VIDEO_GALLERY_ACTION = wrapAction(GET_VIDEO_GALLERY, type);
    const GET_VIDEO_UPLOADS_ACTION = wrapAction(GET_VIDEO_UPLOADS, type);
    const PREDICT_GALLERY_VIDEO_ACTION = wrapAction(PREDICT_GALLERY_VIDEO, type);
    const UPLOAD_VIDEO_ACTION = wrapAction(UPLOAD_VIDEO, type);
    const UPLOAD_VIDEO_REQUEST_ACTION = wrapAction(UPLOAD_VIDEO_REQUEST, type);
    const DELETE_VIDEO_ACTION = wrapAction(DELETE_VIDEO, type);
    const DELETE_VIDEO_CONFIRMED_ACTION = wrapAction(DELETE_VIDEO_CONFIRMED, type);

    function* setGallery(resGallery) {
        try {
            const gallery = resGallery[responseGalleryPath].map(video => ({
                ...video,
                path: `${STATIC_PATH}/${defaultVideoPath}/${video.path}`,
            }))[0];
            localStorageGallery.set(gallery);
            yield put(actions.updateVideoGallery(gallery));
        } catch (e) {
            console.error(`video.setGallery, invalid gallery format, ${e}`);
        }
    }

    function* uploadsPredictionFinished() {
        const uploads = yield select(getStoredUploads);
        if (!uploads) {
            return false;
        }
        return every(uploads, item => item.isFinished);
    }

    function* getUploads() {
        try {
            const uploads = yield call(() => getPredictionsTasks(uploadType));

            yield put(
                actions.setVideoUploads(
                    uploads.map(video => ({
                        ...video,
                        path: `${UPLOADS_PATH}/${video.path}`,
                    }))
                )
            );

            const isFinished = yield call(uploadsPredictionFinished);
            if (!isFinished) {
                yield delay(2000);
                yield call(getUploads);
            }
        } catch (e) {
            console.error(`video.getUploads has failed with error: ${e}`);
        }
    }
    function* isVisible() {
        if (type === videoPrefixes.tagging) {
            return yield select(state => state.app.config.features.videoTagging);
        }
        return false;
    }

    function* galleryPredictionFinished() {
        const gallery = yield select(getStoredGallery);
        if (!gallery) {
            return false;
        }
        return gallery.isFinished;
    }

    function* getGallery(action) {
        if (yield !call(isVisible)) {
            return;
        }
        const { ignoreCache } = action.payload;
        try {
            const cache = localStorageGallery.get();
            if (cache && !ignoreCache) {
                yield put(actions.updateVideoGallery(cache));
            } else {
                const resGallery = yield call(() => getVideoGallery(galleryUploadType));
                yield setGallery(resGallery);
            }
            const isFinished = yield call(galleryPredictionFinished);
            if (!isFinished) {
                yield delay(2000);
                yield call(getGallery, { payload: { ignoreCache: true } });
            }
        } catch (e) {
            console.error(`video.getGallery has failed with error: ${e}`);
        }
    }

    function* predictGalleryVideo(action) {
        const {
            payload: { folder, name },
        } = action;
        try {
            const prediction = yield call(
                getGalleryImagePrediction,
                folder,
                name,
                galleryUploadType
            );
            yield put(actions.updateVideoGalleryItem(folder, name, prediction, galleryUploadType));
        } catch (e) {
            console.error(`video.predictGalleryVideo has failed with error: ${e}`);
        }
    }

    function* watchPredictGalleryVideo() {
        yield takeEvery(PREDICT_GALLERY_VIDEO_ACTION, predictGalleryVideo);
    }

    function* uploadVideo({ videos }, { dispatch, history }) {
        const video = videos[0];
        const cancelTokenSource = axios.CancelToken.source();
        const progressHandler = value => {
            dispatch(
                actions.updateVideoUploads(0, upload => ({
                    ...upload,
                    progress: value,
                }))
            );
        };

        yield put(
            actions.newVideoUpload({
                name: video.name,
                path: `${UPLOADS_PATH}/${video.path}`,
                date: Date.now(),
                progress: 0,
                predictionType: uploadType,
                cancelRequestSource: cancelTokenSource,
            })
        );

        try {
            yield call(predictVideo, video, uploadType, progressHandler, cancelTokenSource);
        } catch (e) {
            if (axios.isCancel(e)) {
                yield put(
                    showDialog({
                        type: 'warning',
                        action: {},
                        actionParams: { message: i18n.t('video.uploadCanceled') },
                    })
                );
            } else {
                yield put(
                    showDialog({
                        type: 'error',
                        action: {},
                        actionParams: {
                            message: `Prediction on ${video.name} has failed. ${e.message}`,
                        },
                    })
                );
                console.error(e);
            }
        }
        yield call(getUploads);
    }

    function* watchGetGallery() {
        while (true) {
            const params = yield take(GET_VIDEO_GALLERY_ACTION);
            yield call(getGallery, params);
        }
    }

    function* watchGetUploads() {
        while (true) {
            const params = yield take(GET_VIDEO_UPLOADS_ACTION);
            yield call(getUploads, params);
        }
    }

    // eslint-disable-next-line no-unused-vars
    function* watchUploadVideo(params) {
        while (true) {
            const { payload } = yield take(UPLOAD_VIDEO_ACTION);
            yield call(uploadVideo, payload, params);
        }
    }

    function* watchUploadVideoRequest({ history }) {
        while (true) {
            yield take(UPLOAD_VIDEO_REQUEST_ACTION);
            const uploads = yield select(getStoredUploads);
            yield put(
                showDialog({
                    type: 'upload_video',
                    action: actions.uploadVideo,
                    actionParams: {
                        hasUploads: uploads.length > 0,
                    },
                })
            );
        }
    }

    function* watchDeleteVideo() {
        while (true) {
            const { payload } = yield take(DELETE_VIDEO_ACTION);

            yield put(
                showDialog({
                    type: dialogTypes.deleteVideoConfirmation,
                    action: { type: DELETE_VIDEO_CONFIRMED_ACTION },
                    actionParams: payload,
                })
            );
        }
    }

    function* watchDeleteVideoConfirmed(action) {
        while (true) {
            const { params } = yield take(DELETE_VIDEO_CONFIRMED_ACTION);
            try {
                yield call(deletePrediction, params.id);
                yield call(getUploads);
            } catch (e) {
                yield put(
                    showDialog({
                        type: dialogTypes.error,
                        action: {},
                        actionParams: {
                            message: 'Removing video has failed',
                        },
                    })
                );
            }
        }
    }

    return {
        sagas: params =>
            all([
                watchGetGallery(),
                watchUploadVideo(params),
                watchUploadVideoRequest(params),
                watchPredictGalleryVideo(),
                watchGetUploads(),
                watchDeleteVideo(),
                watchDeleteVideoConfirmed(),
            ]),
    };
};

export const videoTaggingSaga = predictionFactory(
    videoPrefixes.tagging,
    predictionTypes.uploadedVideo,
    predictionTypes.galleryVideo,
    videoActions.videoTaggingActions,
    localStorageFacade.videoTaggingGallery,
    state => state.video.tagging.uploads,
    state => state.video.tagging.gallery,
    VIDEO_TAGGING_UPLOADS.pathname,
    'videoTagging',
    'video_tagging'
);

export const videoFaceRecognitionSaga = predictionFactory(
    videoPrefixes.faceRecognition,
    predictionTypes.faceRecognitionVideo,
    predictionTypes.faceRecognitionGalleryVideo,
    videoActions.videoFaceRecognitionActions,
    localStorageFacade.videoFaceRecognitionGallery,
    state => state.video.faceRecognition.uploads,
    state => state.video.faceRecognition.gallery,
    VIDEO_FACIAL_RECOGNITION_UPLOADS.pathname,
    'videoFaceRecognition',
    'video_face_recognition'
);

export const videoShotDetectionSaga = predictionFactory(
    videoPrefixes.shotDetection,
    predictionTypes.shotDetectionVideo,
    predictionTypes.shotDetectionGalleryVideo,
    videoActions.videoShotDetectionActions,
    localStorageFacade.videoShotDetectionGallery,
    state => state.video.shotDetection.uploads,
    state => state.video.shotDetection.gallery,
    VIDEO_SHOT_DETECTION_UPLOADS.pathname,
    'videoShotDetection',
    'video_shot_detection'
);

export const videoHighlightsSaga = predictionFactory(
    videoPrefixes.highlights,
    predictionTypes.highlightsVideo,
    predictionTypes.highlightsGalleryVideo,
    videoActions.videoHighlightsActions,
    localStorageFacade.videoHighlightsGallery,
    state => state.video.highlights.uploads,
    state => state.video.highlights.gallery,
    VIDEO_HIGHLIGHTS_UPLOADS.pathname,
    'videoHighlights',
    'video_highlights'
);
