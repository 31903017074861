import ZoomInIcon from '@material-ui/icons/ZoomIn';
import React, {
    CSSProperties,
    FC,
    memo,
    ReactNode,
    ReactType,
    RefObject,
    StrictMode,
    useCallback,
    useRef,
    useState,
} from 'react';
import './styles.css';
import UncontrolledActivated from './UncontrolledActivated';
export interface UncontrolledProps {
    children: ReactNode;
    closeText?: string;
    openText?: string;
    overlayBgColorEnd?: string;
    overlayBgColorStart?: string;
    portalEl?: HTMLElement;
    scrollableEl?: HTMLElement | Window;
    transitionDuration?: number;
    wrapElement?: ReactType;
    wrapStyle?: CSSProperties;
    zoomMargin?: number;
    zoomZindex?: number;
}

const Uncontrolled: FC<UncontrolledProps> = ({
    children,
    closeText = 'Unzoom image',
    overlayBgColorEnd = 'rgba(255, 255, 255, 0.95)',
    overlayBgColorStart = 'rgba(255, 255, 255, 0)',
    portalEl,
    openText = 'Zoom image',
    scrollableEl,
    transitionDuration = 0,
    wrapElement: WrapElement = 'div',
    wrapStyle,
    zoomMargin = 0,
    zoomZindex = 2147483647,
}: UncontrolledProps) => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const [isChildLoaded, setIsChildLoaded] = useState<boolean>(false);
    const wrapRef = useRef<HTMLElement>(null);
    const btnRef = useRef<HTMLButtonElement>(null);

    const handleClickTrigger = useCallback(
        e => {
            if (!isActive) {
                e.preventDefault();
                setIsActive(true);
            }
        },
        [isActive]
    );

    const handleChildLoad = useCallback(() => {
        setIsChildLoaded(true);
    }, []);

    const handleChildUnload = useCallback(() => {
        setIsActive(false);
        setIsChildLoaded(false);

        if (btnRef.current) {
            btnRef.current.focus({ preventScroll: true });
        }
    }, []);

    const isExpanded = isActive && isChildLoaded;
    const wrapType = isExpanded ? 'hidden' : 'visible';

    if (typeof window !== 'undefined' && isActive) {
        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    width: '100%',
                    height: '100%',
                }}
            >
                <UncontrolledActivated
                    closeText={closeText}
                    onLoad={handleChildLoad}
                    onUnload={handleChildUnload}
                    overlayBgColorEnd={overlayBgColorEnd}
                    overlayBgColorStart={overlayBgColorStart}
                    parentRef={wrapRef}
                    portalEl={portalEl}
                    scrollableEl={scrollableEl}
                    transitionDuration={transitionDuration}
                    zoomMargin={zoomMargin}
                    zoomZindex={zoomZindex}
                >
                    {children}
                </UncontrolledActivated>
            </div>
        );
    }
    return (
        <StrictMode>
            <WrapElement
                data-rmiz-wrap={wrapType}
                ref={wrapRef as RefObject<HTMLElement>}
                style={{ width: '100%', height: '100%' }}
            >
                {children}
                <button
                    aria-label={openText}
                    data-rmiz-btn-open
                    onClick={handleClickTrigger}
                    ref={btnRef}
                >
                    <ZoomInIcon color="action" />
                </button>
            </WrapElement>
        </StrictMode>
    );
};

// eslint-disable-next-line import/no-default-export
export default memo(Uncontrolled);
