const tokenVersion = 4;
const taggingGallery = 6;
const videoTaggingGallery = 4;
const videoFaceRecognitionGallery = 4;
const videoShotDetectionGallery = 1;
const videoHighlightsGallery = 1;
const faceRecognitionGallery = 8;
const logoDetectionGallery = 2;

const prevKeys = [
    `token${tokenVersion === 1 ? '' : `-v${tokenVersion - 1}`}`,
    `refreshToken${tokenVersion === 1 ? '' : `-v${tokenVersion - 1}`}`,
    `taggingGallery${taggingGallery === 1 ? '' : `-v${taggingGallery - 1}`}`,
    `videoTaggingGallery${videoTaggingGallery === 1 ? '' : `-v${videoTaggingGallery - 1}`}`,
    `videoFaceRecognitionGallery${
        videoFaceRecognitionGallery === 1 ? '' : `-v${videoFaceRecognitionGallery - 1}`
    }`,
    `videoShotDetectionGallery${
        videoShotDetectionGallery === 1 ? '' : `-v${videoShotDetectionGallery - 1}`
    }`,
    `videoHighlightsGallery${
        videoHighlightsGallery === 1 ? '' : `-v${videoHighlightsGallery - 1}`
    }`,
    `faceRecognitionGallery${
        faceRecognitionGallery === 1 ? '' : `-v${faceRecognitionGallery - 1}`
    }`,
    `logoDetectionGallery${logoDetectionGallery === 1 ? '' : `-v${logoDetectionGallery - 1}`}`,
];

const storageKeys = {
    token: `token-v${tokenVersion}`,
    refreshToken: `refreshToken-v${tokenVersion}`,
    taggingGallery: `taggingGallery-v${taggingGallery}`,
    faceRecognitionGallery: `faceRecognitionGallery-v${faceRecognitionGallery}`,
    logoDetectionGallery: `logoDetectionGallery-v${logoDetectionGallery}`,
    videoTaggingGallery: `videoTaggingGallery-v${videoTaggingGallery}`,
    videoFaceRecognitionGallery: `videoFaceRecognitionGallery-v${videoTaggingGallery}`,
    videoShotDetectionGallery: `videoShotDetectionGallery-v${videoTaggingGallery}`,
    videoHighlightsGallery: `videoHighlightsGallery-v${videoTaggingGallery}`,
};

const clearObsolete = () => {
    prevKeys.forEach(key => {
        try {
            localStorage.removeItem(key);
        } catch {
            console.info(`Cound not remove obsole key ${key}. Not found.`);
        }
    });
};

clearObsolete();

const createDefaultGetSetFunction = key => {
    return {
        get: () => {
            return JSON.parse(localStorage.getItem(key));
        },
        set: gallery => {
            localStorage.setItem(key, JSON.stringify(gallery));
        },
        clear: () => {
            try {
                localStorage.removeItem(key);
            } catch {
                console.info(`Cound not remove obsole key ${key}. Not found.`);
            }
        },
    };
};

const localStorageTaggingGallery = createDefaultGetSetFunction(storageKeys.taggingGallery);
const localStorageFaceRecognitionGallery = createDefaultGetSetFunction(
    storageKeys.faceRecognitionGallery
);
const localStorageLogoDetectionGallery = createDefaultGetSetFunction(
    storageKeys.logoDetectionGallery
);
const localStorageVideoTaggingGallery = createDefaultGetSetFunction(
    storageKeys.videoTaggingGallery
);
const localStorageVideoFaceRecognitionGallery = createDefaultGetSetFunction(
    storageKeys.videoFaceRecognitionGallery
);
const localStorageVideoShotDetectionGallery = createDefaultGetSetFunction(
    storageKeys.videoShotDetectionGallery
);

const localStorageVideoHighlightsGallery = createDefaultGetSetFunction(
    storageKeys.videoHighlightsGallery
);

const localStorageTokens = {
    get: () => {
        return {
            token: localStorage.getItem(storageKeys.token),
            refreshToken: localStorage.getItem(storageKeys.refreshToken),
        };
    },
    headerToken: () => {
        const token = localStorage.getItem(storageKeys.token);
        return token ? `Bearer ${token}` : '';
    },
    headerRefreshToken: () => {
        const token = localStorage.getItem(storageKeys.refreshToken);
        return token ? `Bearer ${token}` : '';
    },
    queryStringToken: () => {
        return `jwt=${localStorage.getItem(storageKeys.token)}`;
    },
    setToken: token => {
        localStorage.setItem(storageKeys.token, token);
    },
    setRefreshToken: token => {
        localStorage.setItem(storageKeys.refreshToken, token);
    },
    clear: () => {
        localStorage.removeItem(storageKeys.token);
        localStorage.removeItem(storageKeys.refreshToken);
    },
};

const clearLocalStorage = () => {
    localStorageTaggingGallery.clear();
    localStorageFaceRecognitionGallery.clear();
    localStorageLogoDetectionGallery.clear();
    localStorageVideoTaggingGallery.clear();
    localStorageVideoFaceRecognitionGallery.clear();
    localStorageVideoShotDetectionGallery.clear();
    localStorageVideoHighlightsGallery.clear();
    localStorageTokens.clear();
};

export const localStorageFacade = {
    taggingGallery: localStorageTaggingGallery,
    faceRecognitionGallery: localStorageFaceRecognitionGallery,
    logoDetectionGallery: localStorageLogoDetectionGallery,
    videoTaggingGallery: localStorageVideoTaggingGallery,
    videoFaceRecognitionGallery: localStorageVideoFaceRecognitionGallery,
    videoShotDetectionGallery: localStorageVideoShotDetectionGallery,
    videoHighlightsGallery: localStorageVideoHighlightsGallery,
    tokens: localStorageTokens,
    clearLocalStorage,
};
