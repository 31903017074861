import { Box } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import React, {
    FC,
    memo,
    ReactNode,
    RefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { createPortal } from 'react-dom';
import useEvent from 'react-use/lib/useEvent';
import './styles.css';

export interface UncontrolledActivatedProps {
    children: ReactNode;
    closeText?: string;
    onUnload: () => void;
    onLoad: () => void;
    overlayBgColorEnd?: string;
    overlayBgColorStart?: string;
    parentRef: RefObject<HTMLElement>;
    portalEl?: HTMLElement;
    scrollableEl?: HTMLElement | Window;
    transitionDuration?: number;
    zoomMargin?: number;
    zoomZindex?: number;
}

const UncontrolledActivated: FC<UncontrolledActivatedProps> = ({
    children,
    closeText = 'Unzoom Image',
    onUnload,
    onLoad,
    overlayBgColorEnd = 'rgba(255, 255, 255, 0.95)',
    overlayBgColorStart = 'rgba(255, 255, 255, 0)',
    parentRef,
    portalEl = document.body,
    scrollableEl = window,
    transitionDuration = 0,
    zoomMargin = 0,
    zoomZindex = 2147483647,
}: UncontrolledActivatedProps) => {
    const btnRef = useRef<HTMLButtonElement>(null);
    const [, forceUpdate] = useState<number>(0);
    const [isUnloading, setIsUnloading] = useState<boolean>(false);

    // on click, begin unloading
    const handleClick = useCallback(e => {
        e.preventDefault();
        setIsUnloading(true);
    }, []);

    // on escape, begin unloading
    const handleKeyDown = useCallback(e => {
        if (e.key === 'Escape' || e.keyCode === 27) {
            e.stopPropagation();
            setIsUnloading(true);
        }
    }, []);

    const handleScroll = useCallback(() => {
        forceUpdate(n => n + 1);

        if (!isUnloading) {
            setIsUnloading(true);
        }
    }, [isUnloading]);

    // listen for keydown on the document
    useEvent('keydown', handleKeyDown, document);

    // listen for scroll and close
    useEvent('scroll', handleScroll, scrollableEl);

    // set loaded on mount and focus
    useEffect(() => {
        // setIsLoaded(true);
        onLoad();

        if (btnRef.current) {
            btnRef.current.focus({ preventScroll: true });
        }
    }, [onLoad]);

    // if unloading, tell parent that we're all done here after Nms
    useEffect(() => {
        const unloadTimeout = isUnloading ? setTimeout(onUnload, transitionDuration) : null;

        return (): void => {
            if (unloadTimeout) {
                clearTimeout(unloadTimeout);
            }
        };
    }, [isUnloading, onUnload, transitionDuration]);

    return createPortal(
        <Box p={6} aria-modal data-rmiz-overlay role="dialog" onClick={handleClick}>
            <button aria-label={closeText} data-rmiz-btn-close onClick={handleClick} ref={btnRef}>
                <CancelIcon color="action" />
            </button>
            {children}
        </Box>,
        portalEl
    );
};

// eslint-disable-next-line import/no-default-export
export default memo(UncontrolledActivated);
