import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { createStyles, makeStyles, Theme, ThemeProvider, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect } from 'react';

import {
    Box,
    Collapse,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {
    ChevronLeft,
    ChevronRight,
    ExitToAppOutlined,
    ExpandLess,
    ExpandMore,
    Mail,
} from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import { useAppConfig } from '../../hooks/common';
import { useIsTemporaryLeftDrawer } from '../../hooks/mobile';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { selectors } from '../../redux';
import { actions } from '../../redux/actions';
import { lightTheme } from '../../theme/light';
import { ContactUsDialog } from '../dialogs/ContactUsDialog';
import { ByUsecaseLists, FeatureLists, MenuItem as MenuItemType } from './config';

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            width: drawerWidth,
        },

        hide: {
            display: 'none',
        },
        drawer: {
            display: 'flex',
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            zIndex: 1,
            alignItems: 'center',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            justifyContent: 'space-between',
        },
        drawerClose: {
            justifyContent: 'space-between',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },

        nested: {
            paddingLeft: theme.spacing(9),
        },
        collapseLabel: {
            '& > img': {
                height: 20,
            },
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        mobileLogo: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: theme.spacing(2),
        },
        list: {
            // '&.Mui-selected': {
            //     background: '#E2FAF3',
            // },
            // '.MuiListItem-root-96.Mui-selected:hover': {
            //     background: '#E2FAF3',
            // },
            '&.MuiMenu-paper': {
                background: 'red',
            },
        },
        nestedMenuLabel: {
            '& > img': {
                height: 20,
            },
        },
    })
);

const Sidebar = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isTemporaryLeftSidebar = useIsTemporaryLeftDrawer();
    const [activeKey, setActiveKey] = React.useState('');
    const [openContactModal, setOpenContactModal] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const location = useLocation();
    const config = useAppConfig();

    const drawerOpen = useAppSelector(selectors.isDrawerOpen);
    const dispatch = useAppDispatch();
    // drawer is closed by default on mobile
    useEffect(() => {
        if (isTemporaryLeftSidebar) {
            dispatch(actions.closeDrawer());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const toggleDrawer = () => {
        if (drawerOpen) {
            dispatch(actions.closeDrawer());
        } else {
            dispatch(actions.openDrawer());
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, key: any) => {
        setAnchorEl(event.currentTarget);
        if (activeKey === key) {
            setActiveKey('');
        } else {
            setActiveKey(key);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        dispatch(actions.logout());
    };

    useEffect(() => {
        // active Collapse menu
    }, [location.pathname]);

    return (
        <ThemeProvider theme={lightTheme}>
            <ContactUsDialog
                handleClose={() => setOpenContactModal(false)}
                open={openContactModal}
            />
            <Drawer
                variant={isTemporaryLeftSidebar ? 'temporary' : 'permanent'}
                open={!!drawerOpen}
                onClose={toggleDrawer}
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: drawerOpen,
                    [classes.drawerClose]: !drawerOpen,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: drawerOpen,
                        [classes.drawerClose]: !drawerOpen,
                    }),
                }}
            >
                <Box>
                    <Box className={classes.mobileLogo}>
                        {!drawerOpen && (
                            <img
                                src="/assets/mobius_logo_small.svg"
                                alt="Mobiuslabs Logo minimized"
                                width="32"
                                data-test="sidebar-drawer-logo"
                            />
                        )}
                    </Box>
                    <Box
                        className={classes.drawerHeader}
                        style={{
                            padding: drawerOpen
                                ? theme.spacing(0, 0, 0, 2.5)
                                : theme.spacing(0, 0, 0, 0.5),
                        }}
                    >
                        {drawerOpen && (
                            <img
                                data-test="sidebar-drawer-logo"
                                src="/assets/mobius_logo_small.svg"
                                alt="Mobiuslabs Logo"
                                width="32"
                            />
                        )}
                        <div className={classes.toolbar}>
                            <IconButton onClick={toggleDrawer} data-test="sidebar-drawer-button">
                                {!drawerOpen ? <ChevronRight /> : <ChevronLeft />}
                            </IconButton>
                        </div>
                    </Box>

                    <List component="nav" className={classes.list}>
                        {false && config.settings.isMobius && (
                            <ListSubheader component="div">By Feature</ListSubheader>
                        )}
                        <Divider />
                        {FeatureLists(config).map(
                            ({ key, label, icon: Icon, items, path, isVisible, dataTest }: any) => {
                                const openNestedMenu = key === activeKey;
                                const openCollapse =
                                    key === activeKey || path === location.pathname;
                                if (
                                    !isVisible ||
                                    (Array.isArray(items) &&
                                        !items.some((item: MenuItemType) => item.isVisible))
                                ) {
                                    return null;
                                }

                                return (
                                    <div key={key}>
                                        <ListItem
                                            // @ts-ignore
                                            component={items?.length > 0 ? null : Link}
                                            to={path}
                                            data-test={dataTest}
                                            button
                                            selected={location.pathname === path}
                                            onClick={(e: any) => handleClick(e, key)}
                                        >
                                            <ListItemIcon>
                                                <Icon />
                                            </ListItemIcon>
                                            <ListItemText primary={label} />
                                            {items?.length > 0 &&
                                                (openNestedMenu ? <ExpandLess /> : <ExpandMore />)}
                                        </ListItem>
                                        {openNestedMenu && !drawerOpen && items && (
                                            <Menu
                                                id="popup-submenu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                {items?.map(
                                                    ({
                                                        key: childKey,
                                                        isVisible,
                                                        label: childLabel,
                                                        path,
                                                        dataTest,
                                                    }: any) => {
                                                        if (!isVisible || !items) {
                                                            return null;
                                                        }
                                                        return (
                                                            <MenuItem
                                                                selected={
                                                                    location.pathname === path
                                                                }
                                                                key={childKey}
                                                                data-test={dataTest}
                                                                onClick={handleClose}
                                                                component={Link}
                                                                to={path}
                                                                className={classes.collapseLabel}
                                                            >
                                                                {childLabel}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Menu>
                                        )}
                                        {drawerOpen && items && (
                                            <Collapse
                                                in={openCollapse}
                                                timeout="auto"
                                                unmountOnExit
                                            >
                                                <List>
                                                    {items?.map(
                                                        ({
                                                            key: childKey,
                                                            label: childLabel,
                                                            path: childPath,
                                                            isVisible,
                                                            dataTest,
                                                        }: any) => {
                                                            if (!isVisible) {
                                                                return null;
                                                            }
                                                            return (
                                                                <ListItem
                                                                    selected={
                                                                        location.pathname ===
                                                                        childPath
                                                                    }
                                                                    data-test={dataTest}
                                                                    className={classes.nested}
                                                                    key={childKey}
                                                                    component={Link}
                                                                    to={childPath}
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            <span
                                                                                className={
                                                                                    classes.nestedMenuLabel
                                                                                }
                                                                            >
                                                                                {childLabel}
                                                                            </span>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            );
                                                        }
                                                    )}
                                                </List>
                                            </Collapse>
                                        )}
                                    </div>
                                );
                            }
                        )}
                    </List>
                    {false && (
                        <>
                            {drawerOpen && (
                                <ListSubheader component="div">By Use case</ListSubheader>
                            )}
                            <Divider />
                            <List className={classes.list}>
                                {ByUsecaseLists(config).map(
                                    ({
                                        key,
                                        label,
                                        icon: Icon,
                                        items,
                                        path,
                                        isVisible,
                                        dataTest,
                                    }: any) => {
                                        if (!isVisible) {
                                            return null;
                                        }

                                        return (
                                            <ListItem
                                                key={key}
                                                // @ts-ignore
                                                component={items?.length > 0 ? null : Link}
                                                to={path ?? ''}
                                                data-test={dataTest}
                                                button
                                                selected={location.pathname === path}
                                                onClick={(e: any) => handleClick(e, key)}
                                            >
                                                <ListItemIcon>
                                                    <Icon />
                                                </ListItemIcon>
                                                <ListItemText primary={label} />
                                            </ListItem>
                                        );
                                    }
                                )}
                            </List>
                        </>
                    )}
                </Box>
                <Box>
                    <Divider />
                    <List>
                        {config.settings.isMobius && (
                            <ListItem
                                button
                                onClick={() => setOpenContactModal(true)}
                                data-test="contact-button"
                            >
                                <ListItemIcon>
                                    <Mail />
                                </ListItemIcon>
                                <ListItemText primary={'Contact Us'} />
                            </ListItem>
                        )}
                        {
                            // @ts-ignore
                            <ListItem button onClick={handleLogout} data-test="logout-button">
                                <ListItemIcon>
                                    <ExitToAppOutlined />
                                </ListItemIcon>
                                <ListItemText primary={'Logout'} />
                            </ListItem>
                        }
                    </List>
                </Box>
            </Drawer>
        </ThemeProvider>
    );
};

// eslint-disable-next-line import/no-default-export
export default Sidebar;
