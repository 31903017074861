import { Dialog, DialogTitle } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { renderDialog } from '../components/dialogs/dialog-factory';
import { actions, selectors } from '../redux';
import { useIsMobile } from '../hooks/mobile';

export const DialogContainerBase = props => {
    const { dialogConfig, closeDialog } = props;

    const { title } = dialogConfig || {};
    const isOpen = Boolean(dialogConfig);
    const isMobile = useIsMobile();

    return (
        <Dialog
            maxWidth="md"
            fullWidth={isMobile}
            onClose={closeDialog}
            aria-labelledby="simple-dialog-title"
            open={isOpen}>
            {title && <DialogTitle id="simple-dialog-title">{title}</DialogTitle>}
            {renderDialog({ ...dialogConfig, onClose: closeDialog })}
        </Dialog>
    );
};
DialogContainerBase.propTypes = {
    dialogConfig: PropTypes.object,
    closeDialog: PropTypes.func.isRequired
};

DialogContainerBase.defaultProps = {
    dialogConfig: null
};

const mapStateToProps = state => {
    const dialogConfig = selectors.getDialogConfig(state);

    return { dialogConfig };
};

const mapDispatchToProps = dispatch => {
    return {
        closeDialog: (dialogAction, dialogActionParams) =>
            dispatch(actions.closeDialog({ dialogAction, dialogActionParams }))
    };
};

const DialogContainer = connect(mapStateToProps, mapDispatchToProps)(DialogContainerBase);
export { DialogContainer };
