import { BoundingBox } from '../utils/commonTypes';

export type DetectModelType = {
    id: number;
    name: string;
    imagePath: string;
    userId: number;
};

export type DetectObject = {
    detectionClassId: number;
    boundingBox: BoundingBox;
};

export type DetectImage = {
    id: number;
    detectionModelId: number;
    imageType: string;
    detectionObjects: Array<DetectObject>;
    origFilename: string;
    path: string;
    filename: string;
};

export type DetectClass = {
    id: number;
    detectionModelId: number;
    name: string;
};

export type ModelStatus = {
    classesCount: number;
    detectionModelId: number;
    imagesCount: number;
    isInProgress: boolean;
    isTrained: boolean;
    testImagesCount: number;
    testLabeledImagesCount: number;
    testObjectsCount: number;
    trainImagesCount: number;
    trainLabeledImagesCount: number;
    trainObjectsCount: number;
    description: string;
    isError: boolean;
    isModified: boolean;
    isEnoughTrainObjects: boolean;
    predictionStatus: string;
    trainingStatus: string;
};

export enum DetectionImageStatus {
    PredictionNotDone = 'prediction_not_done',
    PredictionInProgress = 'prediction_in_progress',
    PredictionSuccess = 'prediction_success',
    PredictionError = 'prediction_error',
}
