/* eslint-disable react/prefer-stateless-function */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { actions } from '../redux/actions';
import { selectors } from '../redux/selectors';
import { renderRoute } from '../routes';

const RouterContainerBase = ({ history, appConfig, hasAuthCheck, checkAuth, isLogged }) => {
    const renderer = (...params) => renderRoute(appConfig, hasAuthCheck, isLogged, ...params);

    useEffect(() => {
        checkAuth();
    }, [checkAuth]);

    return (
        <Router history={history}>
            <Switch>
                {/* Redirect is to removing ending '/' from url as ending slash assume as another route  */}
                <Redirect from="/:url*(/+)" to={history.location.pathname.slice(0, -1)} />
                <Route path="/" render={renderer} />
            </Switch>
        </Router>
    );
};

RouterContainerBase.propTypes = {
    history: PropTypes.object.isRequired,
    appConfig: PropTypes.object.isRequired,
    hasAuthCheck: PropTypes.bool.isRequired,
    checkAuth: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    const hasAuthCheck = state.auth.authCheckComplete;
    const appConfig = state.app.config;
    const isLogged = selectors.isLogged(state);
    return { hasAuthCheck, appConfig, isLogged };
};

const mapDispatchToProps = dispatch => {
    return {
        checkAuth: () => dispatch(actions.checkAuth()),
    };
};

const RouterContainer = connect(mapStateToProps, mapDispatchToProps)(RouterContainerBase);
export { RouterContainer };
