import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { isNumber } from 'lodash';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { selectors } from '../../redux/selectors';
import { matchMobile } from '../../utils/markupHelpers';

const useStyles = makeStyles(theme => ({
    root: {
        width: 600,

        [matchMobile(theme)]: {
            width: 'auto',
        },
    },
    uploadDropzone: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '1.5px dashed #6e6e6e',
        borderRadius: theme.shape.borderRadius,
        '& p': {
            textAlign: 'center',
        },
        padding: theme.spacing(2, 2, 4, 2),
    },
    preview: {
        flex: '20%',
        flexGrow: '0',
        width: '96px',
        height: '96px',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    uploadIcon: {
        color: '#d6d6d6',
        fontSize: '100px',
    },
}));

const UploadFormBase = props => {
    const { onClose, user, action } = props;
    const classes = useStyles();
    const onDrop = React.useCallback(
        acceptedFiles => {
            onClose(action(acceptedFiles));
        },
        [action, onClose]
    );

    const disabled =
        isNumber(user.uploadImagesMax) && user.uploadImagesCounter >= user.uploadImagesMax;
    const { getRootProps, getInputProps } = useDropzone({
        accept: ['image/jpeg', 'image/png'],
        preventDropOnDocument: false,
        onDrop,
        disabled,
    });

    const count = user.uploadImagesMax ? user.uploadImagesMax - user.uploadImagesCounter : null;
    const countMessage = count ? `${count} photos are left.` : '';
    const rootClass = [classes.uploadDropzone, disabled ? ' disabled' : ''].join(' ');

    return (
        <div className={classes.root}>
            <div {...getRootProps({ className: rootClass })}>
                <CloudUploadIcon className={classes.uploadIcon} />
                <input {...getInputProps()} />
                <p>
                    {disabled
                        ? `Upload quota is reached.
                         ${user.uploadImagesMax} of ouf ${user.uploadImagesMax} images have already been uploaded`
                        : `Drag and drop photos to upload. ${countMessage}`}
                </p>
                <Button variant="contained" disabled={disabled} color="secondary">
                    Select photos
                </Button>
            </div>
        </div>
    );
};

UploadFormBase.propTypes = {
    onClose: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        user: selectors.getCurrentUser(state),
    };
};

export const UploadForm = connect(mapStateToProps)(UploadFormBase);
