import { featureAccess } from '../../utils/constants';

export const commonEn = {
    mobiusLabs: 'Mobius Labs',
    logout: 'Logout',
    menu: {
        contact: 'Contact',
        concepts: 'Concepts',
        image: 'Image tag',
        home: 'Home',
        users: 'Users',
    },
    header: {
        demoTab: 'Demo',
        uploadsTab: 'My {{entityNamePlural}}',
        shareButton: 'Share',
        uploadButton: 'Upload your own {{entityName}}',
    },
    contactAMobian: 'Contact A Mobian',
    contactAMobianDescription:
        'Our demo has limited features, but our products are fully customizable! Please contact our team and let’s discuss how we can help you.',
    contactAMobianResponseText: 'A Mobian expert will get back to you soon.',
    features: {
        [featureAccess.cti]: 'CTI',
        [featureAccess.imageDemo]: 'Image Tagging',
        [featureAccess.videoTagging]: 'Video Tagging',
        [featureAccess.imageFacialRecognition]: 'Image Facial Recognition',
        [featureAccess.textSearch]: 'Text Search',
        [featureAccess.videoFacialRecognition]: 'Video Facial Recognition',
        [featureAccess.videoHighlights]: 'Video Highlights',
        [featureAccess.videoShotDetection]: 'Video Shot Detection',
        [featureAccess.earthObservation]: 'Earth Observation',
        [featureAccess.objectDetection]: 'Object Detection',
        [featureAccess.logoDetection]: 'Logo Detection',
    },
    collapse: 'Collapse',
    upCollapse: 'Upcollapse',
    deactivate: 'Deactivate',
    edit: 'Edit',
    view: 'View',
    delete: 'Delete',
    share: 'Share',
    none: 'None',
    all: 'All',
    always: 'Always',
    save: 'Save',
    upload: 'Upload',
    imageNotFound: 'Image not found',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    noDataAvailable: 'No data available',
    uploadAnImage: 'Upload an image',
    secondaryMenu: ' Secondary menu',
    cancel: 'Cancel',
    errorProcessingFile:
        'Unexpected error happened during processing of the file, please try again or contact a our technical support',
    comingSoon: {
        title: 'Coming soon...',
        text1: ' is still in development.',
        text2: 'Come and check in the future.',
    },
    facialRecognition: {
        hoverFaces: 'Hover Faces',
    },
    emptyUploads: {
        header: 'No {{predictionTypeNamePlural}} uploaded yet',
        text: 'You can upload your own {{predictionTypeName}} here to try our SDK out.',
        uploadButton: 'Upload your own {{predictionTypeName}}',
    },
};
