import { Box, CircularProgress, makeStyles, Typography, useTheme } from '@material-ui/core';
import PendingIcon from '@material-ui/icons/Schedule';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DetectionImageStatus } from '../../../types/detection';
import { ConceptFolderEnum, ConceptSelectionMode } from '../../../utils/commonTypes';
import { folderTypes } from '../../../utils/constants';
import { getPredictionColor } from '../../../utils/imageDemo';
import { matchMobileOrTablet } from '../../../utils/markupHelpers';
import { ProtectedImage } from '../../common/ProtectedImage';
import { ImageUploadLinearProgress } from './ImageUploadLinearProgress';

const useStyles = makeStyles(theme => ({
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    pendingNode: {
        color: theme.palette.common.white,
        fontSize: '36px',
    },
    progressNode: {
        width: '100%',
        padding: theme.spacing(0, 1),
        backgroundColor: theme.palette.background.paper,
    },
    uploadedNode: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        borderRadius: theme.shape.borderRadius,
        '$selected > &': {
            borderRadius: 3,
        },
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    progress: {
        color: theme.palette.common.white,
    },
    disabled: {
        opacity: 0.2,
    },
    positive: {
        '& img': {
            border: `3px solid ${theme.palette.secondary.main}`,
        },
    },
    negative: {
        '& img': {
            border: `3px solid ${theme.palette.error.main}`,
        },
    },
    selectedPositive: {
        '& img': {
            border: `3px solid ${theme.palette.secondary.main}!important`,
        },
    },
    selectedNegative: {
        '& img': {
            border: `3px solid ${theme.palette.error.main}!important`,
        },
    },
    selectedTest: {
        '& img': {
            border: `3px solid ${theme.palette.violet}!important`,
        },
    },
    percentage: {
        background: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        position: 'absolute',
        right: 8,
        bottom: 8,
        fontWeight: 'bold',
        padding: theme.spacing(1),
        fontSize: 11,
        color: '#000',
        zIndex: 10000,
        [matchMobileOrTablet(theme)]: {
            padding: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },
    },
    selected: {},
}));

export enum ImageGridItemType {
    start = 'start',
    pending = 'pending',
    progress = 'progress',
    complete = 'complete',
    search = 'search',
    cti = 'cti_image',
    ctiPositive = 'cti_image_positive',
    ctiNegative = 'cti_image_negative',
    ctiValidation = 'cti_validation',
    detection = 'detection',
    detectionTest = 'detection_test',
}

export type ImageGridItemResponse = {
    filename: string;
    path?: string;
    score?: number;
    selectionType?: string;
    imageType?: string;
    detectionObjectsCount?: number;
    status?: string;
};

export type ImageGridItemData = {
    id?: string | number;
    phase: ImageGridItemType;
    progress?: number;
    response: ImageGridItemResponse;
};

type Props = {
    data: ImageGridItemData;
    selected?: any;
    disabled: boolean | null;
    firstRow: boolean;
    lastRow: boolean;
    firstColumn: boolean;
    lastColumn: boolean;
    zoomable?: boolean;
    selectable: any;
};

const ImageGridItemNode = ({
    data: { phase, progress, response },
    selected,
    disabled,
    firstRow,
    lastRow,
    firstColumn,
    lastColumn,
    selectable,
    zoomable = true,
}: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const positions = { firstRow, lastRow, firstColumn, lastColumn };
    const commonProps = {
        positions,
        zoomable,
        selected,
        className: classes.image,
        Loader: (
            <div className={classes.loader}>
                <CircularProgress className={classes.progress} size={20} />
            </div>
        ),
    };

    if (phase === ImageGridItemType.pending) {
        return <PendingIcon className={classes.pendingNode} />;
    }

    if (phase === ImageGridItemType.progress) {
        return (
            <div className={classes.progressNode}>
                <ImageUploadLinearProgress variant="determinate" value={progress} />
            </div>
        );
    }

    if (
        phase === ImageGridItemType.complete ||
        phase === ImageGridItemType.cti ||
        phase === ImageGridItemType.ctiPositive ||
        phase === ImageGridItemType.ctiNegative
    ) {
        return (
            <div
                className={clsx(
                    classes.uploadedNode,
                    disabled && classes.disabled,
                    phase === ImageGridItemType.ctiPositive && !selectable && classes.positive,
                    phase === ImageGridItemType.ctiNegative && !selectable && classes.negative
                )}
            >
                <ProtectedImage src={response.path!} {...commonProps} fullZoom />
            </div>
        );
    }

    if (phase === ImageGridItemType.search) {
        return (
            <div
                className={clsx(
                    classes.uploadedNode,
                    disabled && classes.disabled,
                    selected === ConceptFolderEnum.positive && classes.selectedPositive,
                    selected === ConceptFolderEnum.negative && classes.selectedNegative
                )}
            >
                <ProtectedImage fullZoom src={response.path!} {...commonProps} />
            </div>
        );
    }

    if (phase === ImageGridItemType.detection || phase === ImageGridItemType.detectionTest) {
        return (
            <div
                className={clsx(
                    classes.uploadedNode,
                    disabled && classes.disabled,
                    classes.selected,
                    !selected &&
                        phase === ImageGridItemType.detection &&
                        response.imageType === ConceptFolderEnum.test &&
                        classes.selectedTest
                )}
            >
                {phase === ImageGridItemType.detectionTest && (
                    <Typography
                        className={classes.percentage}
                        style={{
                            backgroundColor: theme.palette.background.paper,
                            color: theme.palette.common.white,
                        }}
                    >
                        {response.status === DetectionImageStatus.PredictionNotDone && 'In queue'}
                        {response.status === DetectionImageStatus.PredictionInProgress &&
                            'Predicting...'}
                        {response.status === DetectionImageStatus.PredictionError && 'Error'}
                        {response.status === DetectionImageStatus.PredictionSuccess &&
                            (response.detectionObjectsCount || 0)}
                    </Typography>
                )}
                {phase === ImageGridItemType.detection &&
                    response.imageType === ConceptFolderEnum.test && (
                        <Typography
                            className={classes.percentage}
                            style={{ backgroundColor: theme.palette.violet }}
                        >
                            Test Set
                        </Typography>
                    )}
                <ProtectedImage fullZoom src={response.path!} {...commonProps} />
            </div>
        );
    }

    if (phase === ImageGridItemType.ctiValidation) {
        const type = response.selectionType;
        const getColor = () => {
            if (response.selectionType === folderTypes.positive) {
                return theme.palette.secondary.main;
            }
            if (response.selectionType === folderTypes.negative) {
                return theme.palette.error.main;
            }
            return getPredictionColor(response.score!);
        };

        const renderText = () => {
            const score = `${Math.round(response.score! * 100)}%`;
            if (type === folderTypes.positive || type === folderTypes.negative) {
                const translation = t(`concepts.movedTo.${type}`);
                return `${translation}, ${score}`;
            }

            return score;
        };

        return (
            <Box
                className={clsx(
                    classes.uploadedNode,
                    disabled && classes.disabled,
                    selectable === ConceptSelectionMode.change &&
                        type === ConceptFolderEnum.positive &&
                        classes.selectedPositive,
                    selectable === ConceptSelectionMode.change &&
                        type === ConceptFolderEnum.negative &&
                        classes.selectedNegative
                )}
            >
                <Typography className={classes.percentage} style={{ backgroundColor: getColor() }}>
                    {renderText()}
                </Typography>
                <ProtectedImage src={response.path || ''} {...commonProps} fullZoom />
            </Box>
        );
    }

    return null;
};

export { ImageGridItemNode };
