import { ACTIONS_REQUIRE_CONSENT } from '../../utils/config';
import { isMobius } from '../../utils/env';
import { showDialog } from '../actions/dialog';
import { isConsentGiven } from '../selectors/auth';

export const consentMiddleware = storeAPI => next => action => {
    const { params, type } = action;
    const isConsent = isConsentGiven(storeAPI.getState());
    if (!isMobius() || isConsent || !ACTIONS_REQUIRE_CONSENT.includes(type)) {
        return next(action);
    }

    storeAPI.dispatch(
        showDialog({
            type: 'consent',
            action,
            actionParams: params
        })
    );

    return null;
};
