import { Box, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React from 'react';
import { getPredictionColor } from '../../../utils/imageDemo';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
    },
    chip: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: 120,
        padding: theme.spacing(1.5),
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(2),
    },
    percentageContainer: {
        width: 280,
        height: 6,
    },
    percentageValue: {
        width: props => `${props.value * 280}px`,
        height: 6,
    },
}));

const ThresholdDescriptionLinearProgress = withStyles(theme => ({
    root: {
        height: 6,
        backgroundColor: theme.palette.grey['800'],
        borderRadius: theme.shape.borderRadius,
        width: '100%',
    },
    bar: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: props => getPredictionColor(props.value / 100),
    },
}))(LinearProgress);

export const ThresholdDescription = ({ text, value, range }) => {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Box className={classes.chip}>
                <Typography variant="body2">{text}</Typography>
                <Typography
                    style={{
                        color: getPredictionColor(value),
                        fontWeight: 700,
                        fontSize: 12,
                    }}
                    variant="body2"
                >
                    {range}
                </Typography>
            </Box>
            <ThresholdDescriptionLinearProgress variant="determinate" value={value * 100} />
        </Box>
    );
};

ThresholdDescription.ThresholdDescription = {
    text: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    range: PropTypes.string.isRequired,
};
