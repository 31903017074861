import { normalize, schema } from 'normalizr';
import hash from 'object-hash';
import { generatePath } from 'react-router-dom';
import { all, call, cancel, delay, fork, put, select, take } from 'redux-saga/effects';
import * as api from '../../api';
import { ConceptPageType } from '../../pages/concept/components/types';
import { CONCEPT } from '../../routes/routes';
import { dialogTypes } from '../../utils/constants';
import { logger } from '../../utils/logging';
import { actions } from '../actions';
import {
    CREATE_CONCEPT_CONFIRMED_REQ,
    CREATE_CONCEPT_REQ,
    FETCH_RESOURCES_REQ,
    PUBLISH_CONCEPT_CONFIRMED_REQ,
    PUBLISH_CONCEPT_REQ,
    REMOVE_CONCEPT_CONFIRMED_REQ,
    REMOVE_CONCEPT_REQ,
    THRESHOLD_CONCEPT_CONFIRMED_REQ,
    THRESHOLD_CONCEPT_REQ,
} from '../actions/actionTypes/concepts';
import { showDialog } from '../actions/dialog';
import { resourceSelector } from '../selectors/concepts';
const resourceHashId = ({ name, params = null, query = null }) => {
    return hash({ name, params, query });
};

/* ** SUBROUTINES ** */

const createEntitySchema = name =>
    new schema.Entity(
        name,
        {},
        {
            items: new schema.Array(),
        }
    );

const createResourceMapEntry = (name, apiMethod) => ({
    [name]: { resSchema: createEntitySchema(name), apiMethod },
});

const resNormz = (normzSchema, name, params, query, response, cachedPayload) =>
    Promise.resolve(
        normalize(
            {
                id: resourceHashId({ name, params, query }),
                hash: { name, params, query },
                isLoading: !response,
                response: response || cachedPayload,
            },
            normzSchema
        )
    );

// RESOURCE MAP

const resourceMap = {
    ...createResourceMapEntry('concepts', api.fetchClassifiers),
};

function* getGallery() {
    yield put(actions.imageTaggingActions.getGallery(true));
}

export function* fetchResources(
    resource,
    params = null,
    query = null,
    debounce = 0,
    useCache = true
) {
    yield delay(debounce);

    const resourceConfig = resourceMap[resource];
    if (!resourceConfig) {
        console.log(`resourceConfig not found ${resource}`);
    }
    const { resSchema, apiMethod } = resourceConfig;

    // NOT CACHED
    let cachedPayload;
    // CACHED
    const resEntityCache = yield select(resourceSelector(resource, params, query));

    if (useCache && resEntityCache) {
        cachedPayload = resEntityCache.response;
    }
    try {
        const resLoading = yield call(
            resNormz,
            resSchema,
            resource,
            params,
            query,
            null,
            cachedPayload
        );
        yield put({
            type: `RESOURCE_UPDATE_${String(resource).toUpperCase()}`,
            ...resLoading,
            phase: 'start',
        });
        const payload = yield call(apiMethod, params, query);
        const payloadNormz = yield call(resNormz, resSchema, resource, params, query, payload);
        yield put({
            type: `RESOURCE_UPDATE_${String(resource).toUpperCase()}`,
            ...payloadNormz,
            query,
            phase: 'complete',
        });
    } catch (e) {
        logger.error(`fetching resources has failed ${e}`);
    }

    return { resource };
}

function* watchLoadResources() {
    let task;
    while (true) {
        const { resource, query, params, debounce } = yield take(FETCH_RESOURCES_REQ);
        if (task && debounce > 0) yield cancel(task);
        task = yield fork(fetchResources, resource, params, query, debounce);
    }
}

function* watchCreateConcept({ history }) {
    while (true) {
        yield take(CREATE_CONCEPT_REQ);

        yield put(
            showDialog({
                type: dialogTypes.conceptCreate,
                action: {
                    type: CREATE_CONCEPT_CONFIRMED_REQ,
                },
                actionParams: {},
            })
        );
    }
}

function* watchCreateConfirmConcept({ history }) {
    while (true) {
        const { params } = yield take(CREATE_CONCEPT_CONFIRMED_REQ);
        const { name } = params;
        try {
            const payload = yield call(api.createClassifier, { name });
            const { id } = payload;
            history.push(
                generatePath(CONCEPT.pathname, {
                    id,
                    type: ConceptPageType.selection,
                })
            );
            yield call(fetchResources, 'concepts', null, null, 0, false);
        } catch (err) {
            yield put(
                showDialog({
                    type: 'error',
                    action: {},
                    actionParams: { message: err.message || 'Error creating a new Concept.' },
                })
            );
        }
    }
}

function* watchRemoveConfirmConcept() {
    while (true) {
        const { params } = yield take(REMOVE_CONCEPT_CONFIRMED_REQ);
        try {
            yield call(api.removeClassifier, params);
            yield call(fetchResources, 'concepts', null, null, 0, false);
            yield call(getGallery);
        } catch (err) {
            console.log('watchRemoveConcept.err:', err);
        }
    }
}

function* watchPublishConfirmConcept() {
    while (true) {
        const { params } = yield take(PUBLISH_CONCEPT_CONFIRMED_REQ);
        try {
            yield call(api.lockClassifier, params);
            yield call(fetchResources, 'concepts', null, null, 0, false);
        } catch (err) {
            console.log('watchPublishConcept.err:', err);
        }
    }
}

function* watchThresholdConfirmConcept() {
    while (true) {
        const { params } = yield take(THRESHOLD_CONCEPT_CONFIRMED_REQ);
        try {
            yield call(api.sendThresholdClassifier, params);
            yield call(fetchResources, 'concepts', null, null, 0, false);
            yield call(getGallery);
            yield put(
                showDialog({
                    type: 'success',
                    action: {},
                    actionParams: { message: 'New threshold was successfully set.' },
                })
            );
        } catch (err) {
            yield put(
                showDialog({
                    type: 'error',
                    action: {},
                    actionParams: { message: err.message || 'An error has occurred!' },
                })
            );
            console.log('watchThresholdConfirmConcept.err:', err);
        }
    }
}

function* watchRemoveConcept() {
    while (true) {
        const { params } = yield take(REMOVE_CONCEPT_REQ);

        yield put(
            showDialog({
                type: 'concept_del_conf',
                action: { type: REMOVE_CONCEPT_CONFIRMED_REQ },
                actionParams: params,
            })
        );
    }
}

function* watchPublishConcept() {
    while (true) {
        const { params } = yield take(PUBLISH_CONCEPT_REQ);

        yield put(
            showDialog({
                type: 'concept_pub_conf',
                action: { type: PUBLISH_CONCEPT_CONFIRMED_REQ },
                actionParams: params,
            })
        );
    }
}

function* watchThresholdConcept() {
    while (true) {
        const { params } = yield take(THRESHOLD_CONCEPT_REQ);
        yield put(
            showDialog({
                type: 'concept_threshold_conf',
                action: { type: THRESHOLD_CONCEPT_CONFIRMED_REQ },
                actionParams: params,
            })
        );
    }
}

export const sagas = params =>
    all([
        watchLoadResources(params),

        watchCreateConcept(params),
        watchCreateConfirmConcept(params),

        watchPublishConcept(),
        watchPublishConfirmConcept(params),

        watchThresholdConcept(),
        watchThresholdConfirmConcept(params),

        watchRemoveConcept(),
        watchRemoveConfirmConcept(params),
    ]);
