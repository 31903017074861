import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

/**
 * On desktop buttons are shown as a row, on mobile as a column
 */
const ButtonContainer = withStyles(theme => ({
    root: {
        marginTop: theme.spacing(3),
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        '& > *': {
            maxHeight: 45,
            width: '50%',
        },

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            '& > *': {
                width: '100%',
            },
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        }

    }
}))(({ classes, children }) => <Box className={classes.root}>{children}</Box>);

export { ButtonContainer };
