import {
    CREATE_CONCEPT_REQ,
    FETCH_RESOURCES_REQ,
    PUBLISH_CONCEPT_REQ,
    REMOVE_CONCEPT_REQ,
    THRESHOLD_CONCEPT_REQ,
} from './actionTypes/concepts';

export const fetchResource = (resource, params, query, debounce) => ({
    type: FETCH_RESOURCES_REQ,
    resource,
    params,
    query,
    debounce,
});

export const createConcept = params => ({ type: CREATE_CONCEPT_REQ, params });
export const removeConcept = params => ({ type: REMOVE_CONCEPT_REQ, params });
export const publishConcept = params => ({ type: PUBLISH_CONCEPT_REQ, params });
export const setConceptThreshold = (params, userThreshold) => ({
    type: THRESHOLD_CONCEPT_REQ,
    params,
    threshold: userThreshold,
});

export const actions = {
    fetchResource,
    createConcept,
    removeConcept,
    publishConcept,
    setConceptThreshold,
};
