import { CircularProgress, makeStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5)
    },
    text: {
        color: theme.palette.common.black
    }
}));

const BasePopover = ({ open, message, showProgress }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                open={open}
                ContentProps={{
                    'aria-describedby': 'message-id'
                }}
                message={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            minWidth: '250px'
                        }}>
                        <div
                            id="message-id"
                            style={{
                                marginRight: '16px'
                            }}>
                            <Typography className={classes.text} variant="body2">
                                {message}
                            </Typography>
                        </div>
                        {showProgress && <CircularProgress color="secondary" />}
                    </div>
                }
                action={[]}
            />
        </div>
    );
};

BasePopover.propTypes = {
    message: PropTypes.any,
    open: PropTypes.bool,
    showProgress: PropTypes.bool
};

BasePopover.defaultProps = {
    message: '',
    open: false,
    showProgress: true
};

export { BasePopover };
