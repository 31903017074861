import { Tab, Tabs } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { DEFAULT_ROUTE, REGISTER_USER } from '../../../routes/routes';

const useStyles = makeStyles(theme => ({
    tabs: {
        display: 'flex',
        width: '100%',
        marginBottom: theme.spacing(4),
        fontSize: 20
    },
    tabsIndicator: {
        width: '30px!important'
    },
    tab: {
        textTransform: 'capitalize',
        minWidth: 0,
        padding: 0,
        marginRight: theme.spacing(5),
        fontSize: 18,
        opacity: 0.2
    }
}));

const LoginTabs = ({ value, history }) => {
    const classes = useStyles();

    return (
        <Tabs
            classes={{
                indicator: classes.tabsIndicator
            }}
            className={classes.tabs}
            value={value}>
            <Tab
                onClick={() => {
                    if (value !== 0) {
                        history.push(DEFAULT_ROUTE);
                    }
                }}
                className={classes.tab}
                style={value === 0 ? { opacity: 1 } : {}}
                value="0"
                label="Sign In"
                data-test="login_tab"
            />
            <Tab
                value="1"
                label="Register"
                className={classes.tab}
                style={value === 1 ? { opacity: 1 } : {}}
                onClick={() => {
                    if (value !== 1) {
                        history.push(REGISTER_USER.pathname);
                    }
                }}
                data-test="register-tab"
            />
        </Tabs>
    );
};

LoginTabs.propTypes = {
    history: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired
};

export { LoginTabs };
