export const earthObservationEn = {
    pageHeader: 'Earth Observation',
    pageContent: {
        title: 'What would you like to analyse?',
        selectOne: 'Please select one.',
        types: {
            clouds: 'Clouds',
            Ship: 'Ship',
            Airplane: 'Airplane',
            Other: 'Other',
        },
        goButton: "Let's Go",
        info: {
            title: 'How can our Earth Observation AI models help you?',
            list: {
                item1: 'Cloud: Satellite manufactures and data aggregators can decide which images to be transferred from space',
                item2: 'Ship: Shipping companies can identify where and how many ships are located in the sea',
                item3: 'Airplane: Aircraft companies can identify the traffic in the air and decide which route to take',
            },
        },
    },
    common: {
        selectOne: 'Select an image.',
        uploadImage: 'Upload an Image',
        showResultButton: 'Show Result',
        seeOriginalImage: 'See Original Image',
        predictionResults: 'Prediction Results',
        infoSidebarBox: 'Click on Show Results to view Result',
    },
    clouds: {
        pageHeader: 'Cloud Segmentation',
        pageContent: {
            subtitle: 'Cloud Coverage',
            title: 'Let’s see what our AI predicts the cloud coverage % is.',
        },
    },
    ship: {
        pageHeader: 'Ship Detection',
        pageContent: {
            title: 'Let’s see what our AI predicts where the ships are located.',
        },
    },
    airplane: {
        pageHeader: 'Airplane Detection',
        pageContent: {
            title: 'Let’s see what our AI predicts where the airplane are located.',
        },
    },
};
