import { GA4React } from 'ga-4-react';
import TagManager from 'react-gtm-module';
import { AUTH_LOGIN_SUCCESS } from '../redux/actions/actionTypes/auth';
import {
    IMPROVE_CONCEPT_REQ,
    TRAIN_CONCEPT_REQ,
    VALIDATE_CONCEPT_REQ,
} from '../redux/actions/actionTypes/concept';
import {
    CREATE_CONCEPT_CONFIRMED_REQ,
    CREATE_CONCEPT_REQ,
} from '../redux/actions/actionTypes/concepts';
import {
    imageDemoPrefixes,
    REMOVE_FACE_IDENTITY,
    SAVE_FACE_IDENTITY,
} from '../redux/actions/actionTypes/imageDemo';
import { SHARE_IMAGE_DEMO_UPLOADS, SHARE_VIDEO_UPLOADS } from '../redux/actions/actionTypes/share';
import { UPLOAD_IMAGES_REQ } from '../redux/actions/actionTypes/upload';
import { USER_REGISTERED, USER_VERIFIED } from '../redux/actions/actionTypes/user';
import { videoPrefixes } from '../redux/actions/actionTypes/video';
import { folderTypes } from './constants';
import { isMobius } from './env';

type eventType = Record<
    string,
    {
        type?: string;
        payload?: {
            event_category: string;
            event_label: string;
            value: string;
        };
        preprocess?: (props: any) => {
            type?: string;
            payload?: {
                event_category: string;
                event_label: string;
                value: string;
            };
        };
    }
>;

export const analyticsEvents: eventType = {
    [AUTH_LOGIN_SUCCESS]: {
        type: 'login-success',
    },
    [USER_REGISTERED]: {
        type: 'user-register',
    },
    [USER_VERIFIED]: {
        type: 'user-verify',
    },
    [CREATE_CONCEPT_REQ]: {
        type: 'concept-new',
    },
    [CREATE_CONCEPT_CONFIRMED_REQ]: {
        type: 'concept-training-overview',
    },
    [TRAIN_CONCEPT_REQ]: {
        type: 'concept-train',
    },
    [IMPROVE_CONCEPT_REQ]: {
        type: 'concept-improve',
    },
    [UPLOAD_IMAGES_REQ]: {
        preprocess: ({ params }) => {
            if (Object.values(folderTypes).includes(params.folderId)) {
                return {
                    type: `concept-${params.folderId}-upload`,
                };
            }
            return { type: `concept-upload` };
        },
    },
    [VALIDATE_CONCEPT_REQ]: {
        type: 'concept-test-finished',
    },
    [SHARE_IMAGE_DEMO_UPLOADS]: {
        preprocess: ({ payload }) => ({
            type: `${payload.predictionType}-shared`,
        }),
    },
    [SHARE_VIDEO_UPLOADS]: {
        type: 'tagging-video-shared',
    },
    [SHARE_IMAGE_DEMO_UPLOADS]: {
        type: 'tagging-images-shared',
    },
    [SAVE_FACE_IDENTITY]: {
        type: 'face-identity-saved',
    },
    [REMOVE_FACE_IDENTITY]: {
        type: 'face-identity-removed',
    },
    'contact-mobian': {
        type: 'contact-mobian',
    },
};

Object.values(videoPrefixes).forEach((prefix: string) => {
    analyticsEvents[prefix + '_UPLOAD_VIDEO_REQUEST'] = {
        type: prefix.toLowerCase() + '_video_upload',
    };
});

Object.values(imageDemoPrefixes).forEach((prefix: string) => {
    analyticsEvents[prefix + '_UPLOAD_IMAGE_REQUEST'] = {
        type: prefix.toLowerCase() + '_image_upload',
    };
});

const gaId = process.env.REACT_APP_GA_ID || '';
const googleAdsId = process.env.REACT_APP_G_ADS_ID || '';
const gtmId = process.env.REACT_APP_GTM_ID || '';
const googleAdsLabel = process.env.REACT_APP_G_ADS_LABEL;
let initGA = () => {};
let updateGAConfig = (config: any) => {};
let trackAdsConversion = () => {};

// renaming gtm global variable to avoid conflict with ga4
const GTM_DATALAYER_NAME = 'gtmDataLayer';

let trackEvent = (
    action: { type: string; payload?: { predictionType?: string } },
    isAuthenticated?: boolean,
    currentUser?: any
) => {};

if (gtmId && !gaId) {
    console.error('If you set up REACT_APP_GTM_ID, you should also set up REACT_APP_GA_ID');
}

if (isMobius() && (gaId || googleAdsId || gtmId)) {
    const ga4react = new GA4React(gaId || googleAdsId);

    const tagManagerArgs = {
        gtmId,
        dataLayerName: GTM_DATALAYER_NAME,
    };

    TagManager.initialize(tagManagerArgs);

    initGA = async () => {
        try {
            await ga4react.initialize();
            if (googleAdsId) {
                ga4react.gtag('config', googleAdsId);
            }
        } catch {
            // probably ga is blocked by adBlocker
        }
    };

    if (gaId) {
        updateGAConfig = (config: any) => {
            try {
                ga4react.gtag('config', gaId, config);
                TagManager.dataLayer({
                    dataLayer: {
                        ...config,
                    },
                    dataLayerName: GTM_DATALAYER_NAME,
                });
            } catch {
                // probably ga is blocked by adBlocker
            }
        };

        trackEvent = (action, isAuthenticated, currentUser) => {
            try {
                // tracking only anonymous or external (not working at Mobious) users
                if (!isAuthenticated || !currentUser?.isPrivate) {
                    const event = analyticsEvents[action.type];

                    if (event) {
                        if (event.preprocess) {
                            const processedEvent = event.preprocess(action);
                            ga4react.gtag('event', processedEvent.type, event.payload);

                            TagManager.dataLayer({
                                dataLayer: {
                                    event: processedEvent.type,
                                    ...event.payload,
                                },
                                dataLayerName: GTM_DATALAYER_NAME,
                            });
                        } else {
                            ga4react.gtag('event', event.type, event.payload);

                            TagManager.dataLayer({
                                dataLayer: {
                                    event: event.type,
                                    ...event.payload,
                                },
                                dataLayerName: GTM_DATALAYER_NAME,
                            });
                        }
                    }
                }
            } catch {
                // probably ga is blocked by adBlocker
            }
        };
    }

    if (googleAdsId) {
        trackAdsConversion = () => {
            try {
                ga4react.gtag('event', 'conversion', {
                    send_to: `${googleAdsId}/${googleAdsLabel}`,
                });
            } catch {
                // probably ga is blocked by adBlocker
            }
        };
    }
}

export { initGA, updateGAConfig, trackEvent, trackAdsConversion };
