import { Box, FormControl, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { minLength } from '../../../utils/validators';

const useStyles = makeStyles(() => ({
    helperContainer: {
        width: '100%',
    },
    helper: {
        color: 'black',
        opacity: 0.6,
    },
}));

const PasswordFormControl = ({
    disabled,
    onEnter,
    password,
    onChangePassword,
    index,
    placeholder,
    skipValidation,
    showHelperText,
}) => {
    const classes = useStyles();
    const setPassword = e => {
        onChangePassword({
            value: e.currentTarget.value,
            error: skipValidation ? false : !minLength(e.currentTarget.value, 6),
            message: password.message,
        });
    };

    return (
        <>
            <FormControl margin="dense" disabled={disabled} fullWidth>
                <TextField
                    data-test={`login-password${index}`}
                    inputProps={{
                        onKeyUp: onEnter,
                    }}
                    name={`password${index}`}
                    type="password"
                    id={`password${index}`}
                    placeholder={placeholder}
                    autoComplete="current-password"
                    value={password.value}
                    onChange={setPassword}
                    helperText={password.message}
                    error={password.error}
                />
            </FormControl>
            {showHelperText && (
                <Box className={classes.helperContainer}>
                    <Typography className={classes.helper} variant="body2">
                        At least 6 characters.
                    </Typography>
                </Box>
            )}
        </>
    );
};

PasswordFormControl.propTypes = {
    onEnter: PropTypes.func.isRequired,
    onChangePassword: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    password: PropTypes.object.isRequired,
    index: PropTypes.string,
    placeholder: PropTypes.string,
    skipValidation: PropTypes.bool,
    showHelperText: PropTypes.bool,
};

PasswordFormControl.defaultProps = {
    index: '',
    placeholder: 'Password',
    disabled: false,
    skipValidation: false,
    showHelperText: false,
};

export { PasswordFormControl };
