import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    smallLogo: {
        height: 52,
        marginBottom: theme.spacing(5),
    },
}));

function LogoContainer() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <img src="/assets/mobius_logo_small.svg" alt="" className={classes.smallLogo} />
        </div>
    );
}

export { LogoContainer };
