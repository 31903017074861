export const usersEn = {
    filters: {
        search: {
            placeholder: 'Search User',
        },
        private: {
            label: 'Private',
            options: {
                all: 'All',
                private: 'Private',
                external: 'External',
            },
        },
        confirmation: {
            label: 'Email Confirmed',
            options: {
                all: 'All',
                confirmed: 'Confirmed',
                pending: 'Pending',
            },
        },
    },
    fields: {
        username: 'Username',
        password: 'Password',
        repeatPassword: 'Repeat Password',
        imageUploadLimit: 'Image upload limit:',
        role: 'Role',
        maxImages: 'Max. Images',
        uploadedImages: 'Uploaded images',
        validUntil: 'Valid until',
        features: 'Features',
        isPrivate: 'Is Private',
    },
    createNewUser: 'Create new User',
};
