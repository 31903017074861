import { Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { ConceptTypes } from '../../pages/concept/ConceptItem/ConceptItem';
import { selectors } from '../../redux/selectors';

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        maxWidth: '600px',
        padding: theme.spacing(4),
    },

    bodyContainer: {
        padding: theme.spacing(1, 0),
        marginBottom: theme.spacing(3),
    },

    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: '70px',
    },
    button: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(0, 2),
        minWidth: '100px',
        height: '36px',
    },
}));

export const CreateConceptDialogBase = ({
    onClose,
    action,
    actionParams,
    isLoading,
    concepts,
}: Props) => {
    const classes = useStyles();

    const [name, setName] = useState('');

    const handleConfirm = useCallback(() => {
        onClose(action, { ...actionParams, name });
    }, [action, actionParams, name, onClose]);

    const validateConcepts = useCallback(() => {
        if (concepts) return concepts.some((concept: ConceptTypes) => concept.name === name);
        return false;
    }, [concepts, name]);

    if (isLoading) {
        return null;
    }

    const hasError = validateConcepts();

    return (
        <div className={classes.dialogContainer}>
            <div className={classes.bodyContainer}>
                <Typography variant="h5">
                    To create a new custom concept, please enter a name
                </Typography>
            </div>
            <div>
                <Typography variant="h5">Concept Name</Typography>
            </div>
            <div className={classes.buttonContainer}>
                <TextField
                    error={hasError}
                    helperText={hasError ? 'the name already exists' : ''}
                    data-test="new-concept-name"
                    variant="outlined"
                    placeholder="enter concept name"
                    value={name}
                    onChange={e => setName(e.currentTarget.value)}
                    style={{
                        margin: '16px 0px',
                        width: '100%',
                    }}
                    inputProps={{
                        style: {
                            padding: '10px 24px',
                            fontSize: '14px',
                            backgroundColor: 'rgba(196, 196, 196, 0.3)',
                        },
                    }}
                />
                <Button
                    data-test="new-concept-name-confirm"
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={handleConfirm}
                    disabled={name.length < 3 || hasError}
                >
                    Save
                </Button>
            </div>
        </div>
    );
};

interface Props {
    action: Record<string, any>;
    actionParams: Record<string, any>;
    onClose: (action: object, params: any) => void;
    isLoading: boolean;
    concepts: ConceptTypes[];
}

const mapStateToProps = (state: any) => {
    const resourceEntity = selectors.resourceSelector('concepts')(state);
    const { isLoading, response: concepts } = resourceEntity || {};
    return {
        isLoading,
        concepts,
    };
};

const CreateConceptDialog = connect(mapStateToProps, null)(CreateConceptDialogBase);

export { CreateConceptDialog };
