import { connect } from 'react-redux';
import { selectors } from '../../redux/selectors';
import { FAILED_IMAGES_REUPLOADS } from '../../utils/config';
import { BasePopover } from './BasePopover';

const mapStateToProps = state => {
    const uploadQueue = selectors.getUploadQueue()(state);
    const uploadResult = selectors.getUploadResult()(state);
    const taskName =
        uploadResult.reuploadsLeft === FAILED_IMAGES_REUPLOADS ? 'Uploading' : 'Retrying to upload';
    const open = Boolean(uploadQueue.length);
    const message = open && `Task: ${taskName}. ${uploadQueue.length} images left.`;
    return {
        message,
        open
    };
};

const UploadPopover = connect(mapStateToProps)(BasePopover);

export { UploadPopover };
