import { AppConfig } from '../utils/commonTypes';

export const DEFAULT_ROUTE = '/';

type RouteConfig = {
    getVisibility: (appConfig: AppConfig) => boolean;
    public?: boolean;
};

const createRoute = (path: string, routeConfig: RouteConfig) => {
    const getVisibility = routeConfig?.getVisibility || (() => true);
    return { pathname: `${path}`, ...routeConfig, getVisibility };
};

export const REGISTER_USER = createRoute('/register', {
    getVisibility: appConfig => appConfig.settings.isMobius,
    public: true,
});
export const CONFIRM_EMAIL = createRoute('/confirm_email/:id', {
    getVisibility: appConfig => appConfig.settings.isMobius,
    public: true,
});
export const CONFIRMATION_EMAIL_SEND = createRoute('/email_confirmation_send', {
    getVisibility: appConfig => appConfig.settings.isMobius,
    public: true,
});
export const RESET_PASSWORD_REQUEST = createRoute('/change_password', {
    getVisibility: appConfig => appConfig.settings.isMobius,
    public: true,
});
export const RESET_PASSWORD_SEND = createRoute('/change_password_send', {
    getVisibility: appConfig => appConfig.settings.isMobius,
    public: true,
});
export const RESET_PASSWORD = createRoute('/change_password/:id', {
    getVisibility: appConfig => appConfig.settings.isMobius,
    public: true,
});
export const CHANGE_PASSWORD_SUCCESS = createRoute('/change_password_success', {
    getVisibility: appConfig => appConfig.settings.isMobius,
    public: true,
});
export const GOOGLE_LOGIN_PAGE = createRoute('/google-login-redirect', {
    getVisibility: appConfig => appConfig.settings.isMobius,
    public: true,
});

export const DASHBOARD = createRoute('/dashboard', {
    getVisibility: appConfig =>
        appConfig.features.cti ||
        appConfig.features.imageDemo ||
        appConfig.features.videoTagging ||
        appConfig.features.imageFacialRecognition,
});
export const CONCEPTS = createRoute('/concepts', {
    getVisibility: appConfig => appConfig.features.cti,
});
export const IMAGE_DEMO = createRoute('/image/tagging', {
    getVisibility: appConfig => appConfig.features.imageDemo,
});
export const IMAGE_DEMO_UPLOADS = createRoute('/image/tagging/uploads', {
    getVisibility: appConfig => appConfig.features.imageDemo,
});

export const VIDEO_TAGGING = createRoute('/video/tagging', {
    getVisibility: appConfig => appConfig.features.videoTagging,
});

export const VIDEO_TAGGING_UPLOADS = createRoute('/video/tagging/uploads', {
    getVisibility: appConfig => appConfig.features.videoTagging,
});

// Start Concept
export const CONCEPT_NEW = createRoute('/concepts/new', {
    getVisibility: appConfig => appConfig.features.cti,
});

// Concept V2
export const CONCEPT = createRoute('/concept/:id/:type', {
    getVisibility: appConfig => appConfig.features.cti,
});
//

export const USER = createRoute('/user', {
    getVisibility: appConfig => appConfig.features.userAdmin,
});
export const USER_EDIT = createRoute('/user-edit/:id', {
    getVisibility: appConfig => appConfig.features.userAdmin,
});
export const USER_CREATE = createRoute('/user-edit', {
    getVisibility: appConfig => appConfig.features.userAdmin,
});

export const IMAGE_FACIAL_RECOGNITION = createRoute('/image/facial-recognition', {
    getVisibility: appConfig => appConfig.features.imageFacialRecognition,
});

export const IMAGE_FACIAL_RECOGNITION_UPLOADS = createRoute('/image/facial-recognition/uploads', {
    getVisibility: appConfig => appConfig.features.imageFacialRecognition,
});

export const IMAGE_LOGO_DETECTION = createRoute('/image/logo-detection', {
    getVisibility: appConfig => appConfig.features.logoDetection,
});

export const IMAGE_LOGO_DETECTION_UPLOADS = createRoute('/image/logo-detection/uploads', {
    getVisibility: appConfig => appConfig.features.logoDetection,
});

export const IMAGE_LOGO_DETECTION_DEFAULT = createRoute('/image/logo-detection/default', {
    getVisibility: appConfig => appConfig.features.logoDetection,
});

export const IMAGE_LOGO_DETECTION_USER = createRoute('/image/logo-detection/user', {
    getVisibility: appConfig => appConfig.features.logoDetection,
});

export const LOGO_DETECTION_ADMIN_PAGE = createRoute('/admin/logo-detection', {
    getVisibility: appConfig => appConfig.features.userAdmin && appConfig.features.logoDetection,
});

export const LOGO_DETECTION_ADMIN_GROUP_PAGE = createRoute('/admin/logo-detection/group/:groupId', {
    getVisibility: appConfig => appConfig.features.userAdmin && appConfig.features.logoDetection,
});

export const VIDEO_FACIAL_RECOGNITION = createRoute('/video/facial-recognition', {
    getVisibility: appConfig => appConfig.features.videoFacialRecognition,
});

export const VIDEO_FACIAL_RECOGNITION_UPLOADS = createRoute('/video/facial-recognition/uploads', {
    getVisibility: appConfig => appConfig.features.videoFacialRecognition,
});

export const VIDEO_SHOT_DETECTION = createRoute('/video/shot-detection', {
    getVisibility: appConfig => appConfig.features.videoShotDetection,
});

export const VIDEO_SHOT_DETECTION_UPLOADS = createRoute('/video/shot-detection/uploads', {
    getVisibility: appConfig => appConfig.features.videoShotDetection,
});

export const VIDEO_HIGHLIGHTS = createRoute('/video/highlights', {
    getVisibility: appConfig => appConfig.features.videoHighlights,
});

export const VIDEO_HIGHLIGHTS_UPLOADS = createRoute('/video/highlights/uploads', {
    getVisibility: appConfig => appConfig.features.videoHighlights,
});

export const VIDEO_PROFANITY_DETECTION = createRoute('/video/profanity-detection', {
    getVisibility: appConfig => appConfig.settings.isMobius,
});

export const SEARCH_ADMIN = createRoute('/admin/search', {
    getVisibility: appConfig => appConfig.features.userAdmin && appConfig.features.textSearch,
});

export const SEARCH = createRoute('/search', {
    getVisibility: appConfig => !appConfig.features.userAdmin && appConfig.features.textSearch,
});

export const IMAGE_SHARE = createRoute('/image/share/:shareId', {
    getVisibility: () => true,
});

export const VIDEO_SHARE = createRoute('/video/share/:shareId', {
    getVisibility: () => true,
});

export const EARTH_OBSERVATION = createRoute('/earth-observation', {
    getVisibility: appConfig => appConfig.features.earthObservation,
});

export const EARTH_OBSERVATION_CLOUDS = createRoute('/earth-observation/clouds', {
    getVisibility: appConfig => appConfig.features.earthObservation,
});

export const EARTH_OBSERVATION_SHIP = createRoute('/earth-observation/ship', {
    getVisibility: appConfig => appConfig.features.earthObservation,
});

export const EARTH_OBSERVATION_AIRPLANE = createRoute('/earth-observation/airplane', {
    getVisibility: appConfig => appConfig.features.earthObservation,
});
export const OBJECT_SEGMENTATION = createRoute('/object-segmentation', {
    getVisibility: appConfig => appConfig.settings.isMobius,
});

export const CUSTOM_OBJECT_DETECTION = createRoute('/detection/custom', {
    getVisibility: appConfig => appConfig.features.objectDetection,
});

export const CUSTOM_OBJECT_DETECTION_DETAILS = createRoute('/detection/custom/:id', {
    getVisibility: appConfig => appConfig.features.objectDetection,
});

export const AUDIO_TAGGING = createRoute('/audio/tagging', {
    getVisibility: appConfig => appConfig.settings.isMobius,
});

export const AUDIO_SPEECH_TO_TEXT = createRoute('/audio/speech-to-text', {
    getVisibility: appConfig => appConfig.settings.isMobius,
});

export const AUDIO_PROFANITY_DETECTION = createRoute('/audio/profanity-detection', {
    getVisibility: appConfig => appConfig.settings.isMobius,
});

export const INDUSTRY_IMAGE_ARCHIVES = createRoute('/industry/image-archives', {
    getVisibility: appConfig =>
        appConfig.settings.isMobius &&
        (CONCEPTS.getVisibility(appConfig) ||
            VIDEO_HIGHLIGHTS.getVisibility(appConfig) ||
            IMAGE_DEMO.getVisibility(appConfig) ||
            IMAGE_FACIAL_RECOGNITION.getVisibility(appConfig)),
});

export const INDUSTRY_I2P = createRoute('/industry/internet-2-print', {
    getVisibility: appConfig =>
        appConfig.settings.isMobius &&
        (IMAGE_DEMO.getVisibility(appConfig) || IMAGE_FACIAL_RECOGNITION.getVisibility(appConfig)),
});

export const INDUSTRY_PRESS = createRoute('/industry/press', {
    getVisibility: appConfig =>
        appConfig.settings.isMobius &&
        (IMAGE_DEMO.getVisibility(appConfig) ||
            IMAGE_FACIAL_RECOGNITION.getVisibility(appConfig) ||
            SEARCH.getVisibility(appConfig) ||
            SEARCH_ADMIN.getVisibility(appConfig)),
});

export const routes = [
    DASHBOARD,
    CONCEPTS,
    CONCEPT_NEW,
    IMAGE_DEMO,
    IMAGE_DEMO_UPLOADS,
    USER,
    USER_EDIT,
    USER_CREATE,
    IMAGE_SHARE,
    REGISTER_USER,
    RESET_PASSWORD,
    RESET_PASSWORD_REQUEST,
    CONFIRM_EMAIL,
    CONFIRMATION_EMAIL_SEND,
    RESET_PASSWORD_SEND,
    CHANGE_PASSWORD_SUCCESS,
    VIDEO_TAGGING,
    VIDEO_TAGGING_UPLOADS,
    VIDEO_PROFANITY_DETECTION,
    IMAGE_FACIAL_RECOGNITION,
    IMAGE_FACIAL_RECOGNITION_UPLOADS,
    VIDEO_FACIAL_RECOGNITION,
    VIDEO_FACIAL_RECOGNITION_UPLOADS,
    VIDEO_HIGHLIGHTS,
    VIDEO_HIGHLIGHTS_UPLOADS,
    VIDEO_SHOT_DETECTION,
    VIDEO_SHOT_DETECTION_UPLOADS,
    VIDEO_SHARE,
    SEARCH_ADMIN,
    EARTH_OBSERVATION,
    EARTH_OBSERVATION_CLOUDS,
    EARTH_OBSERVATION_SHIP,
    EARTH_OBSERVATION_AIRPLANE,
    OBJECT_SEGMENTATION,
    CUSTOM_OBJECT_DETECTION,
    CUSTOM_OBJECT_DETECTION_DETAILS,
    LOGO_DETECTION_ADMIN_PAGE,
    LOGO_DETECTION_ADMIN_GROUP_PAGE,
    AUDIO_TAGGING,
    AUDIO_SPEECH_TO_TEXT,
    AUDIO_PROFANITY_DETECTION,
    GOOGLE_LOGIN_PAGE,
    SEARCH,
    CONCEPT,
    INDUSTRY_IMAGE_ARCHIVES,
    INDUSTRY_I2P,
    INDUSTRY_PRESS,
    IMAGE_LOGO_DETECTION,
    IMAGE_LOGO_DETECTION_UPLOADS,
    IMAGE_LOGO_DETECTION_DEFAULT,
    IMAGE_LOGO_DETECTION_USER,
];
