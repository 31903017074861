import { ConceptInfo } from '../../utils/commonTypes';
import {
    ASSIGN_SEARCH_IMAGES_REQ,
    GET_CONCEPT_INFO_REQ,
    GET_CONCEPT_INFO_SUCCESS,
    IMAGE_DELETE_REQ,
    IMAGE_RATE_REQ,
    TRAIN_CONCEPT_REQ,
    VALIDATE_CONCEPT_REQ,
} from './actionTypes/concept';

export const getConceptInfo = (id: number) => ({ type: GET_CONCEPT_INFO_REQ, params: { id } });

export const getConceptInfoSuccess = (concept: ConceptInfo) => ({
    type: GET_CONCEPT_INFO_SUCCESS,
    params: { concept },
});

export const trainConcept = (id: number) => ({ type: TRAIN_CONCEPT_REQ, params: { id } });
export const validateConcept = (id: number) => ({ type: VALIDATE_CONCEPT_REQ, params: { id } });

export const assignSearchImages = (conceptId: number, folder: string, imageIds: string[]) => {
    return {
        type: ASSIGN_SEARCH_IMAGES_REQ,
        payload: { conceptId, folder, imageIds },
    };
};

export const deleteImages = (id: number, selectedImages: string[]) => ({
    type: IMAGE_DELETE_REQ,
    params: { id, selectedImages },
});

export const rateImage = (id: number, filename: string, mode: 'positive' | 'negative' | null) => ({
    type: IMAGE_RATE_REQ,
    params: { id, filename, mode },
});
