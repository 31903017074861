import { DEFAULT_ROUTE } from '../../routes/routes';

const routerReducer = (state = { pathname: DEFAULT_ROUTE }, action) => {
    switch (action.type) {
        case 'UPDATE_ROUTER_STATE':
            return action.state;
        default:
            return state;
    }
};

export { routerReducer };
