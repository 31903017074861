export const authEn = {
    pleaseRegister: 'Please register for an account to start using the Demo.',
    forgotPassword: 'Forgot Password?',
    signIn: 'Sign In',
    signInWithGoogle: 'Continue With Google',
    repeatPassword: 'Repeat Password',
    workEmail: 'Work Email',
    continue: 'Continue',
    password: 'Password',
    googleRegister: 'Please, fill in the form to start using Mobius Feature Showcase',
    errors: {
        invalidEmail: 'Invalid Email',
        invalidPassword: 'Invalid Password',
        passwordsNotMatch: 'Passwords do not match',
        required: {
            firstName: 'First Name is required',
            lastName: 'Last Name is required',
            company: 'Company is required',
            industry: 'Industry is required',
        },
    },
    placeholders: {
        email: 'Email',
        password: 'Password',
        username: 'Username',
        firstName: 'First Name',
        lastName: 'Last Name',
        company: 'Company',
        industry: 'Industry you are from*',
        solutions: 'Desired solutions',
        dataProtection: 'Data Protection Policy',
    },
    industries: {
        asset_management: 'Asset management',
        content_licensing: 'Content licensing',
        creative_platforms: 'Creative platforms',
        geospatial: 'Geospatial',
        insurance: 'Insurance',
        internet_print_service: 'Internet to print services',
        media: 'Media',
        press_broadcasting: 'Press & broadcasting',
        video_production: 'Video production',
        video_services: 'Video services',
        other: 'Other',
    },
    solutions: {
        content_moderation: 'Content moderation',
        custom_concepts: 'Custom concepts',
        facial_recognition: 'Facial recognition',
        image_tagging: 'Image tagging',
        object_detection: 'Object detection',
        video_segmentation: 'Video segmentation',
        video_tagging: 'Video tagging',
        visual_search: 'Visual search',
        other: 'Other',
    },
};
