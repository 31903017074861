export const SHARE_IMAGE_DEMO_UPLOADS = 'SHARE_IMAGE_DEMO_UPLOADS';
export const SHARE_VIDEO_UPLOADS = 'SHARE_VIDEO_UPLOADS';
export const SHARE_SINGLE_VIDEO_UPLOADS = 'SHARE_SINGLE_VIDEO_UPLOADS';
export const GET_SHARED_IMAGES_PREDICTIONS = 'GET_SHARED_IMAGES_PREDICTIONS';
export const GET_SHARED_VIDEOS_PREDICTIONS = 'GET_SHARED_VIDEOS_PREDICTIONS';
export const GET_SHARED_PREDICTIONS_SUCCESS = 'GET_SHARED_PREDICTIONS_SUCCESS';
export const SET_SHARE_POPOVER_VISIBILITY = 'SET_SHARE_POPOVER_VISIBILITY';
export const SET_SHARE_DRAWER_VISIBILITY = 'SET_SHARE_DRAWER_VISIBILITY';
export const COPY_SHARE_LINK_TO_CLIPBOARD = 'COPY_SHARE_LINK_TO_CLIPBOARD';
export const SEND_SHARE_LINK_EMAIL = 'SEND_SHARE_LINK_EMAIL';
