import {
    ADD_CURRENT_USER_UPLOAD,
    AUTH_CHECK_ERROR,
    AUTH_CHECK_START,
    AUTH_CHECK_SUCCESS,
    AUTH_LOGIN_ERROR,
    AUTH_LOGIN_START,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGOUT_SUCCESS,
    UPDATE_CONSENT,
    ONBOARD_USER_SUCCESS,
} from '../actions/actionTypes/auth';
import { OnboardingEnum } from '../../utils/constants';

const initialState = {
    authCheckStart: false,
    authCheckComplete: false,

    isAuthenticated: false,
    isLoggingIn: false,
    hasAuthFail: false,
    authFailMessage: '',
    currentUser: {},
    token: null,
};

const buildFullname = ({ username, firstName, lastName }) => {
    if (!firstName && !lastName) {
        return username;
    }
    return `${firstName || ''} ${lastName || ''}`;
};

const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case AUTH_LOGOUT_SUCCESS:
            return { ...state, isAuthenticated: false, currentUser: {} };

        case ADD_CURRENT_USER_UPLOAD:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    uploadImagesCounter: state.currentUser.uploadImagesCounter + 1,
                },
            };

        case UPDATE_CONSENT:
            console.log('UPDATE_CONSENT');
            return {
                ...state,
                currentUser: { ...state.currentUser, consentGiven: true },
            };

        case AUTH_CHECK_START:
            return { ...state, authCheckStart: true };

        case AUTH_CHECK_SUCCESS:
            return {
                ...state,
                authCheckComplete: true,
                isAuthenticated: true,
                currentUser: {
                    ...payload.user,
                    fullName: buildFullname(payload.user),
                },
            };

        case AUTH_CHECK_ERROR:
            return { ...state, authCheckComplete: true };

        case AUTH_LOGIN_START:
            return { ...state, isLoggingIn: true, hasAuthFail: false };

        case AUTH_LOGIN_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: true,
                token: payload.accessToken,
                refreshToken: payload.refreshToken,
            };

        case AUTH_LOGIN_ERROR:
            return {
                ...state,
                isLoggingIn: false,
                hasAuthFail: true,
                isAuthenticated: false,
                authFailMessage: payload,
            };

        case ONBOARD_USER_SUCCESS:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    onboarding: {
                        ...state.currentUser.onboarding,
                        [OnboardingEnum[payload.type]]: payload.step,
                    },
                },
            };

        default:
            return state;
    }
};

export { authReducer };
