import { CircularProgress } from '@material-ui/core';
import qs from 'query-string';
import React, { Suspense } from 'react';
import RouteParser from 'route-parser';
import { LoginDialog } from '../components/auth/LoginDialog';
import { NotFoundScreen } from '../components/common/EmptyScreen/NotFoundScreen';
import { Page } from '../components/common/Page/Page';
import { App } from './App';
import { PublicApp } from './PublicApp';
import {
    AUDIO_PROFANITY_DETECTION,
    AUDIO_SPEECH_TO_TEXT,
    AUDIO_TAGGING,
    CHANGE_PASSWORD_SUCCESS,
    CONCEPT,
    CONCEPTS,
    CONFIRMATION_EMAIL_SEND,
    CONFIRM_EMAIL,
    CUSTOM_OBJECT_DETECTION,
    CUSTOM_OBJECT_DETECTION_DETAILS,
    DASHBOARD,
    DEFAULT_ROUTE,
    EARTH_OBSERVATION,
    EARTH_OBSERVATION_AIRPLANE,
    EARTH_OBSERVATION_CLOUDS,
    EARTH_OBSERVATION_SHIP,
    GOOGLE_LOGIN_PAGE,
    IMAGE_DEMO,
    IMAGE_DEMO_UPLOADS,
    IMAGE_FACIAL_RECOGNITION,
    IMAGE_FACIAL_RECOGNITION_UPLOADS,
    IMAGE_LOGO_DETECTION,
    IMAGE_LOGO_DETECTION_DEFAULT,
    IMAGE_LOGO_DETECTION_UPLOADS,
    IMAGE_LOGO_DETECTION_USER,
    IMAGE_SHARE,
    INDUSTRY_I2P,
    INDUSTRY_IMAGE_ARCHIVES,
    INDUSTRY_PRESS,
    LOGO_DETECTION_ADMIN_GROUP_PAGE,
    LOGO_DETECTION_ADMIN_PAGE,
    OBJECT_SEGMENTATION,
    REGISTER_USER,
    RESET_PASSWORD,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SEND,
    routes,
    SEARCH,
    SEARCH_ADMIN,
    USER,
    USER_CREATE,
    USER_EDIT,
    VIDEO_FACIAL_RECOGNITION,
    VIDEO_FACIAL_RECOGNITION_UPLOADS,
    VIDEO_HIGHLIGHTS,
    VIDEO_HIGHLIGHTS_UPLOADS,
    VIDEO_PROFANITY_DETECTION,
    VIDEO_SHARE,
    VIDEO_SHOT_DETECTION,
    VIDEO_SHOT_DETECTION_UPLOADS,
    VIDEO_TAGGING,
    VIDEO_TAGGING_UPLOADS,
} from './routes';

const DashboardPage = React.lazy(() => import('../pages/dashboard/DashboardPage'));

const ConceptsPage = React.lazy(() => import('../pages/concept/ConceptsListPage'));

const ImageDemo = React.lazy(() => import('../pages/image/tagging/ImageDemoPage'));
const ImageDemoShare = React.lazy(() => import('../pages/image/tagging/ImageDemoShare'));
const ImageFacialRecognition = React.lazy(() =>
    import('../pages/image/facial-recognition/ImageFacialRecognitionPage')
);
const ImageFacialRecognitionUploads = React.lazy(() =>
    import('../pages/image/facial-recognition/ImageFacialRecognitionUploadsPage')
);
const ImageDemoUploads = React.lazy(() => import('../pages/image/tagging/ImageDemoUploads'));

const ChangePassword = React.lazy(() => import('../pages/public/ChangePassword'));
const ChangePasswordSuccess = React.lazy(() => import('../pages/public/ChangePasswordSuccess'));
const ConfirmationEmailSend = React.lazy(() => import('../pages/public/ConfirmationEmailSend'));
const ConfirmEmail = React.lazy(() => import('../pages/public/ConfirmEmail'));
const ForgotPasswordRequest = React.lazy(() => import('../pages/public/ForgotPasswordRequest'));
const ForgotPasswordRequestSend = React.lazy(() =>
    import('../pages/public/ForgotPasswordRequestSend')
);
const GoogleLoginPage = React.lazy(() => import('../pages/public/GoogleLoginPage'));
const RegisterUser = React.lazy(() => import('../pages/public/RegisterUser'));

const User = React.lazy(() => import('../pages/user/User'));
const UserEdit = React.lazy(() => import('../pages/user/UserEdit'));

const VideoTagging = React.lazy(() => import('../pages/video/tagging/VideoTaggingPage'));
const VideoTaggingUploads = React.lazy(() =>
    import('../pages/video/tagging/VideoTaggingUploadsPage')
);
const VideoFacialRecognition = React.lazy(() =>
    import('../pages/video/facial-recognition/VideoFacialRecognitionPage')
);
const VideoFacialRecognitionUploads = React.lazy(() =>
    import('../pages/video/facial-recognition/VideoFacialRecognitionUploadsPage')
);
const VideoShotDetection = React.lazy(() => import('../pages/video/shot/VideoShotDetectionPage'));
const VideoShotDetectionUploads = React.lazy(() =>
    import('../pages/video/shot/VideoShotDetectionUploadsPage')
);
const VideoHighlights = React.lazy(() => import('../pages/video/highlight/VideoHighlightsPage'));
const VideoHighlightsUploads = React.lazy(() =>
    import('../pages/video/highlight/VideoHighlightsUploadsPage')
);
const VideoProfanityDetection = React.lazy(() =>
    import('../pages/video/VideoProfanityDetectionPage')
);

const VideoShare = React.lazy(() => import('../pages/video/VideoShare'));

const SearchAdmin = React.lazy(() => import('../pages/search/SearchAdminPage'));
const SearchPage = React.lazy(() => import('../pages/search/SearchPage'));
const EarthObservation = React.lazy(() => import('../pages/earth-observation'));
const CloudPage = React.lazy(() => import('../pages/earth-observation/clouds/CloudEOPage'));
const ShipPage = React.lazy(() => import('../pages/earth-observation/ship/ShipEOPage'));
const AirplanePage = React.lazy(() => import('../pages/earth-observation/airplane/AirplaneEOPage'));

const CustomObjectDetectionPage = React.lazy(() => import('../pages/detection/custom/CustomPage'));
const CustomObjectDetailsPage = React.lazy(() => import('../pages/detection/custom/DetailsPage'));

const LogoDetectionAdminPage = React.lazy(() =>
    import('../pages/image/logo-detection/LogoDetectionAdminPage')
);
const LogoDetectionAdminGroupPage = React.lazy(() =>
    import('../pages/image/logo-detection/LogoDetectionAdminGroupPage')
);
const ObjectSegmentationPage = React.lazy(() =>
    import('../pages/segmentation/ObjectSegmentationPage')
);

const AudioTaggingPage = React.lazy(() => import('../pages/audio/tagging/AudioTaggingPage'));
const AudioSpeechToTextPage = React.lazy(() =>
    import('../pages/audio/speech-to-text/AudioSpeechToTextPage')
);
const AudioProfanityDetectionPage = React.lazy(() =>
    import('../pages/audio/profanity/AudioProfanityDetectionPage')
);
const ConceptPageV2 = React.lazy(() => import('../pages/concept/ConceptPage'));

const IndustryImageAchivesPage = React.lazy(() =>
    import('../pages/industry/IndustryImageAchivesPage')
);
const IndustryInternet2PrintPage = React.lazy(() =>
    import('../pages/industry/IndustryInternet2PrintPage')
);
const IndustryPressPage = React.lazy(() => import('../pages/industry/IndustryPressPage'));

const ImageLogoDetectionPage = React.lazy(() =>
    import('../pages/image/logo-detection/ImageLogoDetectionPage')
);
const ImageLogoDetectionUploadsPage = React.lazy(() =>
    import('../pages/image/logo-detection/ImageLogoDetectionUploadsPage')
);

const ImageLogoDetectionDefaultPage = React.lazy(() =>
    import('../pages/image/logo-detection/ImageLogoDetectionDefaultPage')
);

const ImageLogoDetectionUserPage = React.lazy(() =>
    import('../pages/image/logo-detection/ImageLogoDetectionUserPage')
);

const routesComponentMap = {
    [CONCEPTS.pathname]: ConceptsPage,
    [DASHBOARD.pathname]: DashboardPage,
    [IMAGE_DEMO.pathname]: ImageDemo,
    [IMAGE_DEMO_UPLOADS.pathname]: ImageDemoUploads,
    [USER.pathname]: User,
    [USER_EDIT.pathname]: UserEdit,
    [USER_CREATE.pathname]: UserEdit,
    [IMAGE_SHARE.pathname]: ImageDemoShare,
    [REGISTER_USER.pathname]: RegisterUser,
    [RESET_PASSWORD.pathname]: ChangePassword,
    [CONFIRM_EMAIL.pathname]: ConfirmEmail,
    [CONFIRMATION_EMAIL_SEND.pathname]: ConfirmationEmailSend,
    [RESET_PASSWORD_SEND.pathname]: ForgotPasswordRequestSend,
    [RESET_PASSWORD_REQUEST.pathname]: ForgotPasswordRequest,
    [CHANGE_PASSWORD_SUCCESS.pathname]: ChangePasswordSuccess,
    [VIDEO_TAGGING.pathname]: VideoTagging,
    [VIDEO_TAGGING_UPLOADS.pathname]: VideoTaggingUploads,
    [IMAGE_FACIAL_RECOGNITION.pathname]: ImageFacialRecognition,
    [IMAGE_FACIAL_RECOGNITION_UPLOADS.pathname]: ImageFacialRecognitionUploads,
    [VIDEO_FACIAL_RECOGNITION.pathname]: VideoFacialRecognition,
    [VIDEO_FACIAL_RECOGNITION_UPLOADS.pathname]: VideoFacialRecognitionUploads,
    [VIDEO_SHOT_DETECTION.pathname]: VideoShotDetection,
    [VIDEO_SHOT_DETECTION_UPLOADS.pathname]: VideoShotDetectionUploads,
    [VIDEO_HIGHLIGHTS.pathname]: VideoHighlights,
    [VIDEO_HIGHLIGHTS_UPLOADS.pathname]: VideoHighlightsUploads,
    [VIDEO_PROFANITY_DETECTION.pathname]: VideoProfanityDetection,
    [VIDEO_SHARE.pathname]: VideoShare,
    [SEARCH.pathname]: SearchPage,
    [SEARCH_ADMIN.pathname]: SearchAdmin,
    [CUSTOM_OBJECT_DETECTION.pathname]: CustomObjectDetectionPage,
    [LOGO_DETECTION_ADMIN_PAGE.pathname]: LogoDetectionAdminPage,
    [LOGO_DETECTION_ADMIN_GROUP_PAGE.pathname]: LogoDetectionAdminGroupPage,
    [OBJECT_SEGMENTATION.pathname]: ObjectSegmentationPage,
    [CUSTOM_OBJECT_DETECTION_DETAILS.pathname]: CustomObjectDetailsPage,
    [EARTH_OBSERVATION.pathname]: EarthObservation,
    [EARTH_OBSERVATION_CLOUDS.pathname]: CloudPage,
    [EARTH_OBSERVATION_SHIP.pathname]: ShipPage,
    [EARTH_OBSERVATION_AIRPLANE.pathname]: AirplanePage,
    [AUDIO_TAGGING.pathname]: AudioTaggingPage,
    [AUDIO_SPEECH_TO_TEXT.pathname]: AudioSpeechToTextPage,
    [AUDIO_PROFANITY_DETECTION.pathname]: AudioProfanityDetectionPage,
    [GOOGLE_LOGIN_PAGE.pathname]: GoogleLoginPage,
    [CONCEPT.pathname]: ConceptPageV2,
    [INDUSTRY_IMAGE_ARCHIVES.pathname]: IndustryImageAchivesPage,
    [INDUSTRY_I2P.pathname]: IndustryInternet2PrintPage,
    [INDUSTRY_PRESS.pathname]: IndustryPressPage,
    [IMAGE_LOGO_DETECTION.pathname]: ImageLogoDetectionPage,
    [IMAGE_LOGO_DETECTION_UPLOADS.pathname]: ImageLogoDetectionUploadsPage,
    [IMAGE_LOGO_DETECTION_DEFAULT.pathname]: ImageLogoDetectionDefaultPage,
    [IMAGE_LOGO_DETECTION_USER.pathname]: ImageLogoDetectionUserPage,
};

// UTILS
const matchRoute = pathname => {
    return routes.find(routeConfig => {
        return new RouteParser(routeConfig.pathname).match(pathname);
    });
};

const renderPublicRoute = (appConfig, pathname, search, history) => {
    let routeNode = null;
    let routeParams = null;
    let routeQuery = {};

    const routeItem = matchRoute(pathname);
    if (
        routeItem &&
        routesComponentMap[routeItem.pathname] &&
        routeItem.public &&
        routeItem.getVisibility(appConfig)
    ) {
        const comp = routesComponentMap[routeItem.pathname];
        routeParams = new RouteParser(routeItem.pathname).match(pathname);
        routeQuery = qs.parse(search);
        routeNode = React.createElement(comp, { routeParams, routeQuery });
    } else {
        routeNode = <LoginDialog history={history} />;
    }

    return (
        <PublicApp {...{ routeParams, routeQuery }}>
            <Suspense fallback={<CircularProgress color="secondary" />}>{routeNode}</Suspense>
        </PublicApp>
    );
};

const renderPrivateRoute = (appConfig, pathname, search) => {
    let routeNode = null;
    let routeParams = null;
    let routeQuery = {};
    const routeItem = matchRoute(pathname);

    if (
        routeItem &&
        routesComponentMap[routeItem.pathname] &&
        !routeItem.public &&
        routeItem.getVisibility(appConfig)
    ) {
        const comp = routesComponentMap[routeItem.pathname];
        routeParams = new RouteParser(routeItem.pathname).match(pathname);
        routeQuery = qs.parse(search);
        routeNode = React.createElement(comp, { routeParams, routeQuery });
    } else {
        routeParams = { pathname, search };
        routeNode = (
            <Page style={{ padding: 24 }}>
                <NotFoundScreen />
            </Page>
        );
    }

    return (
        <App {...{ routeParams, routeQuery }}>
            <Suspense fallback={<CircularProgress color="secondary" />}>{routeNode}</Suspense>
        </App>
    );
};

const START_PAGE_KEY = 'startPage';

export const renderRoute = (appConfig, hasAuthCheck, isLogged, routeProps) => {
    const {
        history,
        location: { pathname, search },
    } = routeProps;

    if (isLogged && pathname === DEFAULT_ROUTE && appConfig.baseUrl !== DEFAULT_ROUTE) {
        history.replace(localStorage.getItem(START_PAGE_KEY) || appConfig.baseUrl);
        return null;
    }
    if (!hasAuthCheck && !isLogged && pathname !== DEFAULT_ROUTE) {
        const routeItem = matchRoute(pathname);
        if (routeItem && !routeItem.public) {
            localStorage.setItem(START_PAGE_KEY, pathname);
        }
    }

    if (!hasAuthCheck || (isLogged && !appConfig.initialized)) {
        return (
            <App>
                <CircularProgress color="secondary" />
            </App>
        );
    }

    if (!isLogged) {
        return renderPublicRoute(appConfig, pathname, search, history);
    }

    localStorage.removeItem(START_PAGE_KEY);
    return renderPrivateRoute(appConfig, pathname, search);
};
