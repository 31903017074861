import { CREATE_CONCEPT_REQ } from '../redux/actions/actionTypes/concepts';
import {
    UPLOAD_IMAGE_DEMO_IMAGES,
    UPLOAD_IMAGE_REQUEST,
} from '../redux/actions/actionTypes/imageDemo';
import { UPLOAD_IMAGES_REQ } from '../redux/actions/actionTypes/upload';
import { folderTypes } from './constants';

export const trainPageFoldersOrdering = {
    [folderTypes.positive]: 0,
    [folderTypes.negative]: 1,
    [folderTypes.validation]: 2,
};

export const FAILED_IMAGES_REUPLOADS = 1;

export const INFINITE_GRID_COLUMNS = 6;
export const INFINITE_GRID_OVERSCAN_ROWS = 3;

export const USER_ITEMS_PER_PAGE = 20;

export const DEMO_DEFAULT_FOLDER = 'environment';
export const FACE_RECOGNITION_DEFAULT_FOLDER = 'emotions';
export const LOGO_DETECTION_DEFAULT_FOLDER = 'logos';

export const ACTIONS_REQUIRE_CONSENT = [
    UPLOAD_IMAGE_REQUEST,
    UPLOAD_IMAGE_DEMO_IMAGES,
    UPLOAD_IMAGES_REQ,
    CREATE_CONCEPT_REQ,
];

export const PREDICTION_COLOR_THRESHOLDS = [
    {
        threshold: 0.9,
        color: '#0DFDB7',
    },
    {
        threshold: 0.7,
        color: '#12BD8C',
    },
    {
        threshold: 0.5,
        color: '#DFB666',
    },
    {
        threshold: 0,
        color: 'white',
    },
];

export const SUPPORT_EMAIL = 'support@mobiuslabs.com';
export const DEMO_EMAIL = 'demo@mobiuslabs.com';

export const UPLOADS_PAGE_SHOWN_ITEMS = 50;
export const RESEND_CONFIRMATION_TIMEOUT = 30;

export const SAGA_WORKERS = 5;

// in Megabytes
export const MAX_VIDEO_UPLOAD_SIZE = process.env.MAX_VIDEO_UPLOAD_SIZE || 500;
