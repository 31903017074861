import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Divider, Button, Typography, makeStyles } from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons';
import { actions } from '../../redux/actions';
import { SUPPORT_EMAIL } from '../../utils/config';

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        maxWidth: 500,
        padding: theme.spacing(3, 4),
    },

    title: {
        display: 'flex',
        alignItems: 'center',
        '&>h4': {
            marginLeft: theme.spacing(2),
        },
    },

    bodyContainer: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(0),
    },

    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(6),
    },
    buttonRestart: {
        marginTop: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(4, 0),
    },
    buttonContact: {
        marginTop: theme.spacing(2),
    },
    buttonContinue: {
        width: '100%',
    },
}));

type ConclusionDialogProps = {
    onClose: (action: string) => void;
    action: string;
    startTutorialRequest: (params: boolean) => void;
    onboardUser: (params: { type: string; step: string }) => void;
};

const ConclusionDialogBase = (props: ConclusionDialogProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { onClose, action, startTutorialRequest, onboardUser } = props;

    const handleClose = async () => {
        onboardUser({ type: 'image_tagging', step: 'done' });
        onClose(action);
    };

    const handleRestart = () => {
        startTutorialRequest(true);
        onClose(action);
    };

    const handleContact = () => {
        window.open(SUPPORT_EMAIL, '_blank');
    };

    return (
        <div data-test="demo-tutorial-dialog" className={classes.dialogContainer}>
            <div className={classes.title}>
                <HelpOutlineOutlined />
                <Typography variant="h4">{t('imageDemo.tutorial.conclusion.title')}</Typography>
            </div>
            <div className={classes.bodyContainer}>
                <Typography>{t('imageDemo.tutorial.conclusion.desc1')}</Typography>
                <Button
                    className={classes.buttonRestart}
                    variant="outlined"
                    onClick={handleRestart}
                >
                    {t('imageDemo.tutorial.conclusion.restart')}
                </Button>
                <Divider className={classes.divider} />
                <Typography>{t('imageDemo.tutorial.conclusion.desc2')}</Typography>
                <Button
                    className={classes.buttonRestart}
                    variant="outlined"
                    onClick={handleContact}
                >
                    {t('imageDemo.tutorial.conclusion.contact')}
                </Button>
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    className={classes.buttonContinue}
                    variant="contained"
                    onClick={handleClose}
                >
                    {t('imageDemo.tutorial.conclusion.finish')}
                </Button>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onboardUser: (params: { type: string; step: string }) =>
            dispatch(actions.onboardUser(params)),
        startTutorialRequest: (params: boolean) =>
            dispatch(actions.imageTaggingActions.startTutorialRequest(params)),
    };
};

export const ConclusionDialog = connect(null, mapDispatchToProps)(ConclusionDialogBase);
