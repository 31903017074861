import { DIALOG_CLOSE, DIALOG_SHOW } from '../actions/actionTypes/dialog';

const initialState = {
    data: null
};

const dialogReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case DIALOG_SHOW:
            return { ...state, data: payload };

        case DIALOG_CLOSE:
            return { ...state, data: null };

        default:
            return state;
    }
};

export { dialogReducer };
