export const isPageAnimating = state => {
    return state.app.transitionState === 'enter';
};

export const isDrawerOpen = state => {
    return state.app.drawerOpen;
};

export const selectors = {
    isPageAnimating,
    isDrawerOpen,
};
