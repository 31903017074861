import React from 'react';
import { useLocation } from 'react-router-dom';
import { isMobius } from './env';
import { loadJSAsync } from './utils';

const hubspotJSId = process.env.REACT_APP_HUBSPOT_JS_ID || '';

export const initHubspot = () => {
    if (isMobius() && hubspotJSId) {
        loadJSAsync(`//js.hs-scripts.com/${hubspotJSId}.js`);
    }
};

export const trackHubspotPageView = (pathname: string) => {
    if (isMobius() && hubspotJSId) {
        const _hsq = ((window as any)._hsq = (window as any)._hsq || []);
        _hsq.push(['setPath', pathname]);
        _hsq.push(['trackPageView']);
    }
};

export const useHubspotPageView = () => {
    const location = useLocation();
    const [prevPathname, setPrevPathname] = React.useState<string | undefined>(undefined);
    React.useEffect(() => {
        // skip first page load, because hubspot sends page view on first load by itseld
        if (prevPathname !== undefined) {
            trackHubspotPageView(location.pathname);
        }
        setPrevPathname(location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
};

export const setHubspotUserEmail = (email: string) => {
    if (isMobius() && hubspotJSId) {
        console.log('setHubspotUserEmail', email);
        const _hsq = ((window as any)._hsq = (window as any)._hsq || []);
        _hsq.push(['identify', email ? { email } : undefined]);
    }
};
