import { connect } from 'react-redux';
import i18n from '../../i18n';
import { sdkTaskStatus } from '../../utils/constants';
import { BasePopover } from './BasePopover';

const buildMessage = task => {
    if (task?.status === sdkTaskStatus.inQueue) {
        return i18n.t('search.popover.searchIndex.starting');
    }
    if (task?.status === sdkTaskStatus.ongoing) {
        return i18n.t('search.popover.searchIndex.training', { progress: task.progress });
    }
    return null;
};
const mapStateToProps = state => {
    const task = state.search.trainTask;
    const message = buildMessage(task);
    return {
        message: buildMessage(task),
        open: Boolean(message),
    };
};

const TrainSearchIndexPopover = connect(mapStateToProps)(BasePopover);

export { TrainSearchIndexPopover };
