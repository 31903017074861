import { SearchState } from '../../utils/commonTypes';
import {
    RETRAIN_SEARCH_INDEX_STATUS,
    SEARCH_REQUEST,
    SEARCH_REQUEST_FAILED,
    SEARCH_REQUEST_SUCCESS,
    SET_SEARCH_QUERY,
    UPLOAD_SEARCH_IMAGES_COUNT,
    UPLOAD_SEARCH_IMAGE_DONE,
} from '../actions/actionTypes/search';

const initialState: SearchState = {
    trainTask: null,
    upload: {
        uploaded: 0,
        failed: 0,
        total: 0,
    },
    result: {
        loading: false,
        loaded: true,
        error: null,
        images: [],
    },
    query: '',
};

const searchReducer = (state: SearchState = initialState, action: any): SearchState => {
    switch (action.type) {
        case RETRAIN_SEARCH_INDEX_STATUS:
            return { ...state, trainTask: action.payload };
        case UPLOAD_SEARCH_IMAGES_COUNT:
            const { count } = action.payload;
            return {
                ...state,
                upload: {
                    ...initialState.upload,
                    total: state.upload.total + count,
                },
            };
        case UPLOAD_SEARCH_IMAGE_DONE:
            const { failed } = action.payload;
            const done = state.upload.failed + state.upload.uploaded === state.upload.total;
            return {
                ...state,
                upload: {
                    ...initialState.upload,
                    uploaded: state.upload.uploaded + (failed ? 0 : 1),
                    failed: state.upload.failed + (failed ? 1 : 0),
                    total: done ? 0 : state.upload.total,
                },
            };
        case SET_SEARCH_QUERY:
            const { query } = action.payload;
            return {
                ...state,
                query,
                result: query
                    ? state.result
                    : {
                          loading: false,
                          loaded: true,
                          error: null,
                          images: [],
                      },
            };

        case SEARCH_REQUEST:
            return {
                ...state,
                result: {
                    loading: true,
                    loaded: false,
                    error: null,
                    images: [],
                },
            };

        case SEARCH_REQUEST_SUCCESS:
            const { images } = action.payload;
            return {
                ...state,
                result: {
                    loading: false,
                    loaded: true,
                    error: null,
                    images: images,
                },
            };

        case SEARCH_REQUEST_FAILED:
            const { error } = action.payload;
            return {
                ...state,
                result: {
                    loading: false,
                    loaded: true,
                    error: error,
                    images: [],
                },
            };

        default:
            return state;
    }
};

export { searchReducer };
