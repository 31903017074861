import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { isNumber } from 'lodash';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { actions } from '../../redux/actions';
import { selectors } from '../../redux/selectors';
import { MAX_VIDEO_UPLOAD_SIZE } from '../../utils/config';
import { matchMobile } from '../../utils/markupHelpers';

const useStyles = makeStyles(theme => ({
    root: {
        width: 600,

        [matchMobile(theme)]: {
            width: 'auto',
        },
    },
    uploadDropzone: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '1.5px dashed #6e6e6e',
        borderRadius: theme.shape.borderRadius,
        '& p': {
            textAlign: 'center',
        },
        padding: theme.spacing(2, 0, 4, 0),
    },
    preview: {
        flex: '20%',
        flexGrow: '0',
        width: '96px',
        height: '96px',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    uploadIcon: {
        color: '#d6d6d6',
        fontSize: '100px',
    },
}));

const UploadVideoFormBase = ({
    onClose,
    user,
    action,
    showDialog,
    actionParams: { hasUploads },
}) => {
    const classes = useStyles();
    const { t } = useTranslation('video');

    const onDropAccepted = React.useCallback(
        acceptedFiles => {
            onClose(action(acceptedFiles));
        },
        [action, onClose]
    );
    const onDropRejected = React.useCallback(
        rejections => {
            const { code } = rejections[0].errors[0];
            if (code === 'file-too-large') {
                showDialog(
                    'warning',
                    t('uploadPopup.messages.fileTooLarge', {
                        maxSize: MAX_VIDEO_UPLOAD_SIZE,
                    })
                );
            } else if (code === 'file-invalid-type') {
                showDialog('error', t('uploadPopup.messages.invalidType'));
            } else {
                showDialog('error', t('uploadPopup.messages.error'));
            }
        },
        [showDialog, t]
    );

    const disabled =
        isNumber(user.uploadImagesMax) && user.uploadImagesCounter >= user.uploadImagesMax;
    const { getRootProps, getInputProps } = useDropzone({
        accept: ['video/mp4', 'video/avi', '.mkv'],
        preventDropOnDocument: false,
        onDropAccepted,
        onDropRejected,
        disabled,
        multiple: false,
        maxSize: MAX_VIDEO_UPLOAD_SIZE * 1024 * 1024,
    });

    const count = user.uploadImagesMax ? user.uploadImagesMax - user.uploadImagesCounter : null;
    const countMessage = count ? t('uploadPopup.countMessage', { count }) : '';
    const rootClass = [classes.uploadDropzone, disabled ? ' disabled' : ''].join(' ');

    return (
        <div className={classes.root}>
            <div {...getRootProps({ className: rootClass })}>
                <CloudUploadIcon className={classes.uploadIcon} />
                <input {...getInputProps()} />
                <Box mt={2} mb={3}>
                    <Typography variant="body2">
                        {disabled
                            ? t('uploadPopup.messages.quotaReached', {
                                  uploadImagesMax: user.uploadImagesMax,
                              })
                            : t('uploadPopup.dragAndDrop', {
                                  countMessage,
                              })}
                    </Typography>
                </Box>

                <Button variant="contained" disabled={disabled} color="secondary">
                    {t('uploadPopup.button')}
                </Button>
            </div>
        </div>
    );
};

UploadVideoFormBase.propTypes = {
    onClose: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    showDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        user: selectors.getCurrentUser(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showDialog: (type, message) =>
            dispatch(
                actions.showDialog({
                    type,
                    action: {},
                    actionParams: { message },
                })
            ),
    };
};

export const UploadVideoForm = connect(mapStateToProps, mapDispatchToProps)(UploadVideoFormBase);
