import { connect } from 'react-redux';
import { selectors } from '../../redux';
import { BasePopover } from './BasePopover';

const mapStateToProps = state => {
    const currentTask = selectors.getCurrentTask()(state);
    const taskId = currentTask && currentTask.taskId ? `- ${currentTask.taskId}` : '';
    const message = currentTask && currentTask.status && `Task: ${currentTask.status} ${taskId}`;
    const open = Boolean(currentTask);
    return {
        message,
        open
    };
};

const TaskPopover = connect(mapStateToProps)(BasePopover);

export { TaskPopover };
