import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const ImageUploadLinearProgress = withStyles(theme => ({
    root: {
        height: 8,
        backgroundColor: theme.palette.grey['800'],
        borderRadius: 5,
    },
    bar: {
        borderRadius: 5,
        backgroundColor: theme.palette.secondary.main,
    },
}))(LinearProgress);
