import * as materialColors from '@material-ui/core/colors';

const colors = {
    GREY: '#8C8C8C',

    DARK_LIGHT: '#222326',
    DARK_GRADE_0: '#181818',
    DARK_GRADE_1: '#212121',
    DARK_GRADE_2: '#3E3E3E',

    GREEN: '#12BD8C',
    GREEN_SUPER: '#0DFDB7',

    VIOLET: '#724FFF',

    YELLOW_MEDIUM: '#DFB666',
    WHITE: '#F0F0F0',
    RED: '#C63434',
    RED_DARK: '#B00020',
};

const colorIndex = 400;
const colorIndex1 = 700;

const EMOTION_COLORS = {
    disappointed: materialColors.amber[colorIndex],
    disgusted: materialColors.blue[colorIndex],
    crying: materialColors.blueGrey[colorIndex],
    blurry: materialColors.brown[colorIndex],
    serious: materialColors.purple[colorIndex],
    undecided: materialColors.cyan[colorIndex],
    startled: materialColors.deepOrange[colorIndex],
    'slightly disappointed': materialColors.deepPurple[colorIndex],
    annoyed: materialColors.green[colorIndex],
    shocked: materialColors.indigo[colorIndex],
    ecstatic: materialColors.lightBlue[colorIndex],
    doubtful: materialColors.lightGreen[colorIndex],
    scared: materialColors.lime[colorIndex],
    affection: materialColors.orange[colorIndex],
    stern: materialColors.pink[colorIndex],
    'slightly mad': materialColors.purple[colorIndex],
    joyful: materialColors.red[colorIndex],
    'blank face': materialColors.teal[colorIndex],
    'in pain': materialColors.yellow[colorIndex],

    surprise: materialColors.amber[colorIndex1],
    sad: materialColors.blue[colorIndex1],
    thinking: materialColors.blueGrey[colorIndex1],
    neutral: materialColors.grey[colorIndex], // <-- specific
    focused: materialColors.cyan[colorIndex1],
    mixed: materialColors.deepPurple[colorIndex1],
    'slightly annoyed': materialColors.lime[colorIndex1],
    angry: materialColors.grey[colorIndex1],
    contemplation: materialColors.indigo[colorIndex1],
    surprised: materialColors.lightBlue[colorIndex1],
    'slightly angry': materialColors.lightGreen[colorIndex1],
    happy: materialColors.green[colorIndex1], // <-- specific
    confused: materialColors.orange[colorIndex1],
    silly: materialColors.pink[colorIndex1],
    concentrated: materialColors.purple[colorIndex1],
    reflecting: materialColors.red[colorIndex],
    contentment: materialColors.teal[colorIndex],
    fearful: materialColors.yellow[colorIndex],
};

const LABELING_COLORS = Object.values(EMOTION_COLORS).reverse();

export { colors, EMOTION_COLORS, LABELING_COLORS };
