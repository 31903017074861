import { useState, useEffect } from 'react';
import { getBase64Image } from '../api';
import { logger } from '../utils/logging';

const CDN_URL = process.env.REACT_APP_CDN_URL || '';

export function useProtectedImage(src: string) {
    const [state, setState] = useState({
        blob: null as any,
        loaded: false,
        error: null as null | Error,
    });

    useEffect(() => {
        async function makeRequest() {
            try {
                const result = await getBase64Image(src);
                setState({
                    blob: result,
                    loaded: true,
                    error: null,
                });
            } catch (e) {
                setState({
                    blob: new Blob(),
                    loaded: true,
                    error: e as Error,
                });
                logger.info(`Image request has failed: ${e}`);
            }
        }

        if (!src || src === CDN_URL) {
            return setState({
                blob: null,
                loaded: true,
                error: new Error('No image source provided'),
            });
        }

        makeRequest();
    }, [src]);

    return state;
}
