import hash from 'object-hash';

const resourceHashId = ({ name, params = null, query = null }) => {
    return hash({ name, params, query });
};

export const getCurrentTask = () => state => {
    return state.tasks.currentTask;
};
export const getLastTaskResult = () => state => {
    return state.tasks.taskResult;
};

export const resourceSelector = (resourceName, params, query) => state => {
    if (!state.entities[resourceName]) return null;
    return state.entities[resourceName][resourceHashId({ name: resourceName, params, query })];
};

export const selectors = {
    getCurrentTask,
    getLastTaskResult,
    resourceSelector
};
