import { all, call, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import {
    createImagesShare,
    createVideosShare,
    getSharedImageDemoPredictions,
    getSharedVideosPredictions,
    sendShareLinkEmail,
} from '../../api';
import { IMAGE_SHARE, VIDEO_SHARE } from '../../routes/routes';
import { UPLOADS_PATH } from '../../utils/constants';
import { logger } from '../../utils/logging';
import {
    COPY_SHARE_LINK_TO_CLIPBOARD,
    GET_SHARED_IMAGES_PREDICTIONS,
    GET_SHARED_VIDEOS_PREDICTIONS,
    SEND_SHARE_LINK_EMAIL,
    SHARE_IMAGE_DEMO_UPLOADS,
    SHARE_SINGLE_VIDEO_UPLOADS,
    SHARE_VIDEO_UPLOADS,
} from '../actions/actionTypes/share';
import { showDialog } from '../actions/dialog';
import {
    copyShareLinkToClipboard,
    getSharePredictionsSuccess,
    setShareDrawerVisibility,
    setSharePopoverVisibility,
} from '../actions/share';

function* watchShareImageDemoPredictions() {
    while (true) {
        const action = yield take(SHARE_IMAGE_DEMO_UPLOADS);
        const results = yield call(createImagesShare, action.payload.predictionType);
        const link = `${window.location.origin}${IMAGE_SHARE.pathname.replace(
            ':shareId',
            results.shareId
        )}`;
        if (navigator.share) {
            console.info('Native share');
            navigator
                .share({
                    title: 'Mobius Labs',
                    url: link,
                })
                .catch(e => {
                    console.error('Native share failed');
                    console.error(e);
                });
        } else {
            yield put(setShareDrawerVisibility(true));
            yield put(copyShareLinkToClipboard(link));
        }
    }
}

function* watchShareVideosUploads() {
    while (true) {
        const action = yield take(SHARE_VIDEO_UPLOADS);
        const results = yield call(createVideosShare, {
            predictionType: action.payload.predictionType,
        });
        const link = `${window.location.origin}${VIDEO_SHARE.pathname.replace(
            ':shareId',
            results.shareId
        )}`;
        yield put(setShareDrawerVisibility(true));
        yield put(copyShareLinkToClipboard(link));
    }
}

function* watchShareSingleVideosUploads() {
    while (true) {
        const { payload } = yield take(SHARE_SINGLE_VIDEO_UPLOADS);
        const results = yield call(createVideosShare, payload);
        const link = `${window.location.origin}${VIDEO_SHARE.pathname.replace(
            ':shareId',
            results.shareId
        )}`;
        yield put(setShareDrawerVisibility(true));
        yield put(copyShareLinkToClipboard(link));
    }
}

function* watchGetSharedImagesPredictions() {
    while (true) {
        const action = yield take(GET_SHARED_IMAGES_PREDICTIONS);
        try {
            const predictions = yield call(getSharedImageDemoPredictions, action.payload.shareId);
            const updatedPredictions = predictions.map(prediction => ({
                ...prediction,
                src: `${UPLOADS_PATH}/${prediction.src}`,
            }));
            yield put(getSharePredictionsSuccess(updatedPredictions));
        } catch (e) {
            console.warn(e);
        }
    }
}

function* watchGetSharedVideosPredictions() {
    while (true) {
        const action = yield take(GET_SHARED_VIDEOS_PREDICTIONS);
        const predictions = yield call(getSharedVideosPredictions, action.payload.shareId);
        try {
            yield put(
                getSharePredictionsSuccess(
                    predictions.map(video => ({
                        ...video,
                        path: `${UPLOADS_PATH}/${video.path}`,
                    }))
                )
            );
        } catch (e) {
            console.warn(e);
        }
    }
}

function* handleCopyLinkToClipboard(action) {
    const { link } = action.payload;
    try {
        navigator.clipboard.writeText(link);
    } catch (e) {
        console.warn('Copying link to clipboard is unsupported');
    }
    yield put(setSharePopoverVisibility(true, link));
    yield delay(3000);
    yield put(setSharePopoverVisibility(false));
}

function* watchCopyLinkToClipboard() {
    yield takeLatest(COPY_SHARE_LINK_TO_CLIPBOARD, handleCopyLinkToClipboard);
}

function* watchSendShareLinkEmail() {
    while (true) {
        const { payload } = yield take(SEND_SHARE_LINK_EMAIL);
        const link = yield select(state => state.share.latestShareLink);
        try {
            yield call(sendShareLinkEmail, payload.email, link);
            yield put(
                showDialog({
                    type: 'success',
                    action: {},
                    actionParams: { message: 'The e-mail has been successfully sent!' },
                })
            );
        } catch (e) {
            yield put(
                showDialog({
                    type: 'error',
                    action: {},
                    actionParams: { message: 'Failed to send the e-mail. Please try again.' },
                })
            );
            logger.error(`Sending email failed ${e}`);
        }
    }
}

export const sagas = () =>
    all([
        watchShareImageDemoPredictions(),
        watchGetSharedImagesPredictions(),
        watchGetSharedVideosPredictions(),
        watchCopyLinkToClipboard(),
        watchSendShareLinkEmail(),
        watchShareVideosUploads(),
        watchShareSingleVideosUploads(),
    ]);
