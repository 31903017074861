import { channel } from 'redux-saga';
import { all, call, delay, fork, put, select, take } from 'redux-saga/effects';
import { getTaskStatus, removeSearchImages, searchImages, uploadSearchImage } from '../../api';
import { trainSearch } from '../../api/index';
import { SAGA_WORKERS } from '../../utils/config';
import { dialogTypes, sdkTaskStatus, SEARCH_PATH } from '../../utils/constants';
import { logger } from '../../utils/logging';
import { actions } from '../actions';
import {
    DELETE_SEARCH_IMAGES_CONFIRMED,
    DELETE_SEARCH_IMAGES_REQUEST,
    RETRAIN_SEARCH_INDEX_REQUEST,
    SEARCH_REQUEST,
    SET_SEARCH_QUERY,
    UPLOAD_SEARCH_IMAGES,
    UPLOAD_SEARCH_IMAGES_REQUEST,
} from '../actions/actionTypes/search';
import { showDialog } from '../actions/dialog';
import {
    retrainSearchIndexStatus,
    searchRequest,
    searchRequestFailed,
    searchRequestSuccess,
    updateUploadSearchImageDone,
    updateUploadSearchImagesCount,
} from '../actions/search';

function* handleUploadRequest({ dispatch }, chan) {
    while (true) {
        const params = yield take(chan);
        const { file } = params;
        try {
            yield call(uploadSearchImage, { data: file });
            yield put(updateUploadSearchImageDone());
        } catch (e) {
            console.error(e);
            yield put(updateUploadSearchImageDone(true));
        }
    }
}

function* watchUploadSearchImages(params) {
    const chan = yield call(channel);

    const workersNum = SAGA_WORKERS;
    for (let i = 0; i < workersNum; i++) {
        yield fork(handleUploadRequest, params, chan);
    }

    while (true) {
        const { payload } = yield take(UPLOAD_SEARCH_IMAGES);
        yield put(updateUploadSearchImagesCount(payload.images.length));
        // eslint-disable-next-line no-unused-vars
        for (const [_, file] of payload.images.entries()) {
            yield put(chan, { file });
        }
    }
}

function* watchUploadSearchImagesRequest() {
    while (true) {
        yield take(UPLOAD_SEARCH_IMAGES_REQUEST);
        yield put(showDialog({ type: dialogTypes.demoUpload, action: actions.uploadSearchImages }));
    }
}

function* retrainSearchIndex() {
    try {
        let result = yield call(trainSearch);
        yield put(retrainSearchIndexStatus(result));
        const taskId = result.taskId;
        while (result.status === sdkTaskStatus.inQueue || result.status === sdkTaskStatus.ongoing) {
            yield delay(1000);
            result = yield call(getTaskStatus, taskId);
            yield put(retrainSearchIndexStatus(result));
        }
        if (result.status === sdkTaskStatus.error) {
            throw Error(result);
        }
        yield put(searchRequest());
        yield put(
            showDialog({
                type: dialogTypes.success,
                action: {},
                actionParams: {
                    message: `Training search index is completed`,
                },
            })
        );
    } catch (e) {
        logger.error(`Training search index failed ${e}`);
        yield put(
            showDialog({
                type: dialogTypes.error,
                action: {},
                actionParams: {
                    message: `Training search index has failed`,
                },
            })
        );
    }
}

function* watchRetrainSearchIndexRequest() {
    while (true) {
        yield take(RETRAIN_SEARCH_INDEX_REQUEST);
        yield fork(retrainSearchIndex);
    }
}

function* watchDeleteSearchImagesRequest() {
    while (true) {
        const { payload } = yield take(DELETE_SEARCH_IMAGES_REQUEST);
        yield put(
            showDialog({
                type: dialogTypes.deleteSearchImagesConfirmation,
                action: { type: DELETE_SEARCH_IMAGES_CONFIRMED },
                actionParams: { images: payload.images },
            })
        );
    }
}

function* watchDeleteSearchImagesConfirmed(action) {
    while (true) {
        const { params } = yield take(DELETE_SEARCH_IMAGES_CONFIRMED);
        try {
            yield call(removeSearchImages, params.images);
            yield put(searchRequest());
        } catch (e) {
            yield put(
                showDialog({
                    type: dialogTypes.error,
                    action: {},
                    actionParams: {
                        message: 'Removing video has failed',
                    },
                })
            );
        }
    }
}

function* watchSetQuery() {
    while (true) {
        yield take(SET_SEARCH_QUERY);
        yield put(searchRequest());
    }
}

function* watchSearchRequest() {
    const mapServerResponse = response =>
        response.results.map(image => ({
            id: image.imageId,
            phase: 'search',
            response: {
                filename: image.imageId,
                path: `${SEARCH_PATH}/${image.imageId}`,
            },
        }));

    while (true) {
        yield take(SEARCH_REQUEST);
        const query = yield select(state => state.search.query);
        if (!query) {
            continue;
        }
        try {
            const response = yield call(searchImages, query.trim());
            yield put(searchRequestSuccess(mapServerResponse(response)));
        } catch (e) {
            console.error(e);
            yield put(searchRequestFailed(e));
        }
    }
}

export const sagas = params =>
    all([
        watchUploadSearchImages(params),
        watchUploadSearchImagesRequest(),
        watchRetrainSearchIndexRequest(),
        watchDeleteSearchImagesRequest(),
        watchDeleteSearchImagesConfirmed(),
        watchSetQuery(),
        watchSearchRequest(),
    ]);
