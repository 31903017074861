import { useMediaQuery, useTheme } from '@material-ui/core';

export const useIsMobile = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    return isMobile;
};
export const useIsXl = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.up('xl'));
    return isMobile;
};
export const useIsMobileOrTablet = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return isMobile;
};

/**
 *
 * @returns function, that renders text depending on screen width
 */
export const useResponsiveText = () => {
    const isMobile = useIsMobile();
    const isMobileOrTablet = useIsMobileOrTablet();
    const responsiveText = (desktopText: string, tabletText?: string, mobileText?: string) => {
        if (isMobile) {
            return mobileText;
        } else if (isMobileOrTablet) {
            return tabletText;
        }
        return desktopText;
    };
    return responsiveText;
};

/**
 * Determines if left menu should have "temporary" position
 */
export const useIsTemporaryLeftDrawer = () => {
    return useIsMobileOrTablet();
};
