import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';

import {
    Grid,
    Link,
    DialogTitle,
    Dialog,
    DialogContent,
    IconButton,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { lightTheme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { SUPPORT_EMAIL } from '../../utils/config';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
    link: {
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(4),
    },
}));

export const ContactUsDialog = ({ handleClose, open }: any) => {
    const classes = useStyles();
    const { t } = useTranslation('common');
    return (
        <ThemeProvider theme={lightTheme}>
            <Dialog onClose={handleClose} aria-labelledby="contact-title" open={open}>
                <DialogContent className={classes.root}>
                    <DialogTitle disableTypography>
                        {handleClose ? (
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : null}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container alignItems="center">
                            <Grid item xs={8}>
                                <Typography
                                    variant="h3"
                                    className={classes.title}
                                    id="contact-title"
                                >
                                    {t('contactAMobian')}!
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="body2"
                                    style={{ marginBottom: 32 }}
                                >
                                    {t('contactAMobianDescription')}
                                </Typography>

                                <Link href={`mailto:${SUPPORT_EMAIL}`}>
                                    <Typography gutterBottom variant="h4" className={classes.link}>
                                        {SUPPORT_EMAIL}
                                    </Typography>
                                </Link>
                                <Typography gutterBottom variant="body2">
                                    {t('contactAMobianResponseText')}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <img src="/assets/contact_avatar.png" alt="avatar" width="100%" />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    );
};
