import { eventChannel } from 'redux-saga';
import { call, put, take } from 'redux-saga/effects';
import { APPLICATION_RELOAD, GOTO_PAGE, GO_BACK_PAGE } from '../actions/actionTypes/app';
import { setCurrentPage } from '../actions/app';

function subscribeHistory(history) {
    console.log('subscribeHistory:', subscribeHistory);
    return eventChannel(emit => {
        const unlisten = history.listen(location => {
            emit(setCurrentPage({ pathname: location.pathname, location }));
        });
        return unlisten;
    });
}

function* watchHistory(params) {
    const channel = yield call(subscribeHistory, params.history);
    while (true) {
        const action = yield take(channel);
        // console.log('action:', action);
        yield put(action);
    }
}

function* watchGotoPage(params) {
    while (true) {
        const { payload } = yield take(GOTO_PAGE);
        const { pathname, transition } = payload;
        // console.log('watchGotoPage.payload', payload);
        const state = {
            transition,
            duration: 300
        };
        params.history.push({ pathname, state });
    }
}

function* watchBackPage(params) {
    while (true) {
        yield take(GO_BACK_PAGE);
        params.history.goBack();
    }
}

function* watchAndLog() {
    while (true) {
        yield take('*');
        // console.log('action', action);
    }
}

function* watchReload() {
    while (true) {
        yield take(APPLICATION_RELOAD);
        window.location.reload();
    }
}

export const sagas = params => {
    // console.log('params:', params);
    return [
        watchGotoPage(params),
        watchBackPage(params),
        watchHistory(params),
        watchAndLog(),
        watchReload()
    ];
};
