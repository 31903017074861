import { FAILED_IMAGES_REUPLOADS } from '../../utils/config';
import {
    ADD_IMAGE_TO_QUEUE,
    REMOVE_IMAGE_FROM_QUEUE,
    REUPLOAD_FAILDED_IMAGES,
    UPLOAD_COMPLETE,
    UPLOAD_FAILED,
    UPLOAD_WARNING,
    UPLOAD_IMAGES_REQ
} from '../actions/actionTypes/upload';

const initialState = {
    queue: [],
    uploadResult: {
        isError: false,
        isWarning: false,
        uploadCount: 0,
        uploadWarningCount: 0,
        uploadFailedCount: 0,
        failedToUpload: [],
        reuploadsLeft: FAILED_IMAGES_REUPLOADS
    }
};

const uploadReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_IMAGE_TO_QUEUE:
            return { ...state, queue: [...state.queue, payload] };

        case REMOVE_IMAGE_FROM_QUEUE:
            return {
                ...state,
                queue: state.queue.filter(({ file }) => file !== payload.file)
            };

        case UPLOAD_IMAGES_REQ: {
            if (state.queue.length) {
                return state;
            }
            return {
                ...state,
                uploadResult: { ...initialState.uploadResult }
            };
        }

        case UPLOAD_COMPLETE:
            return {
                ...state,
                uploadResult: {
                    ...state.uploadResult,
                    uploadCount: state.uploadResult.uploadCount + 1
                }
            };

        case UPLOAD_FAILED:
            return {
                ...state,
                uploadResult: {
                    ...state.uploadResult,
                    isError: true,
                    uploadFailedCount: state.uploadResult.uploadFailedCount + 1,
                    failedToUpload: [...state.uploadResult.failedToUpload, payload]
                }
            };

        case UPLOAD_WARNING:
            return {
                ...state,
                uploadResult: {
                    ...state.uploadResult,
                    isWarning: true,
                    uploadWarningCount: state.uploadResult.uploadWarningCount + 1,
                    failedToUpload: [...state.uploadResult.failedToUpload, payload]
                }
            };

        case REUPLOAD_FAILDED_IMAGES:
            if (state.uploadResult.reuploadsLeft === 0) {
                return state;
            }
            return {
                ...state,
                uploadResult: {
                    ...state.uploadResult,
                    reuploadsLeft: state.uploadResult.reuploadsLeft - 1,
                    isError: false,
                    uploadFailedCount: 0,
                    uploadWarningCount: 0,
                    failedToUpload: []
                }
            };

        default:
            return state;
    }
};
export { uploadReducer };
