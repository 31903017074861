import { makeStyles } from '@material-ui/core';
import React from 'react';
import { ErrorBoundary } from '../../ui/ErrorBoundary';

const useStyles = makeStyles(theme => ({
    Page: {
        height: '100%',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
}));

type Props = {
    children: React.ReactNode;
};

export function Page({ children }: Props) {
    const classes = useStyles();
    return (
        <ErrorBoundary>
            <div className={classes.Page}>{children}</div>
        </ErrorBoundary>
    );
}
