import { isMobius } from './env';

/* eslint-disable  */
function addCookieDisclaimer() {
    if (!window.location.hostname === 'localhost') {
        if (isMobius()) {
            var s = document.createElement('script');
            s.src = 'https://consent.cookiebot.com/uc.js';
            s.setAttribute('data-blockingmode', 'auto');
            s.setAttribute('data-cbid', '1403f817-bc7d-4c86-a841-2607ea98b677');

            s.defer = true;
            var parent_node = document.head || document.body;
            parent_node.appendChild(s);
            s.addEventListener('load', function () {
                <script
                    id="CookieDeclaration"
                    src="https://consent.cookiebot.com/1403f817-bc7d-4c86-a841-2607ea98b677/cd.js"
                    type="text/javascript"
                    async
                ></script>;
            });
        }
    }
}

export { addCookieDisclaimer };
