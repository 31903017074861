import { all, call, put, take } from 'redux-saga/effects';
import * as api from '../../api';
import { getGoogleLoginUrl } from '../../api/index';
import { DEFAULT_ROUTE } from '../../routes/routes';
import { featureAccess } from '../../utils/constants';
import { updateGAConfig } from '../../utils/ga';
import { setHubspotUserEmail } from '../../utils/hubspot';
import { localStorageFacade } from '../../utils/localStorage';
import { actions } from '../actions';
import {
    AUTH_CHECK_ERROR,
    AUTH_CHECK_REQ,
    AUTH_CHECK_SUCCESS,
    AUTH_GOOGLE_LOGIN_REQ,
    AUTH_LOGIN_ERROR,
    AUTH_LOGIN_REQ,
    AUTH_LOGIN_START,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGOUT_REQ,
    AUTH_LOGOUT_SUCCESS,
    AUTH_SET_TOKENS,
    ONBOARD_USER,
    ONBOARD_USER_ERROR,
    ONBOARD_USER_SUCCESS,
    SET_CONSENT,
} from '../actions/actionTypes/auth';
import { setAppConfig } from '../actions/app';
import { setTokens, updateConsent } from '../actions/auth';
import { fetchResource } from '../actions/concepts';
import { showDialog } from '../actions/dialog';

function* watchLogoutRequest({ history }) {
    while (true) {
        yield take(AUTH_LOGOUT_REQ);

        try {
            yield call(api.logout);
            updateGAConfig({ user_id: undefined, send_page_view: true });
            setHubspotUserEmail(null);
            yield put({ type: AUTH_LOGOUT_SUCCESS });
            history.push(DEFAULT_ROUTE);
        } catch (err) {
            console.log('err:', err);
        } finally {
            localStorageFacade.clearLocalStorage();
        }
    }
}

function* watchAuthCheckRequest() {
    while (true) {
        yield take(AUTH_CHECK_REQ);
        try {
            // const authData = yield call(api.getCurrentUser, {});
            const { token, refreshToken } = localStorageFacade.tokens.get();
            if (!token) {
                throw Error('Unauthorized');
            }

            const userInfo = yield call(api.getCurrentUser, null);
            updateGAConfig({ user_id: userInfo.user.id, send_page_view: !userInfo.user.isPrivate });
            setHubspotUserEmail(userInfo.user.username);

            const config = {
                ...Object.keys(featureAccess).reduce(
                    (acc, key) => ({
                        ...acc,
                        [key]: userInfo.features.includes(featureAccess[key]),
                    }),
                    {}
                ),
                userAdmin: userInfo.user.role === 'admin',
            };
            yield put(setAppConfig(config, userInfo.config));

            yield put({
                type: AUTH_CHECK_SUCCESS,
                payload: { user: userInfo.user, token, refreshToken },
            });

            if (config.imageDemo) {
                yield put(actions.imageTaggingActions.getGallery());
                yield put(actions.imageTaggingActions.getUploads());
            }
            if (config.imageFacialRecognition) {
                yield put(actions.imageFaceRecognitionActions.getGallery());
                yield put(actions.imageFaceRecognitionActions.getUploads());
            }
            if (config.logoDetection) {
                yield put(actions.imageLogoDetectionActions.getGallery());
                yield put(actions.imageLogoDetectionActions.getUploads());
            }
            if (config.cti) {
                yield put(fetchResource('concepts'));
            }
            if (config.videoTagging) {
                yield put(actions.videoTaggingActions.getGallery());
                yield put(actions.videoTaggingActions.getUploads());
            }
            if (config.videoFacialRecognition) {
                yield put(actions.videoFaceRecognitionActions.getGallery());
                yield put(actions.videoFaceRecognitionActions.getUploads());
            }
            if (config.videoHighlights) {
                yield put(actions.videoHighlightsActions.getGallery());
                yield put(actions.videoHighlightsActions.getUploads());
            }
            if (config.videoShotDetection) {
                yield put(actions.videoShotDetectionActions.getGallery());
                yield put(actions.videoShotDetectionActions.getUploads());
            }
        } catch (err) {
            updateGAConfig({ user_id: undefined, send_page_view: true });
            setHubspotUserEmail(undefined);
            yield put({ type: AUTH_CHECK_ERROR });
        }
    }
}

function* watchLoginRequest() {
    while (true) {
        const action = yield take(AUTH_LOGIN_REQ);
        const { username, password } = action;

        yield put({ type: AUTH_LOGIN_START });

        try {
            const { accessToken, refreshToken } = yield call(api.login, { username, password });

            yield put(setTokens(accessToken, refreshToken));
        } catch (err) {
            console.log('error', err);
            let error = String(err);
            if (error === 'TypeError: Failed to fetch')
                error = 'Network error. Please check internet connection!';
            yield put({ type: AUTH_LOGIN_ERROR, payload: error });
        }
    }
}

function* watchGoogleLoginRequest() {
    while (true) {
        yield take(AUTH_GOOGLE_LOGIN_REQ);
        const response = yield call(getGoogleLoginUrl);
        window.location.href = response.url;
    }
}

function* watchSetTokens() {
    while (true) {
        const {
            payload: { accessToken, refreshToken },
        } = yield take(AUTH_SET_TOKENS);

        localStorageFacade.tokens.setToken(accessToken);
        localStorageFacade.tokens.setRefreshToken(refreshToken);

        yield put({ type: AUTH_LOGIN_SUCCESS, payload: { accessToken, refreshToken } });
        yield put({ type: AUTH_CHECK_REQ });
    }
}

function* watchSetConsent() {
    while (true) {
        yield take(SET_CONSENT);
        try {
            yield call(api.setConsent);
            yield put(updateConsent());
        } catch (e) {
            const { message } = e;
            yield put(
                showDialog({
                    type: 'error',
                    action: {},
                    actionParams: {
                        message,
                    },
                })
            );
        }
    }
}

function* watchOnboardUser() {
    while (true) {
        const action = yield take(ONBOARD_USER);
        const { payload } = action;
        try {
            yield call(api.onboardUser, { type: payload.type, step: payload.step });
            yield put({ type: ONBOARD_USER_SUCCESS, payload });
        } catch (e) {
            yield put({ type: ONBOARD_USER_ERROR, payload: e });
        }
    }
}

/*
function* watchAndLog() {
    while (true) {
        const action = yield take('*');
        //console.log('action:', action)
    }
}
*/

export const sagas = params =>
    all([
        watchLoginRequest(),
        watchAuthCheckRequest(),
        watchLogoutRequest(params),
        watchSetConsent(),
        watchOnboardUser(),
        watchSetTokens(),
        watchGoogleLoginRequest(),
    ]);
