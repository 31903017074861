import { findIndex } from 'lodash';
import { ConceptInfo } from '../../utils/commonTypes';
import { GET_CONCEPT_INFO_SUCCESS, IMAGE_RATE_REQ } from '../actions/actionTypes/concept';

export type ConceptState = {
    concept: {
        [id: number]: ConceptInfo;
    };
};

const initialState: ConceptState = {
    concept: {},
};

const conceptReducer = (state: ConceptState = initialState, action: any) => {
    switch (action.type) {
        case GET_CONCEPT_INFO_SUCCESS:
            const { concept }: { concept: ConceptInfo } = action.params;
            return {
                ...state,
                concept: {
                    ...state.concept,
                    [concept.id]: concept,
                },
            };
        case IMAGE_RATE_REQ:
            const {
                id,
                filename,
                mode,
            }: { id: number; filename: string; mode: 'positive' | 'negative' | null } =
                action.params;

            const index = findIndex(state.concept[id].images.validation, { filename });

            return {
                ...state,
                concept: {
                    ...state.concept,
                    [id]: {
                        ...state.concept[id],
                        images: {
                            ...state.concept[id].images,
                            validation: [
                                ...state.concept[id].images.validation.slice(0, index),
                                {
                                    ...state.concept[id].images.validation[index],
                                    selectionType: mode,
                                },
                                ...state.concept[id].images.validation.slice(index + 1),
                            ],
                        },
                    },
                },
            };
    }
    return state;
};

export { conceptReducer };
