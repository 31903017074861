import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { actions, selectors } from '../../redux';
import { RESET_PASSWORD_REQUEST } from '../../routes/routes';
import { ButtonContainer } from './styled/ButtonContainer';
import { EmailFormControl } from './styled/EmailFormControl';
import { ErrorContainer } from './styled/ErrorContainer';
import { FormContainer } from './styled/FormContainer';
import { GoogleButton } from './styled/GoogleButton';
import { Header } from './styled/Header';
import { LinkContainer } from './styled/LinkContainer';
import { LoginTabs } from './styled/LoginTabs';
import { MainContainer } from './styled/MainContainer';
import { PasswordFormControl } from './styled/PasswordFormControl';
import { UsernameFormControl } from './styled/UsernameFormControl';

const LoginDialogBase = ({ progress, errorMessage, failed, login, history, isMobius }) => {
    const { t } = useTranslation();
    const [username, setUsername] = useState({
        value: '',
        error: false,
        message: isMobius ? 'Invalid Email' : 'Invalid Username',
    });

    const [password, setPassword] = useState({
        value: '',
        errors: false,
        message: 'Invalid Password',
    });

    const onEnter = useCallback(
        e => {
            if (e.key === 'Enter') {
                login(username.value, password.value);
            }
        },
        [login, password, username]
    );

    const onClick = useCallback(() => {
        if (!progress) {
            login(username.value, password.value);
        }
    }, [login, password.value, progress, username.value]);

    return (
        <MainContainer>
            <Header subheader={isMobius ? t('auth.pleaseRegister') : ''} />
            <Box>
                {isMobius && <LoginTabs value="0" history={history} />}
                {false && (
                    <Box width="100%" mt={4} mb={4}>
                        <GoogleButton />
                    </Box>
                )}
                <FormContainer>
                    {isMobius && (
                        <EmailFormControl
                            skipValidation
                            onEnter={onEnter}
                            onChangeUsername={setUsername}
                            disabled={progress}
                            username={username}
                        />
                    )}
                    {!isMobius && (
                        <UsernameFormControl
                            skipValidation
                            onEnter={onEnter}
                            onChangeUsername={setUsername}
                            disabled={progress}
                            username={username}
                        />
                    )}
                    <PasswordFormControl
                        skipValidation
                        onEnter={onEnter}
                        onChangePassword={setPassword}
                        disabled={progress}
                        password={password}
                    />
                    <ButtonContainer>
                        <Button
                            data-test="login-submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={progress}
                            onClick={onClick}
                        >
                            {t('auth.signIn')}
                        </Button>
                        {isMobius && (
                            <LinkContainer>
                                <Button
                                    data-test="forgot-password"
                                    color="secondary"
                                    onClick={() => {
                                        history.push(RESET_PASSWORD_REQUEST.pathname);
                                    }}
                                >
                                    {t('auth.forgotPassword')}
                                </Button>
                            </LinkContainer>
                        )}
                    </ButtonContainer>
                </FormContainer>
                <ErrorContainer error={failed} errorMessage={errorMessage} />
            </Box>
        </MainContainer>
    );
};

LoginDialogBase.propTypes = {
    errorMessage: PropTypes.string,
    failed: PropTypes.bool,
    login: PropTypes.func.isRequired,
    progress: PropTypes.bool,
    history: PropTypes.object.isRequired,
    isMobius: PropTypes.bool.isRequired,
};

LoginDialogBase.defaultProps = {
    progress: false,
    failed: false,
    errorMessage: null,
};

const mapStateToProps = state => {
    return {
        progress: selectors.isAuthProgress(state),
        errorMessage: selectors.getAuthFailMessage(state),
        failed: selectors.isAuthFail(state),
        isMobius: state.app.config.settings.isMobius,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: (username, password) => dispatch(actions.login(username, password)),
    };
};

const LoginDialog = connect(mapStateToProps, mapDispatchToProps)(LoginDialogBase);
export { LoginDialogBase, LoginDialog };
