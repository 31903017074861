export const imageDemoPrefixes = {
    tagging: 'TAGGING',
    faceRecognition: 'FACE_RECOGNITION',
    logoDetection: 'LOGO_DETECTION',
};

export const UPDATE_IMAGE_DEMO_UPLOADS = 'UPDATE_IMAGE_DEMO_UPLOADS';
export const SET_IMAGE_DEMO_UPLOADS = 'SET_IMAGE_DEMO_UPLOADS';
export const UPDATE_IMAGE_DEMO_GALERY = 'UPDATE_IMAGE_DEMO_GALERY';

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST';
export const SHOW_TUTORIAL = 'SHOW_TUTORIAL';
export const SHOW_CONCLUSION_DIALOG = 'SHOW_CONCLUSION_DIALOG';
export const START_TUTORIAL = 'START_TUTORIAL';

export const UPLOAD_IMAGE_DEMO_IMAGES = 'UPLOAD_IMAGE_DEMO_IMAGES';
export const UPDATE_IMAGE_DEMO_GALERY_ITEM = 'UPDATE_IMAGE_DEMO_GALERY_ITEM';
export const GET_GALERY = 'GET_GALERY';
export const GET_UPLOADS = 'GET_UPLOADS';
export const PREDICT_GALLERY_IMAGE = 'PREDICT_GALLERY_IMAGE';
export const REPREDICT_UPLOAD_IMAGE = 'REPREDICT_UPLOAD_IMAGE';

export const DEMO_THRESHOLD_POPUP = 'DEMO_THRESHOLD_POPUP';

export const DELETE_PREDICTION_REC = 'DELETE_PREDICTION_REC';
export const DELETE_PREDICTION_CONFIRMED = 'DELETE_PREDICTION_CONFIRMED';

export const SAVE_FACE_IDENTITY = 'SAVE_FACE_IDENTITY';
export const REMOVE_FACE_IDENTITY = 'REMOVE_FACE_IDENTITY';
