export const imageDemoEn = {
    image: 'image',
    image_plural: 'images',
    pageHeader: {
        imageTagging: 'Image Tagging',
        imageFacialRecognition: 'Image Facial Recognition',
        uploadedImages: 'Uploaded Images',
        back: 'Back',
        shareButton: 'Share',
        uploadButton: 'Upload Your Own Image',
        lastUploads: 'Last Uploads',
        overviewTag: 'Overview',
        featuresTag: 'Features',
        help: 'Help',
        tryAgain: 'Upload new image',
    },
    resultContainer: {
        header: 'Overview',
        subheader:
            'Instantly identify 10,000 highly accurate keywords including emotions, people, objects and custom concepts. Hover over the tags to see the <threshold>threshold<info/></threshold>.',
    },
    resultContainerEmotions: {
        header: 'Overview',
        subheader:
            'Promptly analyses the faces appearing in the image to recognise and tag their facial expressions.',
    },
    resultContainerLogo: {
        header: 'Overview',
        subheader:
            'MobiusLogoDetect comes with a +900 logos preset and with the tools that will allow you to add even more logos to this database.',
    },
    cards: {
        headers: {
            superhumanFeatures: 'Superhuman Features',
        },
        easyTrainable: {
            header: 'Easy Trainable',
            content:
                'Train a computer vision model that is <bold>perfectly suited for your business</bold>. You can do it with just a few training samples and some clicks-and-taps.',
            tryNow: 'Try Now',
            sdkDoc: 'SDK Documentation',
        },
        facialRecognition: {
            header: 'Facial Recognition',
            content:
                'Our facial recognition solution natively identifies over 11,000 celebrities in both images and videos. It can also learn new identities by providing just one reference image, making it very easy to identify anyone.',
        },
        aestheticRanking: {
            header: 'Aesthetic Ranking',
            content:
                'A pre-trained, powerful aesthetics model that identifies the best content from your collections.',
        },
        team: {
            header: 'Team',
            content:
                'We’re a passionate team dedicated to understanding the marvels and mysteries of visual AI.',
        },
        similaritySearch: {
            header: 'Similarity Search',
            content:
                'Search for images that are similar both in style and content by simply providing a reference image.',
        },
        faceExpression: {
            header: 'Automatic Facial Expression Analysis and Customized Expression Tagging',
            content:
                'Rather than training a system that can recognize a limited set of emotions, <bold> we decided to train facial expressions</bold>.',
        },
        accessible: {
            header: 'Accessible',
            content:
                'You have the freedom to upload and test yourself due to our main motto <bold>Superhuman vision everywhere</bold>.',
        },
        emotions: {
            header: 'Emotions',
            content: 'We’re now giving machines the faculty to recognise emotions.',
        },
    },
    faceDetails: {
        unknownName: 'Unknown',
        gender: 'Perceived Gender',
        age: 'Age',
        ethnicity: 'Ethnicity',
        metaData: 'Meta Data',
        addFaceText: ' You know this face?  Add to the database so we can regonize it next time.',
        addToDbBtn: 'Add to database',
        removeFromDbBtn: 'Remove from database',
        addBtn: 'Add',
        cancelBtn: 'Cancel',
        personNameErrorText: 'A name for the person is required',
        personNamePlaceholder: 'Name of the person',
        closeText: 'Tap outside to close',
        faceSuccessfullyAdded: 'Face successfully added!',
        successInfoOne: 'We will properly name this face whenever we find it',
        successInfoTwo: 'A more accurate database of this face will be built',
        showMeProfileBtn: 'Show me the profile',
        removeWarningText: 'That action is irreversible and will:',
        removeWarningPoint1: 'Remove the name from database',
        removeWarningPoint2: 'Turn this face into an Unknown face',
        removeWarningPoint3: ' You will have to manually add this face later',
        keepFaceBtn: 'Keep Face',
        removeBtn: 'Remove',
        somethingWentWrong: 'Something Went Wrong',
        faceTooSmall: 'Face is too small',
        betterPhoto: 'Please try with better photo',
        multipleFaceDetected: 'Multiple face detected',
        personIdNotFound: {
            header: 'Person Not Found',
            message: 'The person with such name was not found in the database',
        },
        differentPhoto: 'Please use different photo',
        tryAgain: 'Please try again in a moment',
        fixingProblem: 'We are working to fix this problem',
        goBack: 'Take me back',
        faceSuccessfullyRemoved: 'Face successfully removed!',
        defaultCannotBeRemoved: 'The faces from the default set cannot be removed',
        minHeightMessage:
            'The face should have height at least 112 pixels, to be able to save it in the face database. The height of the face is {{height}} pixels.',
    },
    logoDetails: {
        unknownName: 'Unknown',
        gender: 'Perceived Gender',
        age: 'Age',
        ethnicity: 'Ethnicity',
        metaData: 'Meta Data',
        addLogoText: ' Do you know this logo? Add to the database so we can regonize it next time.',
        addToDbBtn: 'Add to database',
        removeFromDbBtn: 'Remove reference image',
        addBtn: 'Add',
        cancelBtn: 'Cancel',
        personNameErrorText: 'Logo ID is required',
        personNamePlaceholder: 'Logo name',
        closeText: 'Tap outside to close',
        logoSuccessfullyAdded: 'Logo successfully added!',
        successInfoOne: 'We will properly name this logo whenever we find it',
        successInfoTwo: 'A more accurate database of this logo will be built',
        showMeProfileBtn: 'Show me the profile',
        removeWarningText: 'That action is irreversible and will:',
        removeWarningPoint1: 'Remove the name from database',
        removeWarningPoint2: 'Turn this logo into an Unknown logo',
        removeWarningPoint3: ' You will have to manually add this logo later',
        keepLogoBtn: 'Keep Logo',
        removeBtn: 'Remove',
        somethingWentWrong: 'Something Went Wrong',
        logoTooSmall: 'The logo is too small',
        betterPhoto: 'Please try with better photo',
        multipleLogosDetected: 'Multiple logos detected',
        personIdNotFound: {
            header: 'Person Not Found',
            message: 'The person with such name was not found in the database',
        },
        differentPhoto: 'Please use different photo',
        tryAgain: 'Please try again in a moment',
        fixingProblem: 'We are working to fix this problem',
        goBack: 'Take me back',
        logoSuccessfullyRemoved: 'Logo successfully removed!',
        defaultCannotBeRemoved: 'The logos from the default set cannot be removed',
        minHeightMessage:
            'The logo should have height at least 24 pixels, to be able to save it in the logo database. The height of the logo is {{height}} pixels.',
        refImage: 'Reference Image',
        source: {
            header: 'Source',
            notAdded: 'Not Added',
            default: 'Mobius Preset',
            added: 'Manually Added',
        },
    },
    faceRecognition: {
        noFaceFound: 'We couldn’t detect a face in the photo.',
        noFaceTryAgain: 'Please, try uploading a different photo.',
        noFaceLetUsKnow: 'Let us know if you need <support>support</support>',
        unidentified: 'Unidentified',
        poorQuality: 'Low Quality Faces',
        face: 'Face',
        people: 'People',
        facialAnalisis: 'Facial Analysis',
    },
    confirmations: {
        deleteImage: 'Do you want to delete the image? This action can not be taken back.',
        deleteButton: 'Delete',
        cancelButton: 'Cancel',
    },
    peopleHelp: {
        header: 'Why some tags are hidden?',
        text: 'Our standard concepts module include tags for various types and groups of people, but our face recognition provides much more accurate results, so these are hidden by default.',
    },
    tutorial: {
        dialog: {
            title: 'Welcome to Image Tagging',
            container:
                "You're about to read <vResult>Visual Results</vResult> from any given data to our engine.",
            footer: 'You can access this walkthrough anytime you want through the main menu.',
            skip: 'Skip',
            continue: 'Continue',
        },
        conclusion: {
            title: 'Tutorial Completed',
            desc1: 'Now you are ready to explore the feature by yourself. If you want to see the tutorial again, look for the Help button at the top.',
            restart: 'Restart tutorial',
            desc2: "Feel free to reach out to our support team at any time. We'll be glad to help.",
            contact: 'Get in touch',
            finish: 'Finish tutorial',
        },
        tour: {
            prev: 'Previous',
            continue: 'Continue',
            last: 'Finish',
            step1: {
                title: 'Tags',
                line1: "Here you'll find everything that our feature extracts from your image: <b>People, Things, Concepts, Nature, Aesthetic Score</b> and <b>more</b>.",
                line2: "Each tag comes with its own threshold. Don't forget to <b>hover over the tags to see it!</b>",
            },
            step2: {
                title: "It's that simple!",
                line1: 'If you need help with understanding how the threshold works. You can <b>learn more here.</b>',
            },
            step3: {
                title: 'Facial Recognition is also a part of our Superpowers!',
                line1: 'Click on the People tab to watch how this feature works.',
                line2: 'Tap to continue.',
            },
            step4: {
                title: 'Database',
                line1: 'Our Facial Recognition feature automatically detects people <b>(even if they are wizards).</b>',
                line2: 'Hover over the faces or the tags to see their names, gender and ages.',
            },
            step5: {
                title: 'Pro Tip 💡',
                line1: 'You can also add the names of unknown wizards <b>(and people)</b> to build your own database.',
            },
            step6: {
                title: 'Upload your content here',
                line1: 'Test this feature by uploading any image that you want to.Your uploads will be stored for 30 days.',
            },
        },
    },
};
