// MATERIAL UI
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Cancel';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        maxWidth: '500px',
        padding: theme.spacing(4, 6)
    },

    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(2, 0)
    },

    bodyContainer: {
        padding: theme.spacing(1, 0)
    },

    detailsContainer: {
        padding: theme.spacing(1, 0),
        maxHeight: '250px',
        overflow: 'auto',
        paddingTop: '40px'
    },

    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(0, 2)
    }
}));

const ErrorInfo = props => {
    const classes = useStyles();
    const { onClose, action, actionParams } = props;
    const { message, details } = actionParams;

    const [detailsOpened, setDetailsOpened] = useState(false);

    const handleConfirm = () => {
        onClose(action, actionParams);
    };

    return (
        <div className={classes.dialogContainer}>
            <div className={classes.iconContainer}>
                <ErrorIcon
                    style={{
                        color: '#E30F0F',
                        marginRight: '8px'
                    }}
                />
                <Typography
                    variant="body2"
                    style={{
                        lineHeight: '15px'
                    }}>
                    Error !
                </Typography>
            </div>
            <div className={classes.bodyContainer}>
                <Typography variant="body2">{message}</Typography>
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={handleConfirm}>
                    Okay
                </Button>
                {details && (
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        onClick={() => setDetailsOpened(v => !v)}>
                        Details
                    </Button>
                )}
            </div>
            {details && detailsOpened && (
                <div className={classes.detailsContainer}>
                    {details.map(x => {
                        return (
                            <>
                                {' '}
                                {x} <br />{' '}
                            </>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export { ErrorInfo };
