/* eslint-disable no-loop-func */
import { normalize, schema } from 'normalizr';
import hash from 'object-hash';
import { channel } from 'redux-saga';
import { all, call, fork, put, select, take } from 'redux-saga/effects';
import * as api from '../../api';
import { ImageGridItemType } from '../../components/ui/grid/ImageGridItemNode';
import { ConceptFolderEnum } from '../../utils/commonTypes';
import { SAGA_WORKERS } from '../../utils/config';
import { actions } from '../actions';
import { CT_TASK_COMPLETE, CT_TASK_START } from '../actions/actionTypes/concept';
import {
    ADD_IMAGE_TO_QUEUE,
    REMOVE_IMAGE_FROM_QUEUE,
    REUPLOAD_FAILDED_IMAGES,
    UPLOAD_COMPLETE,
    UPLOAD_FAILED,
    UPLOAD_FINISHED_WITH_FAIL,
    UPLOAD_IMAGES_REQ,
    UPLOAD_PENDING,
    UPLOAD_PROGRESS,
    UPLOAD_START,
    UPLOAD_WARNING,
} from '../actions/actionTypes/upload';
import { getConceptInfo, validateConcept } from '../actions/concept';
import { showDialog } from '../actions/dialog';
import { getUploadQueue, getUploadResult } from '../selectors/upload';

const resourceHashId = params => hash(params);

const createEntitySchema = name => new schema.Entity(name, {});

const imageSchema = createEntitySchema('images');

const imageNormz = ({ id, folderId, file, progress, phase }, response) => {
    return Promise.resolve(
        normalize(
            {
                id: resourceHashId({ id, folderId, name: file.name }),
                concept_id: String(id),
                folderId,
                filename: file.name,
                progress,
                phase,
                isLoading: !response && phase !== ImageGridItemType.pending,
                response,
            },
            imageSchema
        )
    );
};

function* handleUploadRequest({ dispatch, history }, chan) {
    while (true) {
        const params = yield take(chan);
        const { id, folderId, file } = params;

        const resLoadingStart = yield call(
            imageNormz,
            { id, folderId, file, phase: ImageGridItemType.start },
            null
        );
        yield put({ type: UPLOAD_START, ...resLoadingStart });

        const progressHandler = () => async value => {
            const resLoadingProgress = await imageNormz({
                id,
                folderId,
                file,
                progress: value,
                phase: ImageGridItemType.progress,
            });
            dispatch({ type: UPLOAD_PROGRESS, ...resLoadingProgress });
        };

        let uploadPayload;
        try {
            uploadPayload = yield call(
                api.uploadImage,
                { id, folderId, file },
                progressHandler(file.name)
            );
            const resComplete = yield call(
                imageNormz,
                { id, folderId, file, phase: ImageGridItemType.complete },
                uploadPayload
            );
            yield put({ type: UPLOAD_COMPLETE, ...resComplete });
            yield put(actions.addCurrentUserUpload());
        } catch (e) {
            console.warn(`File ${file.name} has not been uploaded`);
            const { message, response } = e;
            if (response && response.status === 409)
                yield put({
                    type: UPLOAD_WARNING,
                    payload: { id, folderId, file, message: 'Picture already in concept' },
                });
            else if (response && response.status === 415)
                yield put({
                    type: UPLOAD_FAILED,
                    payload: { id, folderId, file, message: 'Unknown file format' },
                });
            else yield put({ type: UPLOAD_FAILED, payload: { id, folderId, file, message } });
        } finally {
            yield put({ type: REMOVE_IMAGE_FROM_QUEUE, payload: { id, folderId, file } });
        }

        const imageQueue = yield select(getUploadQueue());
        if (imageQueue.length === 0) {
            const {
                isWarning,
                isError,
                uploadCount,
                uploadFailedCount,
                uploadWarningCount,
                failedToUpload,
                reuploadsLeft,
            } = yield select(getUploadResult());

            if (isError && reuploadsLeft > 0) {
                // eslint-disable-next-line no-restricted-syntax
                for (const image of failedToUpload) {
                    yield put({ type: ADD_IMAGE_TO_QUEUE, payload: { ...image } });
                }
                yield put({ type: REUPLOAD_FAILDED_IMAGES });
            } else {
                yield put({ type: CT_TASK_START, payload: { status: 'payload_started' } });

                if (isError || isWarning) {
                    yield put({
                        type: UPLOAD_FINISHED_WITH_FAIL,
                        payload: { files: failedToUpload.map(x => x.file) },
                    });
                }
                if (isError || isWarning) {
                    const message = uploadCount
                        ? `${uploadCount} out of ${
                              uploadCount + uploadFailedCount + uploadWarningCount
                          } images have been uploaded!`
                        : 'Images have not been uploaded!';
                    const details = failedToUpload.map(msg => {
                        return `${msg.file.name}: ${msg.message}`;
                    });
                    yield put(
                        showDialog({
                            type: isError ? 'error' : 'warning',
                            action: {},
                            actionParams: {
                                message: isError
                                    ? `An error has occurred during the upload. ${message}`
                                    : `Duplicate images occurred. ${message}`,
                                details,
                            },
                        })
                    );
                } else {
                    yield put(
                        showDialog({
                            type: 'success',
                            action: {},
                            actionParams: { message: 'All images have been uploaded!' },
                        })
                    );
                }
                yield put({
                    type: CT_TASK_COMPLETE,
                    payload: {
                        status: 'upload_complete',
                    },
                });
                if (folderId === ConceptFolderEnum.validation) {
                    yield put(validateConcept(id));
                } else {
                    yield put(getConceptInfo(id));
                }
            }
        }
    }
}

function* watchUploadRequests(params) {
    // create a channel to queue incoming requests
    const chan = yield call(channel);

    // create upload worker "threads"
    const workersNum = SAGA_WORKERS;
    for (let i = 0; i < workersNum; i++) {
        yield fork(handleUploadRequest, params, chan);
    }

    while (true) {
        const { payload } = yield take(ADD_IMAGE_TO_QUEUE);
        yield put(chan, payload);
    }
}

function* watchImageUpload() {
    while (true) {
        const { params } = yield take(UPLOAD_IMAGES_REQ);

        const { id, folderId, files } = params;

        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
            const uploadPending = yield call(
                imageNormz,
                { id, folderId, file, phase: ImageGridItemType.pending },
                null
            );
            yield put({ type: UPLOAD_PENDING, ...uploadPending });
            yield put({ type: ADD_IMAGE_TO_QUEUE, payload: { id, folderId, file } });
        }
    }
}

function* watchUpdateFolderImages() {
    while (true) {
        const action = yield take('RESOURCE_UPDATE_FOLDER_IMAGES');
        if (action.phase === ImageGridItemType.complete) {
            const {
                hash: {
                    params: { id },
                },
            } = action.entities.folder_images[action.result];
            yield put({
                type: 'IMAGE_ENTITY_CLEAR',
                payload: {
                    id,
                },
            });
        }
    }
}

export const sagas = params =>
    all([watchImageUpload(), watchUploadRequests(params), watchUpdateFolderImages()]);
