import { connect } from 'react-redux';
import { BasePopover } from './BasePopover';

const mapStateToProps = state => {
    return {
        message: 'Share link has been copied to clipboard',
        open: state.share.isPopoverShown,
        showProgress: false
    };
};

const ShareClipboardPopover = connect(mapStateToProps)(BasePopover);

export { ShareClipboardPopover };
