import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React from 'react';
import { ErrorBoundary } from '../components/ui/ErrorBoundary';
import { AppContainerPublic } from '../containers/AppContainerPublic';
import { darkTheme } from '../theme';
import { useHubspotPageView } from '../utils/hubspot';

const PublicApp = ({ children, ...props }) => {
    useHubspotPageView();
    return (
        <>
            <MuiThemeProvider theme={darkTheme}>
                <CssBaseline />
                <ErrorBoundary rootBoundary>
                    <AppContainerPublic {...props}>{children}</AppContainerPublic>
                </ErrorBoundary>
            </MuiThemeProvider>
        </>
    );
};

PublicApp.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
};

export { PublicApp };
