import {
    GET_USER_ERROR,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    SET_USER_ERROR,
    SET_USER_REQUEST,
    SET_USER_SUCCESS,
    UPDATE_USER_LIST
} from '../actions/actionTypes/user';

export const initialState = {
    userList: [],
    count: 0,
    pages: 0,
    filters: {
        page: 0,
        filter: {
            usernameSearch: ''
        }
    },
    getUserRequest: {
        isPending: false,
        isCompleted: false,
        error: null,
        data: null
    },
    saveUserRequest: {
        isPending: false,
        isCompleted: false,
        error: null
    }
};

const userReducer = (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case UPDATE_USER_LIST:
            return {
                ...state,
                userList: payload.userList,
                count: payload.count,
                pages: payload.pages,
                filters: payload.filter,
                saveUserRequest: {
                    isPending: false,
                    isCompleted: false,
                    error: null
                }
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                getUserRequest: {
                    isPending: false,
                    error: null,
                    isCompleted: true,
                    data: payload.user
                }
            };
        case GET_USER_ERROR:
            return {
                ...state,
                getUserRequest: {
                    isPending: false,
                    isCompleted: true,
                    error: payload.error,
                    data: null
                }
            };
        case GET_USER_REQUEST:
            return {
                ...state,
                getUserRequest: {
                    isPending: true,
                    isCompleted: false,
                    error: null,
                    data: null
                }
            };

        case SET_USER_SUCCESS:
            return {
                ...state,
                saveUserRequest: {
                    isPending: false,
                    isCompleted: true,
                    error: null
                }
            };
        case SET_USER_ERROR:
            return {
                ...state,
                saveUserRequest: {
                    isPending: false,
                    isCompleted: true,
                    error: payload.error
                }
            };
        case SET_USER_REQUEST:
            return {
                ...state,
                saveUserRequest: {
                    isPending: true,
                    isCompleted: false,
                    error: null
                }
            };

        default:
            return state;
    }
};

export { userReducer };
