import { ImageGridItemData } from '../components/ui/grid/ImageGridItemNode';
import { ConceptState } from '../redux/reducers/concept';
import { ImageDemoState } from '../redux/reducers/imageDemo';
import { featureAccess, predictionTypes } from './constants';

export type AppSettings = {
    allowedFeatures: string[];
    isEmailServerSetup: boolean;
    isMobius: boolean;
    standardConceptsThreshold: number;
    sdkConfig: {
        custom_concepts: boolean;
        face_emotions: boolean;
        face_identities: boolean;
        face_recognition: boolean;
        quality_score: boolean;
        standard_concepts: boolean;
        video: boolean;
    };
};

export type FeatureAccessKeys = keyof typeof featureAccess;

export type FeatureAccessFlags = {
    [index in FeatureAccessKeys]: boolean;
};
export type Features = {
    [index in FeatureAccessKeys | 'userAdmin']: boolean;
};

export type AppConfig = {
    baseUrl: string;
    features: Features;
    settings: AppSettings;
};

export type SearchState = {
    trainTask: any;
    upload: {
        uploaded: number;
        failed: number;
        total: number;
    };
    result: {
        loading: boolean;
        loaded: boolean;
        error: Error | null;
        images: ImageGridItemData[];
    };
    query: string;
};

export type User = {
    id: number;
    confirmed: boolean;
    isPrivate: boolean;
    uploadImagesCounter: number;
    role: 'user' | 'admin';
    validUntil: string | null;
    consentGiven?: boolean;
    uploadImagesMax?: number;
    username: string;
    firstName?: string;
    lastName?: string;
    fullName: string;
    company?: string;
    industry?: string[];
} & FeatureAccessFlags;

export type UserObject = {
    user: {
        id: number;
        username: string;
        firstName?: string;
        lastName?: string;
        role: string;
        company?: string;
        industry?: string;
        solutions?: string[];
        uploadImagesCounter?: number;
        uploadImagesMax?: number;
        consentGiven?: boolean;
        isPrivate: boolean;
        onboarding: any;
    };
    features: string[];
    config: {
        isMobius: boolean;
        isEmailServerSetup: boolean;
        sdkConfig: any;
        allowedFeatures: string[];
        standardConceptsThreshold: number;
    };
};

export enum ConceptFolderEnum {
    positive = 'positive',
    negative = 'negative',
    validation = 'validation',
    test = 'test',
    train = 'train',
}

export enum ConceptSelectionMode {
    delete = 'delete',
    change = 'change',
}

export type ConceptFolder = 'positive' | 'negative' | 'test' | 'validation';

export type ReduxState = {
    app: {
        config: AppConfig;
        [key: string]: any;
    };
    video: any;
    search: SearchState;
    concept: ConceptState;
    imageDemo: ImageDemoState;
    [key: string]: any;
};

export type AppDispatch = any;

export type DialogParams = {
    action: Record<string, any>;
    actionParams: Record<string, any>;
    onClose: (action?: object, params?: any) => void;
};

export type Identity = {
    groupId: string;
    personId: string;
};

export type FaceWithTimestamps = {
    age: number;
    emotions: Array<{ name: string; timestamps: Array<{ end: number; start: number }> }>;
    ethnicity: string;
    faceFeatures: Array<{
        name: string;
        timestamps: Array<{ end: number; start: number }>;
    }>;
    gender: string;
    identity: Identity[];
};

export type BoundingBox = {
    left: number;
    lower: number;
    right: number;
    upper: number;
};

export type Face = {
    age: number;
    emotions: Array<{ name: string }>;
    ethnicity: string;
    faceFeatures: Array<{
        name: string;
        // timestamps:
    }>;
    gender: string;
    identity: Identity[];
    boundingBox?: BoundingBox;
};

export type LogoInfo = {
    groupId: string;
    imageId: string;
    logoId: string;
    score: number;
};

export type Logo = {
    boundingBox?: BoundingBox;
    logoInfo: LogoInfo[];
};

export type VideoSegmentTimestamp = { end: number; start: number; index: number };

export type VideoResponse<T> = {
    isError: boolean;
    isFinished: boolean;
    name: string;
    path: string;
    status: 'ongoing' | 'success'; // maybe something else, comes from SDK. Use `isError` and `isFinished instead
    taskId: number;
    taskUid: string;
    predictionId: number; // can be used as unique id of the video
    predictionType: predictionTypes;
    prediction?: T;
};

export type SubSegment = {
    faceRecognition: Face[];
    timestamp: VideoSegmentTimestamp;
};

export type VideoFaceRecognitionResponse = VideoResponse<{
    videoLevel: { faceRecognition: FaceWithTimestamps[] };
    segmentLevel: Array<{
        faceRecognition: Face[];
        timestamp: VideoSegmentTimestamp;
    }>;
    frameLevel?: Array<{
        faceRecognition: Face[];
        timestamp: VideoSegmentTimestamp;
    }>;
    subsegmentLevel: Array<SubSegment>;
    faceRecognitionTimestamps?: any; // TBD
    metaInformation?: any; // TBD
    params?: any; // TBD
    tagsTimestamps?: any; // TBD
    timestamps?: any; // TBD
    status: 'ongoing' | 'success';
}>;

export type VideoShotDetectionResponse = VideoResponse<{
    // videoLevel: {};
    segmentLevel: Array<{
        timestamp: VideoSegmentTimestamp;
    }>;
    subsegmentLevel: Array<{
        timestamp: { end: number; start: number; index: number };
    }>;
    metaInformation?: {
        width: number;
        height: number;
        // TBD
    };
}>;

export type FormValue = {
    value?: string | string[];
    error: boolean;
    message: string;
};

export type ConceptValidationImageType = {
    classifierId: number;
    filename: string;
    folderType: string;
    id: number;
    origFilename: string;
    path: string;
    resultType: string;
    score: number;
    selectionType: string;
    imageType: string;
};

export type ConceptInfo = {
    id: number;
    name: string;
    isTrained: boolean;
    images: {
        positive: string[];
        negative: string[];
        validation: ConceptValidationImageType[];
    };
};

export type LabelingObject = {
    detectionClassId: number | string;
    boundingBox: BoundingBox;
};

export type LabelingClass = {
    id: number | string;
    name: string;
};

export type LabelingObj = {
    labelingClasses: LabelingClass[];
    labeledBoundingBoxes: LabelingObject[];
};

export enum RESPONSE_STATUS {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}
