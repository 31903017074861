import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        maxWidth: '500px',
        padding: theme.spacing(4, 6),
    },

    bodyContainer: {
        padding: theme.spacing(1, 0),
    },

    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(0, 2),
    },

    radioLabel: {
        flex: 1,
        fontSize: '14px',
    },
}));

const MoveImagesDialog = props => {
    const classes = useStyles();
    const { onClose, action, actionParams } = props;
    const {
        folder: { type },
    } = actionParams;

    const handleCancel = () => {
        onClose();
    };

    const moveTarget = type === 'positive' ? 'negative' : 'positive';
    const handleConfirm = () => {
        onClose(action, { ...actionParams, moveTarget });
    };

    return (
        <div className={classes.dialogContainer}>
            <div className={classes.bodyContainer}>
                <Typography variant="body2">
                    Are you sure that you want to move all selected images to the {moveTarget}{' '}
                    folder?
                </Typography>
            </div>

            <div className={classes.buttonContainer}>
                <Button className={classes.button} variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button
                    data-test="dialog-move-confirmation"
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={handleConfirm}
                >
                    Move
                </Button>
            </div>
        </div>
    );
};

export { MoveImagesDialog };
