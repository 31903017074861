import { FeatureAccessFlags, FeatureAccessKeys, User } from './commonTypes';

export const folderTypes = {
    positive: 'positive',
    negative: 'negative',
    validation: 'validation',
    test: 'test',
};

export enum predictionTypes {
    uploadedImage = 'uploaded_image',
    galleryImage = 'gallery_image',
    faceRecognitionImage = 'face_recognition_image',
    faceRecognitionGalleryImage = 'face_recognition_gallery_image',
    logoDetectionImage = 'logo_detection_image',
    logoDetectionGalleryImage = 'logo_detection_gallery_image',

    uploadedVideo = 'uploaded_video',
    galleryVideo = 'gallery_video',
    faceRecognitionVideo = 'face_recognition_video',
    faceRecognitionGalleryVideo = 'face_recognition_gallery_video',
    shotDetectionVideo = 'shot_detection_video',
    shotDetectionGalleryVideo = 'shot_detection_gallery_video',
    highlightsVideo = 'highlights_video',
    highlightsGalleryVideo = 'highlights_gallery_video',
}

export enum featureAccess {
    cti = 'cti',
    imageDemo = 'image_demo',
    imageFacialRecognition = 'image_facial_recognition',
    videoTagging = 'video_tagging',
    textSearch = 'text_search',
    videoFacialRecognition = 'video_facial_recognition',
    videoHighlights = 'video_highlights',
    videoShotDetection = 'video_shot_detection',
    earthObservation = 'earth_observation',
    objectDetection = 'object_detection',
    // to be implemented:
    videoProfanityDetection = 'video_profanity_detection',
    objectSegmentation = 'object_segmentation',
    audioSpeechToText = 'audio_speech_to_text',
    audioTagging = 'audio_tagging',
    audioProfanityDetection = 'audio_profanity_detection',
    logoDetection = 'logo_detection',
}

export enum OnboardingEnum {
    image_tagging = 'imageTagging',
}

export const dialogTypes = {
    deleteConfigConfirmation: 'concept_del_conf',
    predictionDeleteConfirmation: 'prediction_delete_confirmation',
    conceptPublishConfirmation: 'concept_pub_conf',
    conceptThresholdConfirmation: 'concept_threshold_conf',
    deleteVideoConfirmation: 'deleteVideoConfirmation',
    conceptCreate: 'concept_create',
    deleteImages: 'delete_images',
    moveImages: 'move_images',
    success: 'success',
    error: 'error',
    warning: 'warning',
    consent: 'consent',
    demoUpload: 'demo_upload',
    demoThresholdInfo: 'demo_threshold_info',
    uploadVideo: 'upload_video',
    tutorialDialog: 'tutorial_dialog',
    conclusionDialog: 'conclusion_dialog',
    deleteSearchImagesConfirmation: 'delete_search_images_confirmation',
};

export const TOUR_SHOW_TIME = 3000;

export const copyFeatureAccessFields = (user: User) => {
    const result = {} as FeatureAccessFlags;
    Object.keys(featureAccess).forEach(key => {
        result[key as FeatureAccessKeys] = user[key as FeatureAccessKeys];
    });
    return result;
};

export const CDN_URL = process.env.REACT_APP_CDN_URL || '';

export const UPLOADS_PATH = `${CDN_URL}/static/predictions`;
export const STATIC_PATH = `${CDN_URL}/static/default`;
export const CTI_PATH = `${CDN_URL}/static/cti_images`;
export const SEARCH_PATH = `${CDN_URL}/static/search`;

export const PEOPLE_CATEGORY = 'people';

export const lstIndustries = [
    { id: '1', value: 'asset_management' },
    { id: '2', value: 'content_licensing' },
    { id: '3', value: 'creative_platforms' },
    { id: '4', value: 'geospatial' },
    { id: '5', value: 'insurance' },
    { id: '6', value: 'internet_print_service' },
    { id: '7', value: 'media' },
    { id: '8', value: 'press_broadcasting' },
    { id: '9', value: 'video_production' },
    { id: '10', value: 'video_services' },
    { id: '11', value: 'other' },
];

export const lstSolutions = [
    { id: '1', value: 'content_moderation' },
    { id: '2', value: 'custom_concepts' },
    { id: '3', value: 'facial_recognition' },
    { id: '4', value: 'image_tagging' },
    { id: '5', value: 'object_detection' },
    { id: '6', value: 'video_segmentation' },
    { id: '7', value: 'video_tagging' },
    { id: '8', value: 'visual_search' },
    { id: '9', value: 'other' },
];

export const VIDEO_FACE_RECOGNITION_LEVEL: 'segmentLevel' | 'subsegmentLevel' = 'subsegmentLevel';
export const CONCEPTS_MAX_SEARCH_RESULTS = 200;

export enum sdkTaskStatus {
    ongoing = 'ongoing',
    inQueue = 'in_queue',
    success = 'success',
    error = 'error',
}

export const VIDEO_PREDICTION_HEIGHT = 360;
