import { findIndex } from 'lodash';
import { BoundingBox, Face } from '../../utils/commonTypes';
import { UPLOADS_PAGE_SHOWN_ITEMS } from '../../utils/config';
import { predictionTypes } from '../../utils/constants';
import {
    imageDemoPrefixes,
    SET_IMAGE_DEMO_UPLOADS,
    START_TUTORIAL,
    UPDATE_IMAGE_DEMO_GALERY,
    UPDATE_IMAGE_DEMO_GALERY_ITEM,
    UPDATE_IMAGE_DEMO_UPLOADS,
} from '../actions/actionTypes/imageDemo';

export type LogoPrediction = {
    boundingBox: BoundingBox;
    logoInfo: Array<{
        groupId: string;
        imageId: string;
        logoId: string;
        score: number;
    }>;
};

export type LogoDetectionImage = {
    detection: {
        logoDetection: Array<LogoPrediction>;
        params: any;
        status: string;
    };
};

export type FaceRecognitionImage = {
    params: any;
    status: string;
    faceRecognition: Face[];
};
export type TaggingImage = {
    aesthetics?: any;
    params: any;
    status: string;
    tags: any;
    faceRecognition: Face[];
};

export type DemoImage<T> = {
    src: string;
    id: number | null;
    name: string;
    date?: number;
    folder?: string;
    predictionType: predictionTypes;
    predict: T;
};

export type ImageDemoState = {
    tagging: {
        gallery: { [key: string]: DemoImage<TaggingImage>[] } | null;
        uploads: DemoImage<TaggingImage>[];
    };
    faceRecognition: {
        gallery: { [key: string]: DemoImage<FaceRecognitionImage>[] } | null;
        uploads: DemoImage<FaceRecognitionImage>[];
    };
    logoDetection: {
        gallery: { [key: string]: DemoImage<LogoDetectionImage>[] } | null;
        uploads: DemoImage<LogoDetectionImage>[];
    };
    tutorial: {
        start: boolean;
    };
};

export const initialState: ImageDemoState = {
    tagging: {
        gallery: null,
        uploads: [],
    },
    faceRecognition: {
        gallery: null,
        uploads: [],
    },
    logoDetection: {
        gallery: null,
        uploads: [],
    },
    tutorial: {
        start: false,
    },
};

function updateUploadByIndex(uploads: any, i: any, update: any) {
    const updatedUpload = update(uploads[i]);
    let newUploads;
    if (updatedUpload) {
        newUploads = [...uploads.slice(0, i), updatedUpload, ...uploads.slice(i + 1)];
    } else {
        newUploads = [...uploads.slice(0, i), ...uploads.slice(i + 1)];
    }
    if (newUploads.length > UPLOADS_PAGE_SHOWN_ITEMS) {
        for (let j = 0; j < newUploads.length - UPLOADS_PAGE_SHOWN_ITEMS; j++) {
            newUploads[j] = {
                ...newUploads[j],
                src: null,
            };
        }
    }
    return newUploads;
}

function updateUploadById(uploads: any, id: any, update: any) {
    return uploads.reduce((acc: any, upload: any, index: any) => {
        if (upload.id === id) {
            return updateUploadByIndex(acc, index, update);
        }
        return acc;
    }, uploads);
}

function updateUpload(uploads: any, index: any, id: any, update: any) {
    if (id) {
        return updateUploadById(uploads, id, update);
    }
    return updateUploadByIndex(uploads, index, update);
}

function updateGalleryItem(
    gallery: any,
    { folder, name, prediction }: { folder: any; name: any; prediction: any }
) {
    const folderIndex = findIndex(gallery[folder], (item: any) => item.name === name);
    const newGallery = { ...gallery };
    newGallery[folder][folderIndex] = { ...newGallery[folder][folderIndex], predict: prediction };
    return newGallery;
}

const imageDemoReducer = (state = initialState, action: any) => {
    const { payload } = action;
    switch (action.type) {
        case `${imageDemoPrefixes.tagging}_${UPDATE_IMAGE_DEMO_UPLOADS}`:
            return {
                ...state,
                tagging: {
                    ...state.tagging,
                    uploads: updateUpload(
                        state.tagging.uploads,
                        payload.index,
                        payload.id,
                        payload.update
                    ),
                },
            };
        case `${imageDemoPrefixes.faceRecognition}_${UPDATE_IMAGE_DEMO_UPLOADS}`:
            return {
                ...state,
                faceRecognition: {
                    ...state.faceRecognition,
                    uploads: updateUpload(
                        state.faceRecognition.uploads,
                        payload.index,
                        payload.id,
                        payload.update
                    ),
                },
            };
        case `${imageDemoPrefixes.logoDetection}_${UPDATE_IMAGE_DEMO_UPLOADS}`:
            return {
                ...state,
                logoDetection: {
                    ...state.logoDetection,
                    uploads: updateUpload(
                        state.logoDetection.uploads,
                        payload.index,
                        payload.id,
                        payload.update
                    ),
                },
            };

        case `${imageDemoPrefixes.tagging}_${SET_IMAGE_DEMO_UPLOADS}`:
            return {
                ...state,
                tagging: {
                    ...state.tagging,
                    uploads: [...payload.uploads],
                },
            };

        case `${imageDemoPrefixes.faceRecognition}_${SET_IMAGE_DEMO_UPLOADS}`:
            return {
                ...state,
                faceRecognition: {
                    ...state.faceRecognition,
                    uploads: [...payload.uploads],
                },
            };
        case `${imageDemoPrefixes.logoDetection}_${SET_IMAGE_DEMO_UPLOADS}`:
            return {
                ...state,
                logoDetection: {
                    ...state.logoDetection,
                    uploads: [...payload.uploads],
                },
            };

        case `${imageDemoPrefixes.tagging}_${UPDATE_IMAGE_DEMO_GALERY}`:
            return {
                ...state,
                tagging: {
                    ...state.tagging,
                    gallery: { ...payload.gallery },
                },
            };
        case `${imageDemoPrefixes.faceRecognition}_${UPDATE_IMAGE_DEMO_GALERY}`:
            return {
                ...state,
                faceRecognition: {
                    ...state.faceRecognition,
                    gallery: { ...payload.gallery },
                },
            };
        case `${imageDemoPrefixes.logoDetection}_${UPDATE_IMAGE_DEMO_GALERY}`:
            return {
                ...state,
                logoDetection: {
                    ...state.logoDetection,
                    gallery: { ...payload.gallery },
                },
            };

        case `${imageDemoPrefixes.tagging}_${UPDATE_IMAGE_DEMO_GALERY_ITEM}`:
            return {
                ...state,
                tagging: {
                    ...state.tagging,
                    gallery: updateGalleryItem(state.tagging.gallery, payload),
                },
            };
        case `${imageDemoPrefixes.faceRecognition}_${UPDATE_IMAGE_DEMO_GALERY_ITEM}`:
            return {
                ...state,
                faceRecognition: {
                    ...state.faceRecognition,
                    gallery: updateGalleryItem(state.faceRecognition.gallery, payload),
                },
            };
        case `${imageDemoPrefixes.logoDetection}_${UPDATE_IMAGE_DEMO_GALERY_ITEM}`:
            return {
                ...state,
                logoDetection: {
                    ...state.logoDetection,
                    gallery: updateGalleryItem(state.logoDetection.gallery, payload),
                },
            };
        case START_TUTORIAL:
            return {
                ...state,
                tutorial: {
                    ...state.tutorial,
                    start: payload,
                },
            };

        default:
            return state;
    }
};

export { imageDemoReducer };
