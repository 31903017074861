import { Box, Button, Grid, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
    imgSrc?: string;
    title?: string;
    text?: React.ReactNode;
};

export const NotFoundScreen = ({ imgSrc = '/assets/404.png', title, text }: Props) => {
    const history = useHistory();
    return (
        <Box
            style={{
                height: '100%',
                marginTop: '10%',
                paddingLeft: '10%',
            }}
        >
            <Grid
                container
                spacing={2}
                style={{
                    float: 'right',
                    height: '100%',
                    backgroundPosition: 'right',
                    backgroundImage: `url(${imgSrc})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                }}
            >
                <Grid item xs={12} md={6}>
                    <Typography variant="h1" gutterBottom style={{ fontSize: '3rem' }}>
                        {title ?? (
                            <span>
                                404 Well...
                                <br />
                                This is awkward.
                            </span>
                        )}
                    </Typography>
                    <Box mb={2}>
                        <Typography variant="subtitle1">
                            {text ?? (
                                <span>
                                    Apparently this page took the day off, <br />
                                    please go back to the previous page
                                </span>
                            )}
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<ArrowBack />}
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        Go Back
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};
