const AGE_TAGS = [
    '0-1 months',
    '0-11 months',
    '10-11 years',
    '12-13 years',
    '12-17 months',
    '12-23 months',
    '14-15 years',
    '16-17 years',
    '18-19 years',
    '18-23 months',
    '2-3 years',
    '2-5 months',
    '20-24 years',
    '20-29 years',
    '25-29 years',
    '30-34 years',
    '30-39 years',
    '35-39 years',
    '4-5 years',
    '40-44 years',
    '40-49 years',
    '45-49 years',
    '50-54 years',
    '50-59 years',
    '55-59 years',
    '6-11 months',
    '6-7 years',
    '60-64 years',
    '60-69 years',
    '65-69 years',
    '70-79 years',
    '8-9 years',
    '80-89 years',
    'adolescent',
    'adult',
    'child',
    'senior adult',
    'senior citizen',
    'senior couple',
    'senior men',
    'senior women',
    'seniors',
    'mature adult',
    'mature men',
    'mature women',
    'mid adult',
    'mid adult couple',
    'mid adult men',
    'mid adult women',
    'pre-adolescent child',
    'teenage boys',
    'teenage couple',
    'teenage girls',
    'teenager',
    'toddler',
    'young adult',
    'young couple',
    'young family',
    'young men',
    'young women',
];

const GENDER_TAGS = [
    'beautiful woman',
    'boy',
    'boys',
    'daughter',
    'female',
    'female doctor',
    'female high school student',
    'female nurse',
    'female person',
    'female with group of males',
    'females',
    'girl',
    'girls',
    'granddaughter',
    'grandfather',
    'grandmother',
    'grandparent',
    'grandson',
    'lady',
    'male',
    'male person',
    'males',
    'man',
    'men',
    'woman',
    'women',
];

const ETHNICITY_TAGS = [
    'african ethnicity',
    'african-american ethnicity',
    'afro-caribbean ethnicity',
    'argentinian ethnicity',
    'asian and indian ethnicity',
    'brazilian ethnicity',
    'caucasian ethnicity',
    'chinese ethnicity',
    'cuban ethnicity',
    'east asian ethnicity',
    'eastern european descent',
    'ethiopian ethnicity',
    'filipino ethnicity',
    'indian ethnicity',
    'indian subcontinent ethnicity',
    'italian ethnicity',
    'jamaican ethnicity',
    'japanese ethnicity',
    'korean ethnicity',
    'latin american and hispanic ethnicity',
    'mexican ethnicity',
    'middle eastern ethnicity',
    'native american ethnicity',
    'northern european descent',
    'pakistani ethnicity',
    'russian ethnicity',
    'southeast asian ethnicity',
    'southern european descent',
    'spanish and portuguese ethnicity',
    'taiwanese ethnicity',
    'thai ethnicity',
    'turkish ethnicity',
    'vietnamese ethnicity',
];

export const PEOPLE_TAGS = [...AGE_TAGS, ...GENDER_TAGS, ...ETHNICITY_TAGS];
