import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { localStorageFacade } from '../utils/localStorage';
import { logger } from '../utils/logging';

export const API_HOST = process.env.REACT_APP_API_URL || '/api';

const client = applyCaseMiddleware(axios.create(), {
    ignoreHeaders: true,
    preservedKeys: input => {
        const imageReg = /[.](jpg|jpeg|tif|tiff|png|mp4|avi|mkv|mov)$/i;
        return imageReg.test(input);
    },
});

const sendRefreshTokenRequest = url => {
    return client.request({
        method: 'post',
        url,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: localStorageFacade.tokens.headerRefreshToken(),
        },
    });
};

client.interceptors.response.use(null, error => {
    if (error.config && error.response && error.response.status === 401) {
        const { refreshToken, token } = localStorageFacade.tokens.get();
        if (refreshToken) {
            const { config } = error;
            const url = `${API_HOST}/refresh`;
            if (config.url !== url) {
                return sendRefreshTokenRequest(url)
                    .then(response => {
                        localStorageFacade.tokens.setToken(response.data.accessToken);
                        config.headers.Authorization = localStorageFacade.tokens.headerToken();
                        return client.request(config);
                    })
                    .catch(() => {
                        localStorageFacade.tokens.clear();
                        window.location.reload();
                    });
            }
        }
        if (token) {
            localStorageFacade.tokens.clear();
            window.location.reload();
        }
    }

    return Promise.reject(error);
});

const sendRequest = (
    path,
    method,
    data = {},
    responseHandler = r => r,
    errorHandler = e => {
        throw e;
    },
    progressCallback = () => {},
    cancelTokenSource = {}
) => {
    const { params } = data;
    // eslint-disable-next-line no-param-reassign
    delete data.params;

    const requestConfig = {
        method,
        url: `${API_HOST}${path}`,
        data,
        params,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: localStorageFacade.tokens.headerToken(),
        },
        withCredentials: true,
        cancelToken: cancelTokenSource.token,
        onUploadProgress: p => {
            let progress = 0;
            if (p.total !== 0) {
                progress = parseInt((p.loaded / p.total) * 100, 10);
            }
            progressCallback(progress);
        },
    };

    return client.request(requestConfig).then(responseHandler).catch(errorHandler);
};

const getErrorMessage = data => {
    if (data.messages) {
        const errors = [];
        Object.keys(data.messages).forEach(field => {
            errors.push(...data.messages[field]);
        });
        return errors.join('. ');
    }

    if (data.message) {
        return data.message;
    }
    return '';
};

const handleError = error => {
    if (!error.response) {
        throw Error('Network failure. Please check internet connection and try again!');
    } else {
        const { status, data } = error.response;

        if (status === 401) {
            return Promise.reject(new Error(getErrorMessage(data)));
        }
        if (status === 422) {
            localStorageFacade.tokens.clear();
            window.location.reload();
        }
        if (status >= 400 && status < 500) {
            try {
                return Promise.reject(
                    new Error(getErrorMessage(data) || 'Bad response from server')
                );
            } catch {
                return Promise.reject(new Error('Bad response from server'));
            }
        }
        throw Error('Something went wrong');
    }
};

const handleErrorUpload = e => {
    if (axios.isCancel(e)) {
        console.log('UPLOAD.canceled', e);
        throw e;
    } else {
        logger.error('UPLOAD.error', e);
    }
    return handleError(e);
};

const handleResponse = async response => {
    const { data } = response;

    return Promise.resolve(data);
};

const handleResponseUpload =
    (progressCallback = () => {}) =>
    response => {
        const { status } = response;
        if (status >= 200 && status <= 201) {
            progressCallback(100);
        }
        return handleResponse(response);
    };

const get = (path, params) => {
    return sendRequest(path, 'get', { params }, handleResponse, handleError);
};

const remove = path => {
    return sendRequest(path, 'delete', {}, handleResponse, handleError);
};

const post = (path, params) => {
    return sendRequest(path, 'post', params, handleResponse, handleError);
};

const put = (path, params) => {
    return sendRequest(path, 'put', params, handleResponse, handleError);
};

const getBlob = async path => {
    return await client.get(path, {
        responseType: 'blob',
        headers: {
            Authorization: localStorageFacade.tokens.headerToken(),
        },
    });
};

const getImageBlob = async path => {
    const imageBlob = await getBlob(path);

    const reader = new FileReader();
    return new Promise(resolve => {
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(imageBlob.data);
    });
};

const upload = (path, file, params, progressCallback = () => {}, cancelTokenSource = {}) => {
    const formData = new FormData();
    if (params)
        Object.keys(params).forEach(key => {
            if (params[key]) formData.append(key, params[key]);
        });
    formData.append(file.name, file);

    return sendRequest(
        path,
        'post',
        formData,
        handleResponseUpload(progressCallback),
        handleErrorUpload,
        progressCallback,
        cancelTokenSource
    );
};

export { get, post, put, remove, upload, getImageBlob, getBlob };
