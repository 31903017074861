/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import { isNumber } from 'lodash';
import { channel } from 'redux-saga';
import { all, call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import {
    deletePrediction,
    getGalleryImagePrediction,
    getImageDemoGallery,
    getPredictions,
    predictImageDemo,
    repredictImage,
} from '../../api';
import {
    IMAGE_DEMO_UPLOADS,
    IMAGE_FACIAL_RECOGNITION_UPLOADS,
    IMAGE_LOGO_DETECTION_UPLOADS,
} from '../../routes/routes';
import { SAGA_WORKERS } from '../../utils/config';
import { dialogTypes, predictionTypes, STATIC_PATH, UPLOADS_PATH } from '../../utils/constants';
import { localStorageFacade } from '../../utils/localStorage';
import { actions as imageActions } from '../actions';
import {
    DELETE_PREDICTION_CONFIRMED,
    DELETE_PREDICTION_REC,
    DEMO_THRESHOLD_POPUP,
    GET_GALERY,
    GET_UPLOADS,
    imageDemoPrefixes,
    PREDICT_GALLERY_IMAGE,
    REPREDICT_UPLOAD_IMAGE,
    SHOW_CONCLUSION_DIALOG,
    SHOW_TUTORIAL,
    UPLOAD_IMAGE_DEMO_IMAGES,
    UPLOAD_IMAGE_REQUEST,
} from '../actions/actionTypes/imageDemo';
import { addCurrentUserUpload } from '../actions/auth';
import { showDialog } from '../actions/dialog';

const wrapAction = (acion, prefix) => {
    return `${prefix}_${acion}`;
};

const predictionFactory = (
    type,
    imageUploadType,
    galleryUploadType,
    actions,
    localStorageGallery,
    getStoredUploads,
    getStoredGallery,
    uploadsPagePathname
) => {
    const DEMO_THRESHOLD_POPUP_ACTION = wrapAction(DEMO_THRESHOLD_POPUP, type);
    const GET_GALERY_ACTION = wrapAction(GET_GALERY, type);
    const GET_UPLOADS_ACTION = wrapAction(GET_UPLOADS, type);
    const PREDICT_GALLERY_IMAGE_ACTION = wrapAction(PREDICT_GALLERY_IMAGE, type);
    const UPLOAD_IMAGE_DEMO_IMAGES_ACTION = wrapAction(UPLOAD_IMAGE_DEMO_IMAGES, type);
    const UPLOAD_IMAGE_REQUEST_ACTION = wrapAction(UPLOAD_IMAGE_REQUEST, type);
    const REPREDICT_UPLOAD_IMAGE_ACTION = wrapAction(REPREDICT_UPLOAD_IMAGE, type);
    const DELETE_PREDICTION_REC_ACTION = wrapAction(DELETE_PREDICTION_REC, type);
    const DELETE_PREDICTION_CONFIRMED_ACTION = wrapAction(DELETE_PREDICTION_CONFIRMED, type);
    const SHOW_TUTORIAL_ACTION = wrapAction(SHOW_TUTORIAL, type);
    const SHOW_CONCLUSION_DIALOG_ACTION = wrapAction(SHOW_CONCLUSION_DIALOG, type);

    function* setGallery(resGallery) {
        const gallery = {};
        yield Object.keys(resGallery).forEach(key => {
            gallery[key] = resGallery[key].map(image => ({
                id: image.id,
                name: image.path,
                folder: key,
                src: `${STATIC_PATH}/${key}/${image.path}`,
                predict: image.prediction,
                predictionType: galleryUploadType,
            }));
        });

        localStorageGallery.set(gallery);
        yield put(actions.updateImageDemoGallery(gallery));
    }

    function* getUploads() {
        try {
            const uploads = yield call(() => getPredictions(imageUploadType));
            const updatedUploads = uploads.map(upload => ({
                ...upload,
                date: Number(new Date(upload.date)),
                src: `${UPLOADS_PATH}/${upload.src}`,
                predictionType: imageUploadType,
            }));
            yield put(actions.setImageDemoUploads(updatedUploads));
        } catch (e) {
            console.error(`imageDemo.getUploads has failed with error: ${e}`);
        }
    }

    function* isVisible() {
        if (type === imageDemoPrefixes.tagging) {
            return yield select(state => state.app.config.features.imageDemo);
        }
        if (type === imageDemoPrefixes.faceRecognition) {
            return yield select(state => state.app.config.features.imageFacialRecognition);
        }
        return false;
    }

    function* getGallery(action) {
        if (yield !call(isVisible)) {
            return;
        }
        const { ignoreCache } = action.payload;
        try {
            const cache = localStorageGallery.get();
            if (cache && !ignoreCache) {
                yield put(actions.updateImageDemoGallery(cache));
            } else {
                let resGallery = yield call(() => getImageDemoGallery(galleryUploadType));
                yield setGallery(resGallery);
                resGallery = yield call(() => getImageDemoGallery(galleryUploadType, false));
                yield setGallery(resGallery);
            }
        } catch (e) {
            console.error(`imageDemo.getGallery has failed with error: ${e}`);
        }
    }

    function* predictGalleryImage(action) {
        const {
            payload: { folder, name },
        } = action;
        try {
            yield put(actions.updateImageDemoGalleryItem(folder, name, null, galleryUploadType));
            const prediction = yield call(
                getGalleryImagePrediction,
                folder,
                name,
                galleryUploadType
            );
            yield put(
                actions.updateImageDemoGalleryItem(folder, name, prediction, galleryUploadType)
            );
            const gallery = yield select(getStoredGallery);
            localStorageGallery.set(gallery);
        } catch (e) {
            console.error(`imageDemo.predictGalleryImage has failed with error: ${e}`);
        }
    }

    function* watchPredictGalleryImage() {
        yield takeEvery(PREDICT_GALLERY_IMAGE_ACTION, predictGalleryImage);
    }

    function* repredictUploadImage(action) {
        const {
            payload: { id },
        } = action;
        try {
            yield put(
                actions.updateImageDemoUploads(null, upload => ({ ...upload, predict: null }), id)
            );
            const prediction = yield call(repredictImage, id);
            yield put(
                actions.updateImageDemoUploads(
                    null,
                    upload => ({ ...upload, predict: prediction }),
                    id
                )
            );
        } catch (e) {
            console.error(`imageDemo.repredictUploadImage has failed with error: ${e}`);
        }
    }

    function* watchRepredictUploadImage() {
        yield takeEvery(REPREDICT_UPLOAD_IMAGE_ACTION, repredictUploadImage);
    }

    function* uploadImages(chan, { dispatch }) {
        const progressHandler = (index, cancelRequestSource) => async value => {
            if (!cancelRequestSource.status.isCanceled) {
                dispatch(
                    actions.updateImageDemoUploads(index, upload => ({
                        ...upload,
                        progress: value,
                    }))
                );
            }
        };

        while (true) {
            const { file, i, cancelRequestSource = {} } = yield take(chan);

            try {
                const { id, prediction, image } = yield call(
                    predictImageDemo,
                    { data: file },
                    imageUploadType,
                    progressHandler(i, cancelRequestSource)
                );
                if (!cancelRequestSource.status.isCanceled) {
                    yield put(
                        actions.updateImageDemoUploads(i, upload => ({
                            ...upload,
                            id,
                            predict: prediction,
                            src: `${UPLOADS_PATH}/${image}`,
                            predictionType: imageUploadType,
                        }))
                    );
                    yield put(addCurrentUserUpload());
                } else {
                    yield call(deletePrediction, id);
                }
            } catch (e) {
                if (!axios.isCancel(e)) {
                    yield put(
                        showDialog({
                            type: 'error',
                            action: {},
                            actionParams: {
                                message: `Prediction on ${file.name} has failed. ${e.message}`,
                            },
                        })
                    );
                    console.error(e);
                }

                yield put(actions.updateImageDemoUploads(i, () => null));
            }
        }
    }

    function* watchGetGallery() {
        while (true) {
            const params = yield take(GET_GALERY_ACTION);
            yield call(getGallery, params);
        }
    }

    function* watchGetUploads() {
        while (true) {
            const params = yield take(GET_UPLOADS_ACTION);
            yield call(getUploads, params);
        }
    }

    function* watchUploadDemoImages(params) {
        // create a channel to queue incoming requests
        const chan = yield call(channel);

        // create upload worker "threads"
        const workersNum = SAGA_WORKERS;
        for (let i = 0; i < workersNum; i++) {
            yield fork(uploadImages, chan, params);
        }

        while (true) {
            const { payload } = yield take(UPLOAD_IMAGE_DEMO_IMAGES_ACTION);

            const uploads = yield select(getStoredUploads);
            const user = yield select(state => state.auth.currentUser);

            if (
                isNumber(user.uploadImagesMax) &&
                user.uploadImagesCounter >= user.uploadImagesMax
            ) {
                return;
            }
            const images = isNumber(user.uploadImagesMax)
                ? payload.images.slice(0, user.uploadImagesMax - user.uploadImagesCounter)
                : payload.images;
            let index = 0;
            const failedImages = [];
            const n = uploads.length;
            // eslint-disable-next-line no-unused-vars
            for (const [_, file] of images.entries()) {
                try {
                    const cancelRequestSourceBuilder = i => {
                        const status = {
                            isCanceled: false,
                        };
                        return {
                            cancel: () => {
                                status.isCanceled = true;
                                params.dispatch(actions.updateImageDemoUploads(i, () => null));
                            },
                            status,
                        };
                    };

                    const cancelRequestSource = cancelRequestSourceBuilder(n + index);

                    const withSameName = uploads.reduce((acc, upl) => {
                        return acc + (upl.name.includes(file.name) ? 1 : 0);
                    }, 0);

                    yield put(
                        actions.updateImageDemoUploads(n + index, upload => ({
                            ...upload,
                            name: `${withSameName ? `${withSameName.toString()}-` : ''}${
                                file.name
                            }`,
                            path: file.path,
                            date: Date.now(),
                            progress: 0,
                            cancelRequestSource,
                            predictionType: imageUploadType,
                        }))
                    );
                    yield put(chan, { file, i: n + index, cancelRequestSource });
                    index += 1;
                } catch (e) {
                    failedImages.push(file.name);
                    console.error(e);
                }
            }
            if (failedImages.length > 0) {
                const message = `Image${failedImages.length > 1 ? 's' : ''} ${failedImages.join(
                    ', '
                )} could not be uploaded. Please try again.`;
                yield put(
                    showDialog({
                        type: 'error',
                        action: {},
                        actionParams: {
                            message,
                        },
                    })
                );
            }
        }
    }

    function* watchUploadImageRequest({ history }) {
        while (true) {
            yield take(UPLOAD_IMAGE_REQUEST_ACTION);
            history.push(uploadsPagePathname);
            yield put(showDialog({ type: 'demo_upload', action: actions.uploadImageDemoImages }));
        }
    }

    function* watchThresholdInfoPopup() {
        while (true) {
            yield take(DEMO_THRESHOLD_POPUP_ACTION);
            yield put(showDialog({ type: 'demo_threshold_info' }));
        }
    }

    function* watchShowTutorialDialog() {
        while (true) {
            yield take(SHOW_TUTORIAL_ACTION);
            yield put(showDialog({ type: 'tutorial_dialog' }));
        }
    }

    function* watchShowConclusionDialog() {
        while (true) {
            yield take(SHOW_CONCLUSION_DIALOG_ACTION);
            yield put(showDialog({ type: 'conclusion_dialog' }));
        }
    }

    function* watchDeletePredictionConfirmed() {
        while (true) {
            const {
                params: { id },
            } = yield take(DELETE_PREDICTION_CONFIRMED_ACTION);
            try {
                yield call(deletePrediction, id);
                yield call(getUploads);
            } catch (e) {
                yield put(
                    showDialog({
                        type: dialogTypes.error,
                        action: {},
                        actionParams: {
                            message: 'Removing prediction has failed',
                        },
                    })
                );
            }
        }
    }

    function* watchDeletePrediction() {
        while (true) {
            const { payload } = yield take(DELETE_PREDICTION_REC_ACTION);

            yield put(
                showDialog({
                    type: dialogTypes.predictionDeleteConfirmation,
                    action: { type: DELETE_PREDICTION_CONFIRMED_ACTION },
                    actionParams: payload,
                })
            );
        }
    }

    return {
        sagas: params =>
            all([
                watchGetGallery(),
                watchUploadDemoImages(params),
                watchUploadImageRequest(params),
                watchThresholdInfoPopup(),
                watchPredictGalleryImage(),
                watchGetUploads(),
                watchRepredictUploadImage(),
                watchDeletePrediction(),
                watchDeletePredictionConfirmed(),
                watchShowTutorialDialog(),
                watchShowConclusionDialog(),
            ]),
    };
};

export const imageTaggingSaga = predictionFactory(
    imageDemoPrefixes.tagging,
    predictionTypes.uploadedImage,
    predictionTypes.galleryImage,
    imageActions.imageTaggingActions,
    localStorageFacade.taggingGallery,
    state => state.imageDemo.tagging.uploads,
    state => state.imageDemo.tagging.gallery,
    IMAGE_DEMO_UPLOADS.pathname
);
export const imageFaceRecognitionSaga = predictionFactory(
    imageDemoPrefixes.faceRecognition,
    predictionTypes.faceRecognitionImage,
    predictionTypes.faceRecognitionGalleryImage,
    imageActions.imageFaceRecognitionActions,
    localStorageFacade.faceRecognitionGallery,
    state => state.imageDemo.faceRecognition.uploads,
    state => state.imageDemo.faceRecognition.gallery,
    IMAGE_FACIAL_RECOGNITION_UPLOADS.pathname
);
export const imageLogoDetectionSaga = predictionFactory(
    imageDemoPrefixes.logoDetection,
    predictionTypes.logoDetectionImage,
    predictionTypes.logoDetectionGalleryImage,
    imageActions.imageLogoDetectionActions,
    localStorageFacade.logoDetectionGallery,
    state => state.imageDemo.logoDetection.uploads,
    state => state.imageDemo.logoDetection.gallery,
    IMAGE_LOGO_DETECTION_UPLOADS.pathname
);
