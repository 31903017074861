import {
    ADD_CURRENT_USER_UPLOAD,
    AUTH_CHECK_REQ,
    AUTH_GOOGLE_LOGIN_REQ,
    AUTH_LOGIN_REQ,
    AUTH_LOGOUT_REQ,
    AUTH_SET_TOKENS,
    ONBOARD_USER,
    SET_CONSENT,
    UPDATE_CONSENT,
} from './actionTypes/auth';

export const logout = () => ({ type: AUTH_LOGOUT_REQ });

export const login = (username, password) => ({
    type: AUTH_LOGIN_REQ,
    username,
    password,
});
export const checkAuth = () => ({ type: AUTH_CHECK_REQ });

export const addCurrentUserUpload = () => ({ type: ADD_CURRENT_USER_UPLOAD });
export const setConsent = () => ({ type: SET_CONSENT });
export const updateConsent = () => ({ type: UPDATE_CONSENT });
export const onboardUser = payload => ({ type: ONBOARD_USER, payload });
export const setTokens = (accessToken, refreshToken) => ({
    type: AUTH_SET_TOKENS,
    payload: {
        accessToken,
        refreshToken,
    },
});
export const googleLoginRequest = () => ({ type: AUTH_GOOGLE_LOGIN_REQ });

export const actions = {
    login,
    logout,
    checkAuth,
    addCurrentUserUpload,
    setConsent,
    onboardUser,
    setTokens,
    googleLoginRequest,
};
