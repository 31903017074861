import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Error';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogParams } from '../../utils/commonTypes';

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        maxWidth: '500px',
        margin: theme.spacing(4),
    },

    bodyContainer: {
        padding: theme.spacing(1, 0),
    },

    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(0, 2),
    },
}));

type Props = {
    messageTranslationPath: string;
} & DialogParams;

export function BaseDeleteConfirmation(props: Props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { onClose, action, actionParams, messageTranslationPath } = props;
    const handleCancel = () => {
        onClose();
    };

    const handleConfirm = () => {
        onClose(action, actionParams);
    };

    return (
        <div className={classes.dialogContainer}>
            <div>
                <InfoIcon />
            </div>
            <div className={classes.bodyContainer}>
                <Typography variant="body2">{t(messageTranslationPath)}</Typography>
            </div>
            <div className={classes.buttonContainer}>
                <Button className={classes.button} variant="outlined" onClick={handleCancel}>
                    {t('common.cancel')}
                </Button>
                <Button
                    data-test="delete-confirmation"
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={handleConfirm}
                >
                    {t('common.delete')}
                </Button>
            </div>
        </div>
    );
}
