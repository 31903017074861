import { groupBy, memoize } from 'lodash';
import { createSelector } from 'reselect';

export const fetchAllImages = state => {
    return state.entities.images;
};

const fetchAllImagesOrEmptyArray = createSelector(fetchAllImages, (images = []) => {
    return images;
});

const fetchImages = createSelector(fetchAllImages, (images = []) =>
    memoize(
        (id, folderId) => {
            return Object.keys(images)
                .map(key => images[key])
                .filter(image => image.folderId === folderId && image.concept_id === id);
        },
        (_, folderId) => folderId
    )
);

const uploadedImages = createSelector(fetchAllImages, (images = []) =>
    memoize(
        id =>
            groupBy(
                Object.keys(images)
                    .map(key => images[key])
                    .filter(image => Number(image.concept_id) === id),
                'folderId'
            ),
        id => id
    )
);

export const getUploadQueue = () => state => {
    return state.upload.queue;
};

export const getUploadResult = () => state => {
    return state.upload.uploadResult;
};

export const selectors = {
    fetchImages,
    getUploadQueue,
    fetchAllImages,
    fetchAllImagesOrEmptyArray,
    getUploadResult,
    uploadedImages,
};
