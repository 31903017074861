import {
    AccountTree,
    BarChart,
    CameraEnhance,
    CopyrightOutlined,
    Crop169Outlined,
    Dashboard,
    EmojiTransportation,
    GraphicEq,
    Group,
    Image,
    PanoramaOutlined,
    Print,
    Public,
    Search,
    Theaters,
    VerifiedUser,
    Videocam,
} from '@material-ui/icons';
import {
    AUDIO_PROFANITY_DETECTION,
    AUDIO_SPEECH_TO_TEXT,
    AUDIO_TAGGING,
    CONCEPTS,
    CUSTOM_OBJECT_DETECTION,
    DASHBOARD,
    EARTH_OBSERVATION,
    IMAGE_DEMO,
    IMAGE_FACIAL_RECOGNITION,
    IMAGE_LOGO_DETECTION,
    INDUSTRY_I2P,
    INDUSTRY_IMAGE_ARCHIVES,
    INDUSTRY_PRESS,
    LOGO_DETECTION_ADMIN_PAGE,
    OBJECT_SEGMENTATION,
    SEARCH,
    SEARCH_ADMIN,
    USER,
    VIDEO_FACIAL_RECOGNITION,
    VIDEO_HIGHLIGHTS,
    VIDEO_PROFANITY_DETECTION,
    VIDEO_SHOT_DETECTION,
    VIDEO_TAGGING,
} from '../../routes/routes';
import { AppConfig } from '../../utils/commonTypes';
import { featureAccess } from '../../utils/constants';

export interface MenuItem {
    key: string;
    dataTest: string;
    label: any;
    path?: string;
    isVisible: boolean;
}

interface SidebarMenuItem extends MenuItem {
    items?: MenuItem[];
    icon: React.ReactNode;
}

export const FeatureLists = (config: AppConfig): SidebarMenuItem[] => [
    {
        key: 'dashboard',
        label: 'Dashboard',
        dataTest: 'left-menu-dashboard',
        icon: Dashboard,
        isVisible: DASHBOARD.getVisibility(config),
        path: DASHBOARD.pathname,
    },
    {
        key: 'user-management',
        dataTest: `left-menu-user`,
        isVisible: USER.getVisibility(config),
        label: 'User Management',
        icon: Group,
        path: USER.pathname,
    },
    {
        key: 'search-admin',
        label: 'Admin Search',
        icon: Search,
        dataTest: `left-menu-search-admin`,
        isVisible: SEARCH_ADMIN.getVisibility(config),
        path: SEARCH_ADMIN.pathname,
    },
    {
        key: 'logo-admin',
        label: 'Logo Admin',
        icon: Crop169Outlined,
        dataTest: `left-menu-logo-admin`,
        isVisible: LOGO_DETECTION_ADMIN_PAGE.getVisibility(config),
        path: LOGO_DETECTION_ADMIN_PAGE.pathname,
    },

    {
        key: 'image-search',
        label: 'Image',
        isVisible: true,
        dataTest: 'left-menu-image',
        icon: Image,
        items: [
            {
                key: 'image-search',
                dataTest: `left-menu-search`,
                isVisible: SEARCH.getVisibility(config),
                label: <img src="/assets/brandedLogos/search.svg" alt="search" />,
                path: SEARCH.pathname,
            },
            {
                key: 'image-concepts',
                dataTest: `left-menu-${featureAccess.cti}`,
                isVisible: CONCEPTS.getVisibility(config),
                path: CONCEPTS.pathname,
                label: (
                    <img
                        src="/assets/brandedLogos/Product Wordmarks_not outlines_concepts.svg"
                        alt="concepts"
                    />
                ),
            },

            {
                key: 'image-tagging',
                dataTest: `left-menu-${featureAccess.imageDemo}`,
                isVisible: IMAGE_DEMO.getVisibility(config),
                path: IMAGE_DEMO.pathname,
                label: (
                    <img
                        src="/assets/brandedLogos/Product Wordmarks_not outlines_tagging.svg"
                        alt="tagging"
                    />
                ),
            },
            {
                key: 'image-id',
                dataTest: `left-menu-${featureAccess.imageFacialRecognition}`,
                isVisible: IMAGE_FACIAL_RECOGNITION.getVisibility(config),
                path: IMAGE_FACIAL_RECOGNITION.pathname,
                label: (
                    <img
                        src="/assets/brandedLogos/Product Wordmarks_not outlines_id.svg"
                        alt="recognition"
                    />
                ),
            },
            {
                key: 'logo-detection',
                dataTest: `left-menu-${featureAccess.logoDetection}`,
                isVisible: IMAGE_LOGO_DETECTION.getVisibility(config),
                path: IMAGE_LOGO_DETECTION.pathname,
                label: <img src="/assets/brandedLogos/logo_detection.svg" alt="mobius logo" />,
            },
        ],
    },
    {
        label: 'Video',
        key: 'highlights',
        isVisible: true,
        dataTest: `left-menu-video`,
        icon: Theaters,
        items: [
            {
                key: 'highlights',
                dataTest: `left-menu-${featureAccess.videoHighlights}`,
                isVisible: VIDEO_HIGHLIGHTS.getVisibility(config),
                path: VIDEO_HIGHLIGHTS.pathname,
                label: (
                    <img
                        src="/assets/brandedLogos/Product Wordmarks_not outlines_highlight.svg"
                        alt="video highlights"
                    />
                ),
            },
            {
                key: 'shot',
                dataTest: `left-menu-${featureAccess.videoShotDetection}`,
                isVisible: VIDEO_SHOT_DETECTION.getVisibility(config),
                path: VIDEO_SHOT_DETECTION.pathname,
                label: (
                    <img
                        src="/assets/brandedLogos/Product Wordmarks_not outlines_shot.svg"
                        alt="video shot detection"
                    />
                ),
            },
            {
                key: 'video-tagging',
                dataTest: `left-menu-${featureAccess.videoTagging}`,
                isVisible: VIDEO_TAGGING.getVisibility(config),
                path: VIDEO_TAGGING.pathname,
                label: (
                    <img
                        src="/assets/brandedLogos/Product Wordmarks_not outlines_tagging.svg"
                        alt="video tagging"
                    />
                ),
            },
            {
                key: 'video-profanity',
                dataTest: `left-menu-${featureAccess.videoProfanityDetection}`,
                isVisible: false, // VIDEO_PROFANITY_DETECTION.getVisibility(config),
                path: VIDEO_PROFANITY_DETECTION.pathname,
                label: (
                    <>
                        <strong>Mobius</strong>profanity
                    </>
                ),
            },

            {
                key: 'video-id',
                dataTest: `left-menu-${featureAccess.videoFacialRecognition}`,
                isVisible: VIDEO_FACIAL_RECOGNITION.getVisibility(config),
                path: VIDEO_FACIAL_RECOGNITION.pathname,
                label: (
                    <img
                        src="/assets/brandedLogos/Product Wordmarks_not outlines_id.svg"
                        alt="video recognition "
                    />
                ),
            },
        ],
    },

    {
        key: 'audio-tagging',
        label: 'Audio',
        icon: GraphicEq,
        dataTest: `left-menu-audio`,
        isVisible: true,
        items: [
            {
                key: 'audio-tagging',
                dataTest: `left-menu-${featureAccess.audioTagging}`,
                isVisible: AUDIO_TAGGING.getVisibility(config),
                path: AUDIO_TAGGING.pathname,
                label: (
                    <img
                        src="/assets/brandedLogos/Product Wordmarks_not outlines_tagging.svg"
                        alt="audio tagging"
                    />
                ),
            },
            {
                key: 'audio-speech-to-text',
                dataTest: `left-menu-${featureAccess.audioSpeechToText}`,
                isVisible: false, //AUDIO_SPEECH_TO_TEXT.getVisibility(config),
                path: AUDIO_SPEECH_TO_TEXT.pathname,
                label: (
                    <>
                        <strong>Mobius</strong>Speech
                    </>
                ),
            },
            {
                key: 'audio-profanity',
                dataTest: `left-menu-${featureAccess.audioProfanityDetection}`,
                isVisible: false, // AUDIO_PROFANITY_DETECTION.getVisibility(config),
                path: AUDIO_PROFANITY_DETECTION.pathname,
                label: (
                    <>
                        <strong>Mobius</strong>Profanity
                    </>
                ),
            },
        ],
    },
    {
        key: 'detect',
        label: 'Object',
        icon: AccountTree,
        dataTest: `left-menu-object`,
        isVisible: true,
        items: [
            {
                key: 'detect',
                dataTest: `left-menu-${featureAccess.objectDetection}`,
                isVisible: CUSTOM_OBJECT_DETECTION.getVisibility(config),
                path: CUSTOM_OBJECT_DETECTION.pathname,
                label: (
                    <img
                        src="/assets/brandedLogos/Product Wordmarks_not outlines_detect.svg"
                        alt="object detect"
                    />
                ),
            },
            {
                key: 'segment',
                dataTest: `left-menu-${featureAccess.objectSegmentation}`,
                isVisible: OBJECT_SEGMENTATION.getVisibility(config),
                path: OBJECT_SEGMENTATION.pathname,
                label: (
                    <img
                        src="/assets/brandedLogos/Product Wordmarks_not outlines_segment.svg"
                        alt="object segment"
                    />
                ),
            },
        ],
    },
];

export const ByUsecaseLists = (config: any): any => [
    {
        label: 'Earth Observation',
        key: 'earth-observation',
        icon: Public,
        path: EARTH_OBSERVATION.pathname,
        isVisible: EARTH_OBSERVATION.getVisibility(config),
    },

    {
        label: 'Press & Broadcasting',
        key: 'press-broadcasting',
        icon: PanoramaOutlined,
        path: INDUSTRY_PRESS.pathname,
        isVisible: INDUSTRY_PRESS.getVisibility(config),
    },

    {
        label: 'Stock Photography',
        key: 'stock-photography',
        icon: CameraEnhance,
        path: INDUSTRY_IMAGE_ARCHIVES.pathname,
        isVisible: INDUSTRY_IMAGE_ARCHIVES.getVisibility(config),
    },
    {
        label: 'Printing Services',
        key: 'printing-services',
        icon: Print,
        path: INDUSTRY_I2P.pathname,
        isVisible: INDUSTRY_I2P.getVisibility(config),
    },
    {
        label: 'Creative Platforms',
        key: 'creative-platforms',
        icon: CopyrightOutlined,
        isVisible: false,
    },
    {
        label: 'Asset Management',
        key: 'asset-management',
        icon: BarChart,
        isVisible: false,
    },
    {
        label: 'Video Services',
        key: 'video-services',
        icon: Videocam,
        isVisible: false,
    },
    {
        label: 'Insurance',
        key: 'insurance',
        icon: VerifiedUser,
        isVisible: false,
    },
    {
        label: 'Industrial & Manufacturing',
        key: 'industrial-manufacturing',
        icon: EmojiTransportation,
        isVisible: false,
    },
];
