const { isEmpty } = require('lodash');

const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const isEmail = text => {
    const preparedValue = text.toLowerCase().trim();
    return Boolean(preparedValue && emailRegex.test(preparedValue));
};
const isNotEmpty = text => {
    return !isEmpty(text);
};
const minLength = (text, min) => {
    return isNotEmpty(text) && text.length >= min;
};

export { isEmail, isNotEmpty, minLength };
