export const loadJSAsync = (url: string) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.onload = () => {
            resolve(undefined);
        };
        script.onerror = () => {
            reject();
        };
        document.head.appendChild(script);
    });
};
