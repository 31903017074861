import {
    DetectClass,
    DetectImage,
    DetectModelType,
    DetectObject,
    ModelStatus,
} from '../types/detection';

import { BoundingBox, ConceptInfo, User } from '../utils/commonTypes';
import {
    CONCEPTS_MAX_SEARCH_RESULTS,
    copyFeatureAccessFields,
    predictionTypes,
} from '../utils/constants';
import { API_HOST, get, getBlob, getImageBlob, post, put, remove, upload } from './apiHelper';

type conceptId = {
    id: number;
};

export const login = ({ username, password }: { username: string; password: string }) =>
    post('/login', { username, password });
export const logout = () => get('/logout');

export const getCurrentUser = () => get('/user/current');

export const fetchClassifiers = () => get('/concept/list');

export const createClassifier = (params: { name: string }) => post('/concept', params);
export const removeClassifier = ({ id }: conceptId) => remove(`/concept/${id}`);
export const lockClassifier = ({ id }: conceptId) => get(`/concept/${id}/lock`);
export const sendThresholdClassifier = ({
    id,
    updatedThreshold,
}: {
    id: number;
    updatedThreshold: number;
}) => post(`/concept/${id}/threshold`, { threshold: updatedThreshold });

export const trainClassifier = ({ id }: conceptId) => get(`/concept/${id}/train?type=validation`);
export const testClassifier = ({ id }: conceptId) => get(`/concept/${id}/predict?type=test`);

export const predictClassifier = ({ id }: conceptId) => get(`/concept/${id}/predict`);

export const fetchTaskStatus = ({ taskId }: { taskId: string }) => get(`/concept/status/${taskId}`);

export const uploadImage = (
    { id, folderId, file }: { id: number; folderId: string; file: object },
    progressCallback: () => {}
) => {
    return upload(`/concept/${id}/image`, file, { folderId }, progressCallback);
};

export const rateImage = ({
    id,
    filename,
    mode,
}: {
    id: number;
    filename: string;
    mode: string;
}) => {
    return post(`/concept/${id}/image/${filename}/change_selection`, { type: mode });
};

export const moveImage = ({
    id,
    filename,
    moveTarget,
}: {
    id: number;
    filename: string;
    moveTarget: string;
}) => {
    return post(`/concept/${id}/image/${filename}/move`, { type: moveTarget });
};
export const deleteImage = ({ id, filename }: { id: number; filename: string }) => {
    return remove(`/concept/${id}/image/${filename}`);
};

export const getImageDemoGallery = (predictionType: predictionTypes, skipPrediction = true) => {
    return get('/image/gallery', { skipPrediction, predictionType });
};

export const getPredictions = (predictionType: predictionTypes) => {
    return get('/prediction/uploads', { predictionType });
};

export const getPredictionsTasks = (predictionType: predictionTypes) => {
    return get('/prediction/uploads_tasks', { predictionType });
};

export const deletePrediction = (id: number) => {
    return remove(`/prediction/${id}`);
};

export const setConsent = () => {
    return post('/user/set_consent');
};

export const getGalleryImagePrediction = (
    folder: string,
    name: string,
    predictionType: predictionTypes
) => {
    return post('/image/predict/gallery', {
        folder,
        name,
        predictionType,
        params: {},
    });
};

export const repredictImage = (id: number) => {
    return post(`/image/predict/${id}`, {
        id,
        params: {},
    });
};

export const predictImageDemo = (
    params: { data: object },
    predictionType: predictionTypes,
    progressCallback = () => {},
    cancelTokenSource = {}
) => {
    return upload(
        '/image/predict',
        params.data,
        { predictionType },
        progressCallback,
        cancelTokenSource
    );
};

export const getAdminUserList = (filters: object) => {
    return post('/user/list', filters);
};

export const getAdminUserCount = (filters: object) => {
    return post('/user/count', filters);
};

export const getUser = (id: number) => {
    return get(`/user/${id}`);
};

export const getUserInfo = () => {
    return get('/user/current');
};

export const createUser = (user: User & { password: string }) => {
    return post('/user/', {
        username: user.username,
        password: user.password,
        role: user.role,
        uploadImagesMax: user.uploadImagesMax || null,
        validUntil: user.validUntil ? JSON.stringify(user.validUntil) : null,
        isPrivate: user.isPrivate,
        ...copyFeatureAccessFields(user),
    });
};

export const updateUser = (user: User) => {
    return put('/user/', {
        id: user.id,
        role: user.role,
        uploadImagesMax: user.uploadImagesMax || null,
        validUntil: user.validUntil ? JSON.stringify(user.validUntil) : null,
        isPrivate: user.isPrivate,
        ...copyFeatureAccessFields(user),
    });
};

export const deactivateUser = (id: number) => {
    return get(`/user/${id}/deactivate`, {});
};

export const onboardUser = ({ type, step }: { type: string; step: string }) => {
    return post('/user/onboarding', {
        type,
        step,
    });
};

export const createImagesShare = (predictionType: predictionTypes) => {
    return post('/share/images/create', {
        predictionType,
    });
};

export const createVideosShare = ({
    predictionType,
    predictionIds,
}: {
    predictionType: string;
    predictionIds?: number[];
}) => {
    return post('/share/videos/create', {
        predictionType,
        predictionIds,
    });
};

export const getSharedImageDemoPredictions = (shareUid: string): Promise<any> => {
    return get(`/share/images/${shareUid}`);
};

export const getSharedVideosPredictions = (shareUid: string): Promise<any> => {
    return get(`/share/videos/${shareUid}`);
};

export const sendShareLinkEmail = (email: string, link: string) => {
    return post('/share/send_email', { email, link });
};

export const sendEmailConfirmation = (confirmationId: string) => {
    return post('/auth/confirm_email', { confirmation_id: confirmationId });
};

export const resendEmailConfirmation = (username: string) => {
    const frontendUrl = window.location.origin;
    return post('/auth/email_confirmation_resend', { username, frontendUrl });
};

export const registerUser = (formData: object) => {
    const frontendUrl = window.location.origin;
    return post('/auth/register', { ...formData, frontendUrl });
};

export const sendForgotPasswordRequest = (username: string) => {
    const frontendUrl = window.location.origin;
    return post('/auth/forgot_password', { username, frontendUrl });
};

export const changePassword = (id: string, password: string) => {
    return post('/auth/forgot_password/reset', { reset_id: id, password });
};

export const logging = (severity: string, data: any) => {
    return post('/logging', { severity, data });
};

export const getBase64Image = (url: string) => {
    return getImageBlob(url);
};

export const getFile = (url: string) => {
    return getBlob(url);
};

export const saveFaceIdentity = (data: object) => {
    return post('/identity/add', {
        data,
    });
};

export const removeFaceIdentity = (data: object) => {
    return post('/identity/delete', {
        data,
    });
};

export const getVideoGallery = (predictionType: predictionTypes) => {
    return get('/video/predict/gallery', { predictionType });
};

export const predictVideo = (
    file: FileList,
    predictionType: predictionTypes,
    progressCallback: () => {},
    cancelTokenSource: () => {}
) => {
    return upload(`/video/predict`, file, { predictionType }, progressCallback, cancelTokenSource);
};

export const searchImages = (query?: string, top = CONCEPTS_MAX_SEARCH_RESULTS) => {
    return post(`/search`, { query, top });
};

export const conceptSearchImages = (
    conceptId: number,
    query?: string,
    top = CONCEPTS_MAX_SEARCH_RESULTS
) => {
    return post(`/search/classifier/${conceptId}`, { query, top });
};

export const assignSearchImages = (conceptId: number, folder: string, imageIds: number[]) => {
    return post(`/search/classifier/${conceptId}/assign`, {
        folder,
        images: imageIds,
    });
};

export const uploadSearchImage = (
    params: { data: object },
    progressCallback = () => {},
    cancelTokenSource = {}
) => {
    return upload('/search/image', params.data, {}, progressCallback, cancelTokenSource);
};

export const removeSearchImages = (filenames: string[]) => {
    return post(`/search/image/delete`, { images: filenames });
};

export const trainSearch = () => {
    return post('/search/train', {});
};

export const getTaskStatus = (taskId: string) => {
    return get(`/task/status/${taskId}`);
};

export const segmentThumbnailSrc = (
    taskId: string,
    segmentId: number,
    segmentType: 'subsegment' | 'segment' = 'segment'
) => {
    return `${API_HOST}/video/${taskId}/${segmentType}/${segmentId}/thumbnail`;
};

export const getGoogleLoginUrl = () => {
    return get('/auth/google/url');
};

export const getGoogleLoginToken = (url: string, state: string) => {
    return get('/auth/google/token', { url, state });
};

export const registerGoogleUser = (model: {
    jwt: string;
    firstName: string;
    lastName: string;
    company: string;
    industry: string;
    solutions: string[];
    agreedDataProtectionPolicy: boolean;
}) => {
    return post('/auth/google/register', model);
};

export const getConcept = (id: number): Promise<ConceptInfo> => get(`/v2/concept/${id}/info`);

// MobiusDetect API
export const getModels = (): Promise<DetectModelType[]> => get(`/detection/models`);

export const createModel = (params: {
    name: string;
    input_size: number;
    model_source?: string;
    unlabeled_set?: string;
}) => post('/detection/models', params);

export const getModel = (id: number): Promise<DetectModelType> => get(`/detection/models/${id}`);

export const deleteModel = (id: number): Promise<DetectModelType> =>
    remove(`/detection/models/${id}`);

export const getModelImages = async (
    id: number,
    image_type?: 'test' | 'train'
): Promise<DetectImage[]> => await get(`/detection/models/${id}/images`, { image_type });

export const getModelClasses = (id: number): Promise<DetectClass[]> =>
    get(`/detection/models/${id}/classes`);

export const updateModelClass = (params: {
    detectionClass: { name: string; id: number; confidence_threshold?: number };
    modelId: number;
    classId: number;
}) => put(`/detection/models/${params.modelId}/classes/${params.classId}`, params.detectionClass);

export const deleteModelClass = (params: {
    modelId: number;
    classId: number;
}): Promise<DetectModelType> =>
    remove(`/detection/models/${params.modelId}/classes/${params.classId}`);

export const createModelClass = (params: { detectionClass: { name: string }; modelId: number }) => {
    return post(`/detection/models/${params.modelId}/classes`, params.detectionClass);
};

export const getModelImage = (id: number, imageId: number): Promise<DetectImage> =>
    get(`/detection/models/${id}/images/${imageId}`);

export const uploadModelImages = async ({
    modelId,
    imageType,
    file,
}: {
    modelId: number;
    imageType: string;
    file: object;
}) => await upload(`/detection/models/${modelId}/images`, file, { imageType });

export const deleteModelImage = (modelId: number, imageId: string) => {
    return remove(`/detection/models/${modelId}/images/${imageId}`);
};

export const updateModelImage = async (modelId: number, imageId: number, imageType: string) =>
    await put(`/detection/models/${modelId}/images/${imageId}`, {
        image_type: imageType,
    });

export const trainModel = (modelId: number) =>
    post(`/detection/models/${modelId}/train`, {
        training_mode: 'tail',
    });

export const predictModel = (modelId: number) => post(`/detection/models/${modelId}/predict`);

export const predictImage = ({ modelId, imageId }: { modelId: number; imageId: number }) =>
    post(`/detection/models/${modelId}/predict`, {
        detectionImageId: imageId,
    });

export const predictAllImages = ({ modelId, imageType }: { modelId: number; imageType: string }) =>
    post(`/detection/models/${modelId}/predict`, {
        imageType: imageType,
    });

export const getModelStatus = (modelId: number): Promise<ModelStatus> =>
    get(`/detection/models/${modelId}/status`);
export const getAllModelTasks = (modelId: number) => get(`/detection/models/${modelId}/tasks`);
export const getDetectionTaskStatus = (taskId?: string) => get(`/detection/tasks/${taskId}`);

export const updateObjects = async ({
    modelId,
    imageId,
    detectionObjects,
}: {
    modelId: number;
    imageId: number;
    detectionObjects: DetectObject[];
}) => await put(`/detection/models/${modelId}/images/${imageId}/objects`, { detectionObjects });

export const saveLogo = (data: {
    logoId: string;
    predictionId: number;
    boundingBox: BoundingBox;
}) => {
    return post('/logo/add', {
        data,
    });
};

export const removeCurrentUserLogoImage = (data: { imageId: string }) => {
    return post('/logo/delete_logo_image', {
        data,
    });
};

export const removeLogoImage = (data: { groupId: string; imageId: string }) => {
    return post('/logo/admin/delete_logo_image', {
        data,
    });
};

export const removeLogo = (data: { groupId: string; logoId: string }) => {
    return post('/logo/admin/delete_logo', {
        data,
    });
};

export const removeLogoGroup = (data: { groupId: string }) => {
    return post('/logo/admin/delete_group', { data });
};

export const referenceImageSrc = (groupId: string, imageId: string) => {
    return `${API_HOST}/logo/reference/${groupId}/${imageId}`;
};

export const hasReferenceImage = async (groupId: string, imageId: string) => {
    try {
        await get(`/logo/reference/${groupId}/${imageId}`);
        return true;
    } catch {
        return false;
    }
};

export const getLogoGroups = () => {
    return get(`/logo/groups`);
};

export const getGroupInfo = (groupId: string) => {
    return get(`/logo/group/${groupId}`);
};

export const addReferenceImages = async ({
    groupId,
    logoId,
    boundingBox,
    file,
}: {
    groupId: string;
    logoId: string;
    boundingBox: BoundingBox;
    file: File;
}) =>
    await upload(`/logo/reference/${groupId}/${logoId}`, file, {
        boundingBox: JSON.stringify(boundingBox),
    });
