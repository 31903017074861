import { useState } from 'react';
import { useTimeoutFn } from 'react-use';
import { ImageGridItemData } from '../components/ui/grid/ImageGridItemNode';
import { selectors } from '../redux';
import { ConceptState } from '../redux/reducers/concept';
import { getCurrentUser } from '../redux/selectors/auth';
import { AppConfig, ConceptInfo, ReduxState, SearchState, User } from '../utils/commonTypes';
import { useAppSelector } from './redux';

export const useAppReducer = () => useAppSelector(state => state.app as ReduxState['app']);
export const useAppConfig = () => useAppSelector(state => state.app.config as AppConfig);
export const useVideoReducer = () => useAppSelector(state => state.video);
export const useCurrentUser = () => useAppSelector(getCurrentUser as () => User);
export const useLogoDetection = () => useAppSelector(state => state.imageDemo.logoDetection);
const LOADING_TIMEOUT = 3000;

/**
 * Since we don't have a proper `loading` state for some requests,
 * here is a temporary fix. Retuns "loading: true" for the first 3 seconds
 * if list is empty. If it is still empty after 3s return "loading: false",
 * so that "list is empty" could be shown
 */
export const useFakeLoading = (items?: any[]) => {
    const [loading, setState] = useState(true);
    useTimeoutFn(() => setState(false), LOADING_TIMEOUT);
    // if there is any data, always return loaded=false state
    if (items?.length) {
        return { loading: false };
    }
    return { loading };
};
export const useSearchReducer = (): SearchState => useAppSelector(state => state.search);
export const useConceptReducer = (): ConceptState => useAppSelector(state => state.concept);
export const useConcept = (id: number): ConceptInfo => {
    const { concept } = useConceptReducer();
    return concept[id];
};
export const useTasks = () => useAppSelector(state => state.tasks);
export const useUploadedConceptImages = (id: number): { [key: string]: ImageGridItemData[] } =>
    useAppSelector(state => selectors.uploadedImages(state)(id));
