export const UPLOAD_SEARCH_IMAGES = 'UPLOAD_SEARCH_IMAGE';
export const UPLOAD_SEARCH_IMAGES_REQUEST = 'UPLOAD_SEARCH_IMAGES_REQUEST';
export const UPLOAD_SEARCH_IMAGES_COUNT = 'UPLOAD_SEARCH_IMAGES_COUNT';
export const UPLOAD_SEARCH_IMAGE_DONE = 'UPDATE_UPLOAD_SEARCH_IMAGE_DONE';
export const RETRAIN_SEARCH_INDEX_REQUEST = 'RETRAIN_SEARCH_INDEX_REQUEST';
export const RETRAIN_SEARCH_INDEX_STATUS = 'RETRAIN_SEARCH_INDEX_STATUS';
export const DELETE_SEARCH_IMAGES_REQUEST = 'DELETE_SEARCH_IMAGES_REQUEST';
export const DELETE_SEARCH_IMAGES_CONFIRMED = 'DELETE_SEARCH_IMAGES_CONFIRMED';
export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_REQUEST_FAILED = 'SEARCH_REQUEST_FAILED';
export const SEARCH_REQUEST_SUCCESS = 'SEARCH_REQUEST_SUCCESS';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
