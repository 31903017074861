export const conceptsEn = {
    pageHeader: {
        list: 'Concepts',
        seeAllConcepts: 'See All Concepts',
        continue: 'Continue',
        selection: 'Selection',
        result: 'Result',
        retrain: 'Retrain',
    },
    positive: 'Positive',
    negative: 'Negative',
    validation: 'Validation',
    addImages: 'Add Images',
    deleteImages: 'Delete Images',
    cancelSelection: 'Cancel Selection',
    deleteConfirm: 'Delete Selected',
    selectedImages: 'Selected Images',
    loading: 'Your concepts are loading...',
    isBeingTrained: 'Your concept is being trained...',
    movedTo: {
        positive: 'Moved to Positive',
        negative: 'Moved to Negative',
    },
    intro: {
        searchButton: 'Search in our database',
        uploadButton: 'Upload your own images',
    },
    positivePage: {
        title: 'Positive',
        subText1: 'Concepts that are considered positive',
        subText1SubText: 'Concepts that are considered positive',
        subText2: 'Concepts that are considered positive',
        subText2SubText: 'Concepts that are considered positive',
        subText3: 'Concepts that are considered positive',
        subText3SubText: 'Concepts that are considered positive',
        emptyScreen: {
            header: 'Create a <highlighted>Positive</highlighted> Set',
            subHeader: 'Choose a method to define your <strong>Positive Images</strong>.',
            helpHeader: 'What are positive images?',
            helpText:
                'All the images that you consider as <highlighted>correctly representing</highlighted> your chosen concept. For instance, for training a custom concept of <strong>"Harry Potter"</strong>, a Positive would be any image from the Harry Potter series. <learnmore>Learn more</learnmore>.',
        },
    },
    negativePage: {
        emptyScreen: {
            header: 'Create a <highlighted>Negative</highlighted> Set',
            subHeader: 'Choose a method to define your Negative Images.',
            helpHeader: 'What are negative images?',
            helpText:
                'To teach your AI what to avoid: select images that do <highlighted>not represent your concept correctly</highlighted>. Note that the images in the Negative Set cannot be the same as the ones in the Positive Set. <learnmore>Learn more</learnmore>.',
        },
    },
    testPage: {
        headerText:
            'This is your concept in work. The number next to the photos show how certain the AI thinks it fits your model. <threshold>Learn More<info/></threshold>.',
        selectHelpTouch:
            'Tap to mark <negative>Negative</negative> or <positive>Positive</positive>',
    },
    search: {
        dialogHeader: 'Search and select multiple images',
        selectedCount: '{{count}}\xa0selected',
        selectedCount_plural: '{{count}}\xa0selected',
        add: 'Add to {{folderId}}',
        inputLabel: 'What do you want to find?',
        errorText: 'An unexpected error occurred during search. Please try to change search query.',
    },
    uploadPopup: {
        search: 'Search',
        upload: 'Upload',
        dragAndDrop: 'Drop & Drop to select files to upload...',
        uploadTo: 'Upload to {{folder}}',
    },
    emptyConcept: {
        header: 'Concept list is empty',
        subHeader: 'Your concepts will be stored here',
        text1: 'Few-shot Learning means that your computer vision model learns new concepts with very little training data.',
        text2: 'Show your model some positive examples and a few negatives. Then stand back and watch the magic happen.',
        header2: 'Your AI is waiting for you',
        text3: `The model learns new concepts with very little training data, Show your model positive examples then stand back and watch the magic happen`,
    },
    emptyNegatives: {
        header: 'No Negatives?',
        subHeader:
            'It’s ok to train without negatives, but teaching it what to avoid vastly improves the results.',
        list1: '<normal>Go to </normal><span/><strong>Add Images.<strong>',
        list2: '<strong>Search </strong><span/><normal>for things you want your concept to avoid.</normal>',
        list3: '<strong>Right click </strong><span/><normal>to tag as negative.<normal>',
    },
    hint: {
        selectionPage: 'You can always keep adding from here.',
        dragAndDrop: 'Drag & Drop',
        resultPage:
            'You can still keep tagging to keep improving! Don’t forget to Retrain right after.',
        empty: 'It’s time to teach your AI what is Positive or Negative - Use the search to find more content to Tag.',
        morePositive:
            'Keep going. For best results, try at least {{threshold}} images ({{left}} to go)',
        moreNegative:
            'What about tagging some Negatives to teach it what to avoid? Feel free to Search again.',
        enough: 'Super! It’s enough to continue - but the more the better.',
        markAsPositive: 'Mark as Positive',
        markAsNegative: 'Mark as Negative',
    },
    resultPage: {
        header: 'Test Set',
        subHeader: 'Your AI is being tested against a huge database of images, see some of them.',
        uploadedOnly: 'My Images Only',
    },
};
