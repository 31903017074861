import { connect } from 'react-redux';
import i18n from '../../i18n';
import { BasePopover } from './BasePopover';

const mapStateToProps = state => {
    const { uploaded, failed, total } = state.search.upload;
    const processed = uploaded + failed;
    return {
        message: i18n.t('search.popover.upload', {
            processed,
            total,
            failed,
        }),
        open: Boolean(uploaded + failed < total),
    };
};

const SearchUploadStatusPopover = connect(mapStateToProps)(BasePopover);

export { SearchUploadStatusPopover };
