import { createTheme } from '@material-ui/core/styles';
import { matchMobile, matchMobileOrTablet } from '../utils/markupHelpers';
import { colors } from './colors';

declare module '@material-ui/core/styles/createPalette' {
    interface PaletteOptions {
        violet?: string;
        palette2?: string;
        darkGrade1?: string;
        darkGrade2?: string;
        mediumYellow?: string;
        superGreen?: string;
        negative?: string;
    }
    interface Palette {
        violet: string;
        palette2: string;
        darkGrade1: string;
        darkGrade2: string;
        mediumYellow: string;
        superGreen: string;
        negative: string;
    }
}

/*
xs, extra-small: 0px
sm, small: 600px
md, medium: 960px
lg, large: 1280px
xl, extra-large: 1920px
*/

const baseDarkTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 700,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        type: 'dark',
        primary: {
            main: colors.GREY,
        },
        secondary: {
            main: colors.GREEN, // image/primary in figma
        },
        error: {
            main: colors.RED_DARK,
        },
        background: {
            paper: colors.DARK_LIGHT,
            default: colors.DARK_GRADE_0,
        },
        violet: colors.VIOLET,
        darkGrade1: colors.DARK_GRADE_1,
        darkGrade2: colors.DARK_GRADE_2,
        mediumYellow: colors.YELLOW_MEDIUM,
        superGreen: colors.GREEN_SUPER,
        negative: colors.RED,
    },
    shape: {
        borderRadius: 4,
    },
});

const darkTheme = {
    ...baseDarkTheme,
    typography: {
        ...baseDarkTheme.typography,
        fontFamily: 'Soehne Web, Roboto, sans-serif',
        fontSize: 14,
        h1: {
            fontWeight: 700,
            fontSize: 28,
        },
        h2: {
            fontWeight: 700,
            fontSize: 24,
        },
        h3: {
            fontWeight: 500,
            fontSize: 24,
            [matchMobile(baseDarkTheme)]: {
                fontSize: 18,
                fontWeight: 600,
            },
        },
        h4: {
            fontWeight: 700,
            fontSize: 20,
        },
        h5: {
            fontWeight: 400,
            fontSize: 16,
        },
        h6: {
            fontWeight: 700,
            fontSize: 16,
        },
        body1: {
            fontSize: 16,
            lineHeight: 1,
        },
        subtitle1: {
            fontSize: 14,
            opacity: 0.7,
        },
        subtitle2: {
            fontSize: 12,
            opacity: 0.6,
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    color: baseDarkTheme.palette.common.black,
                    fontFamily: 'Soehne Web, Roboto, sans-serif',
                },
                '*::-webkit-scrollbar': {
                    width: 7,
                    height: 7,
                },
                '*::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                },
                '*::-webkit-scrollbar-thumb ': {
                    borderRadius: 7,
                    backgroundColor: baseDarkTheme.palette.common.white,
                },
                '*::-webkit-scrollbar-corner': {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiButton: {
            root: {
                padding: '11px 16px',
                minWidth: 0, // I hope this doesn't break something
                textTransform: 'none',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '19px',
                borderRadius: baseDarkTheme.shape.borderRadius,
                backgroundColor: 'white',
                color: 'black',
            },
            contained: {
                backgroundColor: 'white',
                color: 'black',
            },
            containedPrimary: {
                color: 'white',
                boxShadow: 'none',
                backgroundColor: baseDarkTheme.palette.darkGrade2,
                '&:hover': {
                    backgroundColor: baseDarkTheme.palette.grey[700],
                },
                '&.Mui-disabled': {
                    opacity: 0.5,
                },
            },
            containedSecondary: {
                color: 'white',
            },
            outlined: {
                backgroundColor: 'transparent',
                color: 'white',
                borderColor: `white`,
                borderRadius: baseDarkTheme.shape.borderRadius,
                padding: '11px 16px',
            },
            outlinedPrimary: {
                color: 'white',
                borderColor: `white`,
                '&:hover': {
                    border: `1px solid ${baseDarkTheme.palette.secondary.main}`,
                },
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: baseDarkTheme.palette.background.paper,
            },
        },
        MuiPaper: {
            root: {
                borderRadius: baseDarkTheme.shape.borderRadius,
            },
        },
        MuiChip: {
            root: {
                borderRadius: baseDarkTheme.shape.borderRadius,
                backgroundColor: baseDarkTheme.palette.background.default,
                margin: '2px',
                padding: '12px 0',
            },
        },
        MuiTypography: {
            root: {
                color: 'white',
            },
        },
        MuiCircularProgress: {
            root: {
                color: baseDarkTheme.palette.secondary.main,
            },
        },
        MuiFormControl: {
            root: {
                borderRadius: baseDarkTheme.shape.borderRadius,
                '&:focus': {
                    outline: 'none',
                },
            },
        },
        MuiInputBase: {
            input: {
                fontSize: 16,
            },
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: '1px solid #ffffff',
                },
                '&:after': {
                    borderBottom: '1px solid #ffffff',
                },
                '&:hover:not(.Mui-disabled):before': {
                    borderBottom: '1px solid #ffffff',
                },
            },
        },
        MuiOutlinedInput: {
            input: {
                fontSize: 16,
                borderRadius: baseDarkTheme.shape.borderRadius,
                backgroundColor: 'white',
                color: baseDarkTheme.palette.background.paper,
            },
            root: {
                backgroundColor: 'white',
                borderRadius: baseDarkTheme.shape.borderRadius,
                '& fieldset': {
                    border: 'none',
                },
            },
        },
        MuiFilledInput: {
            input: {
                fontSize: 16,
                borderRadius: baseDarkTheme.shape.borderRadius,
                backgroundColor: '#E6E6E6',
                color: baseDarkTheme.palette.background.paper,
                padding: 12,
            },
            root: {
                backgroundColor: '#E6E6E6',
                borderRadius: baseDarkTheme.shape.borderRadius,
                '& fieldset': {
                    border: 'none',
                },
            },
            underline: {
                '&:after': {
                    borderBottom: 'none',
                },
                '&:hover:not(.Mui-disabled):before': {
                    borderBottom: 'none',
                },
            },
        },
        MuiCardContent: {
            root: {
                padding: 24,
                [matchMobile(baseDarkTheme)]: {
                    padding: baseDarkTheme.spacing(2),
                },
            },
        },
        MuiTableCell: {
            root: {
                padding: 8,
            },
        },
        MuiPopover: {
            paper: {
                padding: 0,
            },
        },
        MuiMenuItem: {
            root: {
                paddingTop: '12px',
                paddingBottom: '12px',
                fontSize: 14,
            },
        },
        MuiDialogActions: {
            root: {
                padding: baseDarkTheme.spacing(2),
            },
        },
        MuiTabs: {
            indicator: {
                width: '40px!important',
            },
        },
        MuiTab: {
            root: {
                textTransform: 'capitalize',
                minWidth: '0!important',
                padding: 0,
                marginRight: baseDarkTheme.spacing(5),
                fontSize: 18,

                [matchMobileOrTablet(baseDarkTheme)]: {
                    marginRight: baseDarkTheme.spacing(2),
                },
            },
        },
        MuiTabPanel: {
            root: {
                padding: baseDarkTheme.spacing(2, 0, 0, 0),
            },
        },
        MuiAlert: {
            standardWarning: {
                backgroundColor: 'transparent',
            },
            standardError: {
                backgroundColor: 'transparent',
            },
            standardSuccess: {
                backgroundColor: 'transparent',
            },
            standardInfo: {
                backgroundColor: 'transparent',
            },
        },
    },
    props: {
        MuiUseMediaQuery: {
            // whithout this option `useMediaQuery` will always return false during a first run
            // https://github.com/mui-org/material-ui/issues/21142#issuecomment-633144987
            noSsr: true,
        },
    },
};

export { darkTheme };
