import { all, call, delay, put, take, takeEvery } from 'redux-saga/effects';
import * as api from '../../api';
import { ConceptFolderEnum } from '../../utils/commonTypes';
import { actions } from '../actions';
import {
    ASSIGN_SEARCH_IMAGES_REQ,
    CT_TASK_COMPLETE,
    CT_TASK_START,
    DELETE_IMAGES_CONFIRMED_REQ,
    GET_CONCEPT_INFO_REQ,
    IMAGE_DELETE_REQ,
    IMAGE_RATE_REQ,
    IMPROVE_CONCEPT_REQ,
    TRAIN_CONCEPT_REQ,
    VALIDATE_CONCEPT_REQ,
} from '../actions/actionTypes/concept';
import { getConceptInfo, getConceptInfoSuccess, validateConcept } from '../actions/concept';
import { showDialog } from '../actions/dialog';

function* getGallery() {
    yield put(actions.imageTaggingActions.getGallery(true));
}

function* fetchConceptsAdapter(
    resource,
    params = null,
    query = null,
    debounce = 0,
    useCache = true
) {
    yield put(actions.fetchResource('concepts', params, query, debounce, useCache));
}

function* fetchTaskStatus(taskId) {
    while (true) {
        try {
            const payload = yield call(api.fetchTaskStatus, { taskId });
            const { status, message } = payload;

            if (status === 'error') {
                throw new Error(message);
            }

            if (status === 'success') {
                yield put({ type: CT_TASK_COMPLETE });

                yield put(
                    showDialog({
                        type: 'success',
                        action: {},
                        actionParams: { message: 'Task has been completed!' },
                    })
                );
                yield call(fetchConceptsAdapter);
                return;
            }

            yield put({ type: CT_TASK_START, payload: { ...payload, taskId } });
        } catch (err) {
            yield put({ type: CT_TASK_COMPLETE });
            yield call(fetchConceptsAdapter);

            yield put(
                showDialog({
                    type: 'error',
                    action: {},
                    actionParams: {
                        message: 'Task has failed. An error has occurred!',
                        details: [err.message],
                    },
                })
            );
            return;
        }

        yield delay(2000);
    }
}

function* watchGetInfo() {
    while (true) {
        const { params } = yield take(GET_CONCEPT_INFO_REQ);
        try {
            const result = yield call(api.getConcept, params.id);
            yield put(getConceptInfoSuccess(result));
        } catch (err) {
            console.log('watchGetInfo.err:', err);
        }
    }
}

function* watchTrainConcept({ history }) {
    while (true) {
        const { params } = yield take([TRAIN_CONCEPT_REQ, IMPROVE_CONCEPT_REQ]);
        yield put({ type: CT_TASK_START, payload: params });
        let payload;

        try {
            payload = yield call(api.trainClassifier, params);
            const { taskId } = payload;
            yield call(fetchConceptsAdapter);
            yield call(fetchTaskStatus, taskId);
            yield call(getGallery);
            yield put(getConceptInfo(params.id));
        } catch (err) {
            history.goBack();

            yield put(
                showDialog({
                    type: 'error',
                    action: {},
                    actionParams: {
                        message: err.message || 'Error. Please try again!',
                    },
                })
            );
        }
    }
}

function* watchValidateConcept() {
    while (true) {
        const { params } = yield take(VALIDATE_CONCEPT_REQ);
        yield put({ type: CT_TASK_START, payload: params });

        let payload;
        try {
            payload = yield call(api.predictClassifier, params);
            const { taskId } = payload;
            yield call(fetchConceptsAdapter);
            yield call(fetchTaskStatus, taskId);
            yield put(getConceptInfo(params.id));
        } catch (err) {
            yield put(
                showDialog({
                    type: 'error',
                    action: {},
                    actionParams: {
                        message: err.message || 'Error. Please try again!',
                    },
                })
            );
        }
    }
}

function* watchRateImage() {
    while (true) {
        const { params } = yield take(IMAGE_RATE_REQ);
        try {
            yield call(api.rateImage, params);
        } catch (err) {
            console.log('watchRateImage.err:', err);
            yield put(getConceptInfo(params.id));
        }
    }
}

function* watchDeleteImages() {
    while (true) {
        const { params } = yield take(IMAGE_DELETE_REQ);
        yield put(
            showDialog({
                type: 'delete_images',
                action: { type: DELETE_IMAGES_CONFIRMED_REQ },
                actionParams: params,
            })
        );
    }
}
function* watchDeleteImagesConfirmed() {
    while (true) {
        const { params } = yield take(DELETE_IMAGES_CONFIRMED_REQ);
        const { id, selectedImages } = params;
        yield put({
            type: CT_TASK_START,
            payload: {
                status: `deleting ${selectedImages.length} images`,
                taskId: '',
            },
        });

        const errors = [];

        for (let index = 0; index < selectedImages.length; index++) {
            const filename = selectedImages[index];
            try {
                yield call(api.deleteImage, { id, filename });
            } catch (err) {
                errors.push(err);
                console.log('watchDeleteImagesConfirmed.err:', err);
            }
        }
        yield put({
            type: CT_TASK_COMPLETE,
            payload: {
                status: 'delete_complete',
            },
        });
        if (errors.length > 0) {
            yield put(
                showDialog({
                    type: 'error',
                    action: {},
                    actionParams: { message: 'Error removing images.' },
                })
            );
        }
        yield put(getConceptInfo(id));
    }
}

function* assignSearchImage({ payload }) {
    const { imageIds, folder, conceptId } = payload;
    yield put({
        type: CT_TASK_START,
        payload: {
            status: `Assigning images to your ${folder} set`,
            taskId: '',
        },
    });
    try {
        yield call(api.assignSearchImages, conceptId, folder, imageIds);
    } catch (e) {
        console.error('Error assigning images:', e);
        yield put(
            showDialog({
                type: 'error',
                action: {},
                actionParams: { message: 'Error assigning images.' },
            })
        );
        return;
    } finally {
        yield put({
            type: CT_TASK_COMPLETE,
        });
    }
    if (folder === ConceptFolderEnum.validation) {
        yield put(validateConcept(conceptId));
    }
    yield put(getConceptInfo(conceptId));
}

function* watchAssignSearchImages() {
    yield takeEvery(ASSIGN_SEARCH_IMAGES_REQ, assignSearchImage);
}

export const sagas = params =>
    all([
        watchGetInfo(),
        watchTrainConcept(params),
        watchValidateConcept(params),
        watchRateImage(),
        watchDeleteImages(),
        watchDeleteImagesConfirmed(),
        watchAssignSearchImages(),
    ]);
