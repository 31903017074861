export const videoPrefixes = {
    tagging: 'TAGGING',
    faceRecognition: 'FACE_RECOGNITION',
    shotDetection: 'SHOT_DETECTION',
    highlights: 'HIGHLIGHTS',
};

export const UPDATE_VIDEO_UPLOADS = 'UPDATE_VIDEO_UPLOADS';
export const NEW_VIDEO_UPLOAD = 'NEW_VIDEO_UPLOAD';
export const SET_VIDEO_UPLOADS = 'SET_VIDEO_UPLOADS';
export const UPDATE_VIDEO_GALLERY = 'UPDATE_VIDEO_GALLERY';

export const UPLOAD_VIDEO_REQUEST = 'UPLOAD_VIDEO_REQUEST';

export const UPLOAD_VIDEO = 'UPLOAD_VIDEO';
export const UPDATE_VIDEO_GALLERY_ITEM = 'UPDATE_VIDEO_GALLERY_ITEM';
export const GET_VIDEO_GALLERY = 'GET_VIDEO_GALLERY';
export const GET_VIDEO_UPLOADS = 'GET_VIDEO_UPLOADS';
export const PREDICT_GALLERY_VIDEO = 'PREDICT_GALLERY_VIDEO';
export const DELETE_VIDEO = 'DELETE_VIDEO';
export const DELETE_VIDEO_CONFIRMED = 'DELETE_VIDEO_CONFIRMED';
