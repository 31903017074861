import {
    COPY_SHARE_LINK_TO_CLIPBOARD,
    GET_SHARED_IMAGES_PREDICTIONS,
    GET_SHARED_PREDICTIONS_SUCCESS,
    GET_SHARED_VIDEOS_PREDICTIONS,
    SEND_SHARE_LINK_EMAIL,
    SET_SHARE_DRAWER_VISIBILITY,
    SET_SHARE_POPOVER_VISIBILITY,
    SHARE_IMAGE_DEMO_UPLOADS,
    SHARE_VIDEO_UPLOADS,
    SHARE_SINGLE_VIDEO_UPLOADS,
} from './actionTypes/share';

export const shareImageDemoUploads = predictionType => ({
    type: SHARE_IMAGE_DEMO_UPLOADS,
    payload: { predictionType },
});

export const shareVideoUploads = predictionType => ({
    type: SHARE_VIDEO_UPLOADS,
    payload: { predictionType },
});

export const shareSingleVideoUploads = (predictionIds, predictionType) => ({
    type: SHARE_SINGLE_VIDEO_UPLOADS,
    payload: { predictionIds, predictionType },
});

export const getShareImagesPredictions = shareId => ({
    type: GET_SHARED_IMAGES_PREDICTIONS,
    payload: {
        shareId,
    },
});

export const getShareVideosPredictions = shareId => ({
    type: GET_SHARED_VIDEOS_PREDICTIONS,
    payload: {
        shareId,
    },
});

export const setSharePopoverVisibility = (opened, latestShareLink = '') => ({
    type: SET_SHARE_POPOVER_VISIBILITY,
    payload: {
        opened,
        latestShareLink,
    },
});

export const setShareDrawerVisibility = opened => ({
    type: SET_SHARE_DRAWER_VISIBILITY,
    payload: {
        opened,
    },
});

export const getSharePredictionsSuccess = predictions => ({
    type: GET_SHARED_PREDICTIONS_SUCCESS,
    payload: predictions,
});

export const copyShareLinkToClipboard = link => ({
    type: COPY_SHARE_LINK_TO_CLIPBOARD,
    payload: {
        link,
    },
});

export const sendShareEmail = email => ({
    type: SEND_SHARE_LINK_EMAIL,
    payload: {
        email,
    },
});

export const actions = {
    shareImageDemoUploads,
    shareVideoUploads,
    getShareImagesPredictions,
    getShareVideosPredictions,
    getSharePredictionsSuccess,
    setSharePopoverVisibility,
    setShareDrawerVisibility,
    copyShareLinkToClipboard,
    sendShareEmail,
    shareSingleVideoUploads,
};
