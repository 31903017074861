import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const MainContainer = withStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        height: '100%',
        maxHeight: 700,
        paddingBottom: theme.spacing(4)
    }
}))(({ classes, children }) => (
    <Box className={classes.root}>
        <>
            {children}
            <Box />
        </>
    </Box>
));

export { MainContainer };
