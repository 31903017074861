// MATERIAL UI
import { Box, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { VisibilityOutlined } from '@material-ui/icons';
import React from 'react';
import { ThresholdDescription } from './styled/ThresholdDescription';

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        maxWidth: '500px',
        padding: theme.spacing(4),
    },

    title: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: theme.spacing(1),
        '&>h4': {
            marginLeft: theme.spacing(2),
        },
    },

    bodyContainer: {
        padding: theme.spacing(1, 0),
    },

    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        '&>p': {
            color: '#777778',
        },
    },
    button: {
        minWidth: 80,
        marginLeft: theme.spacing(2),
    },
}));

const DemoThresholdInfo = props => {
    const classes = useStyles();
    const { onClose, action } = props;

    const handleConfirm = () => {
        onClose(action);
    };

    return (
        <div data-test="demo-threshold-popup" className={classes.dialogContainer}>
            <div className={classes.title}>
                <VisibilityOutlined size={24} />
                <Typography variant="h4">Threshold</Typography>
            </div>
            <div className={classes.bodyContainer}>
                <Typography variant="h5">
                    This feature offers a measurement of accuracy that can be shown when hovering
                    tags.
                </Typography>
                <Box>
                    <ThresholdDescription text="Super" value={0.95} range="90~100" />
                    <ThresholdDescription text="High" value={0.8} range="70~90" />
                    <ThresholdDescription text="Medium" value={0.6} range="50~70" />
                    <ThresholdDescription text="Low" value={0.4} range="<50" />
                </Box>
            </div>
            <div className={classes.buttonContainer}>
                <Typography variant="body2">
                    Get in touch with us and learn more about how this feature can improve your
                    solution.
                </Typography>
                <Button className={classes.button} variant="outlined" onClick={handleConfirm}>
                    Close
                </Button>
            </div>
        </div>
    );
};

export { DemoThresholdInfo };
