import {
    DELETE_SEARCH_IMAGES_REQUEST,
    RETRAIN_SEARCH_INDEX_REQUEST,
    RETRAIN_SEARCH_INDEX_STATUS,
    SEARCH_REQUEST,
    SEARCH_REQUEST_FAILED,
    SEARCH_REQUEST_SUCCESS,
    SET_SEARCH_QUERY,
    UPLOAD_SEARCH_IMAGES,
    UPLOAD_SEARCH_IMAGES_COUNT,
    UPLOAD_SEARCH_IMAGES_REQUEST,
    UPLOAD_SEARCH_IMAGE_DONE,
} from './actionTypes/search';

export const uploadSearchImagesRequest = images => ({
    type: UPLOAD_SEARCH_IMAGES_REQUEST,
    payload: { images },
});

export const uploadSearchImages = images => ({
    type: UPLOAD_SEARCH_IMAGES,
    payload: { images },
});

export const retrainSearchIndexRequest = () => ({
    type: RETRAIN_SEARCH_INDEX_REQUEST,
});

export const retrainSearchIndexStatus = payload => ({
    type: RETRAIN_SEARCH_INDEX_STATUS,
    payload,
});

export const updateUploadSearchImagesCount = count => ({
    type: UPLOAD_SEARCH_IMAGES_COUNT,
    payload: { count },
});

export const updateUploadSearchImageDone = (failed = false) => ({
    type: UPLOAD_SEARCH_IMAGE_DONE,
    payload: { failed },
});

export const deleteSearchImagesRequest = images => ({
    type: DELETE_SEARCH_IMAGES_REQUEST,
    payload: { images },
});

export const setSearchQuery = query => ({
    type: SET_SEARCH_QUERY,
    payload: { query },
});

export const searchRequest = () => ({
    type: SEARCH_REQUEST,
});

export const searchRequestSuccess = images => ({
    type: SEARCH_REQUEST_SUCCESS,
    payload: { images },
});

export const searchRequestFailed = error => ({
    type: SEARCH_REQUEST_FAILED,
    payload: { error },
});

export const actions = {
    uploadSearchImagesRequest,
    uploadSearchImages,
    retrainSearchIndexRequest,
    retrainSearchIndexStatus,
    updateUploadSearchImagesCount,
    updateUploadSearchImageDone,
    deleteSearchImagesRequest,
    setSearchQuery,
    searchRequest,
    searchRequestSuccess,
    searchRequestFailed,
};
