import { UPLOAD_FINISHED_WITH_FAIL } from '../actions/actionTypes/upload';

const initialEntities = {};

const entitiesReducer = (state = initialEntities, action) => {
    if (action.type === 'RESET_ENTITY') {
        return initialEntities;
    }

    if (action.type === 'IMAGE_ENTITY_RESET') {
        return { ...state, images: {} };
    }

    if (action.type === 'IMAGE_ENTITY_CLEAR') {
        const newImages = {};
        if (!state.images) {
            return state;
        }
        Object.keys(state.images)
            .filter(key => {
                const image = state.images[key];
                return (
                    image.phase !== 'complete' ||
                    Number(image.concept_id) !== Number(action.payload.id)
                );
            })
            .forEach(key => {
                newImages[key] = state.images[key];
            });

        return {
            ...state,
            images: newImages,
        };
    }

    if (action.type === UPLOAD_FINISHED_WITH_FAIL) {
        const newImages = {};
        const failedImages = action.payload.files;
        const failedImagesNames = failedImages.map(image => image.name);
        Object.keys(state.images)
            .filter(key => !failedImagesNames.includes(state.images[key].filename))
            .forEach(key => {
                newImages[key] = state.images[key];
            });

        return {
            ...state,
            images: newImages,
        };
    }

    if (action.entities) {
        const newState = Object.keys(action.entities).reduce(
            (acc, key) => {
                return { ...acc, [key]: { ...state[key], ...action.entities[key] } };
            },
            { ...state }
        );
        return newState;
    }
    return state;
};

export { entitiesReducer };
