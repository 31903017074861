import { createTheme } from '@material-ui/core/styles';
import { matchMobile } from '../utils/markupHelpers';
import { colors } from './colors';
/*
xs, extra-small: 0px
sm, small: 600px
md, medium: 960px
lg, large: 1280px
xl, extra-large: 1920px
*/

const baseLightTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 700,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        type: 'light',
        primary: {
            main: colors.DARK_LIGHT,
        },
        secondary: {
            main: colors.GREEN, // image/primary in figma
        },
        error: {
            main: colors.RED_DARK,
        },
    },
    shape: {
        borderRadius: 4,
    },
});

export const lightTheme = createTheme({
    palette: {
        ...baseLightTheme.palette,
    },
    typography: {
        fontFamily: 'Soehne Web, Roboto, sans-serif',
        fontSize: 14,
        h1: {
            fontWeight: 700,
            fontSize: 28,
        },
        h2: {
            fontWeight: 700,
            fontSize: 24,
        },
        h3: {
            fontWeight: 500,
            fontSize: 24,
            [matchMobile(baseLightTheme)]: {
                fontSize: 18,
                fontWeight: 600,
            },
        },
        h4: {
            fontWeight: 700,
            fontSize: 20,
        },
        h5: {
            fontWeight: 400,
            fontSize: 16,
        },
        h6: {
            fontWeight: 700,
            fontSize: 16,
        },
        body1: {
            fontSize: 16,
            lineHeight: 1,
        },
        subtitle1: {
            fontSize: 14,
            opacity: 0.7,
        },
    },
    shape: {
        ...baseLightTheme.shape,
    },
    overrides: {
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: '1px solid #aaaaaa',
                },
                '&:after': {
                    borderBottom: '1px solid #12BD8C',
                },
                '&:hover:not(.Mui-disabled):before': {
                    borderBottom: '1px solid #12BD8C',
                },
            },
        },
        MuiPopover: {
            paper: {
                padding: 24,
            },
        },
        MuiSlider: {
            root: {
                color: '#222326',
            },
            track: {
                height: 4,
            },
            rail: {
                height: 4,
            },
            thumb: {
                height: 8,
                width: 8,
                marginTop: -2,
                marginLeft: -2,
            },
        },
        MuiFormControl: {
            root: {
                borderRadius: 3,
                '&:focus': {
                    outline: 'none',
                },
            },
        },
        MuiTabs: {
            indicator: {
                width: '40px!important',
            },
        },
        MuiMenuItem: {
            root: {
                paddingTop: '12px',
                paddingBottom: '12px',
                fontSize: 14,
            },
        },
        MuiFormHelperText: {
            root: {
                color: 'white',
            },
        },
        MuiButton: {
            root: {
                padding: '11px 16px',
                minWidth: '50px',
                textTransform: 'none',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '14px',
                borderRadius: baseLightTheme.shape.borderRadius,
            },
            containedPrimary: {
                boxShadow: 'none',
                '&:hover': {
                    backgroundColor: baseLightTheme.palette.secondary.main,
                },
            },
            outlined: {
                padding: '11px 16px',
            },
        },
    },
});
