import { CT_TASK_COMPLETE, CT_TASK_START } from '../actions/actionTypes/concept';

const initialState = {
    currentTask: null,
    taskResult: null,
};

const tasksReducer = (state = initialState, action) => {
    switch (action.type) {
        case CT_TASK_START:
            return { ...state, currentTask: action.payload, taskResult: null };

        case CT_TASK_COMPLETE:
            return { ...state, currentTask: null, taskResult: action.payload };

        default:
            return state;
    }
};

export { tasksReducer };
