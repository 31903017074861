import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const Header = withStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2)
    },
    header: {
        color: 'black'
    },
    subheader: {
        color: 'black',
        opacity: 0.6,
        paddingTop: theme.spacing(1)
    }
}))(({ classes, header, subheader }) => (
    <Box className={classes.root}>
        {header && (
            <Typography className={classes.header} variant="h4">
                {header}
            </Typography>
        )}
        {subheader && (
            <Typography className={classes.subheader} variant="h5">
                {subheader}
            </Typography>
        )}
    </Box>
));

export { Header };
