import { combineReducers } from 'redux';
import { appReducer } from './app';
import { authReducer } from './auth';
import { conceptReducer } from './concept';
import { dialogReducer } from './dialog';
import { entitiesReducer } from './entities';
import { imageDemoReducer } from './imageDemo';
import { routerReducer } from './router';
import { searchReducer } from './search';
import { shareReducer } from './share';
import { tasksReducer } from './tasks';
import { uploadReducer } from './upload';
import { userReducer } from './user';
import { videoReducer } from './video';

const rootReducer = combineReducers({
    router: routerReducer,
    entities: entitiesReducer,
    app: appReducer,
    auth: authReducer,
    tasks: tasksReducer,
    dialog: dialogReducer,
    upload: uploadReducer,
    imageDemo: imageDemoReducer,
    user: userReducer,
    share: shareReducer,
    video: videoReducer,
    search: searchReducer,
    concept: conceptReducer,
});

export { rootReducer };
