import React from 'react';
import { DialogParams } from '../../utils/commonTypes';
import { BaseDeleteConfirmation } from './BaseDeleteConfirmation';

export function DeletePredictionConfirmation(props: DialogParams) {
    return (
        <BaseDeleteConfirmation
            {...props}
            messageTranslationPath="imageDemo.confirmations.deleteImage"
        />
    );
}
