export const shareEn = {
    headers: {
        sharedImages: 'Shared Images',
        sharedVideo: 'Shared Video',
    },
    shareLink: {
        NotFoundTitle: 'Share link invalid',
        NotFoundMessage: 'The link expired or might have been deleted by the user.',
    },
};
