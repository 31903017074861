export const dashboardEn = {
    superHumanVision: 'Superhuman Vision',
    forEveryApp: 'for every application',
    mobius: {
        welcome: 'Welcome, {{user}}',
        intro1: 'All the features listed below are available within our SDK.',
        intro2: "Freely pick and test the feature that you're interested in.",
    },
    onpremise: {
        welcome: 'Welcome, {{user}}',
        intro1: '',
        intro2: '',
    },
    tiles: {
        image: {
            header: 'Image Tagging Demo',
            text: 'This solution does more than identifying objects: it can recognize abstract concepts, emotions and facial expressions.',
        },
        concepts: {
            header: 'Customize Your Concepts',
            text: 'Your computer vision model learns new concepts with very little training data.',
        },
        video: {
            header: 'Video Tagging Demo',
            text: 'Our video tagging feature helps you find the most relevant content from your archive.',
        },
        facial: {
            header: 'Facial Recognition Demo',
            text: 'This solution natively identifies over 11,000 celebrities in both images and videos.',
        },
    },
};
