import { Box, Grow, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
    root: {
        minHeight: 40,
        marginTop: theme.spacing(1),
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        backgroundColor: theme.palette.error.main,
    },
}));

type Props = {
    error: boolean;
    errorMessage: string;
};

const ErrorContainer = ({ error, errorMessage }: Props) => {
    const classes = useStyles();
    if (!error) {
        return null;
    }
    return (
        <Box className={classes.root}>
            <Grow in={error} {...(error ? { timeout: 750 } : {})}>
                <Paper className={classes.paper}>
                    <Typography component="span" variant="caption" style={{ color: 'white' }}>
                        {errorMessage || 'incorrect username or password'}
                    </Typography>
                </Paper>
            </Grow>
        </Box>
    );
};

export { ErrorContainer };
