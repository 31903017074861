export const videoEn = {
    video: 'video',
    video_other: 'videos',
    processingError:
        'Unexpected error happened during processing of the video, please try again or contact a our technical support',
    modeSwitch: {
        frame: {
            name: 'Shot By Shot',
            description: 'Showing tags for the current segment only',
        },
        video: {
            name: 'Entire Video',
            description: 'Showing tags for the entire video',
        },
    },
    search: {
        placeholder: 'Search Tag or Category',
    },
    pageHeader: {
        videoTagging: 'Video Tagging',
        videoTaggingUploads: 'Video Tagging Uploads',
        videoFacialRecognition: 'Video Face Recognition',
        videoFacialRecognitionUploads: 'Video Face Recognition Uploads',
        videoHighlights: 'Video Highlights',
        videoHighlightsUploads: 'Video Highlights Uploads',
        videoShotDetection: 'Video Shot Detection',
        videoShotDetectionUploads: 'Video Shot Detection Uploads',
        back: 'Back',
        shareButton: 'Share',
        uploadButton: 'Upload Your Own Video',
        lastUploads: 'Last Uploads',
    },
    uploadPopup: {
        messages: {
            error: 'An error has occurred during uploading the file. Please try again.',
            invalidType: 'Unsupported file type',
            fileTooLarge: 'The file is too large. Max allowed size is {{maxSize}} MB',
            quotaReached: `Upload quota is reached.
            {{uploadImagesMax}} of ouf {{uploadImagesMax}} files have already been uploaded`,
        },
        dragAndDrop: 'Drag and drop video to upload. {{countMessage}}',
        countMessage: '{{count}} videos are left.',
        button: 'Select Video',
    },
    selectedTags: {
        header: 'Selected Tags',
        emptyHeader: 'No tag selected',
        emptyMessage: 'You can select multiple tags to highlight in the video',
    },
    uploadVideo: 'Please, upload a video',
    uploadCanceled: 'Video uploading canceled',
    tagging: {
        settings: 'Settings',
        threshold: 'Threshold',
        alwaysShowPercentage: 'Always show tags %',
    },
    faceRecognition: {
        person: 'Person',
        noFacesFound: 'No faces were found in this video',
    },
    confirmations: {
        deleteVideo: 'Do you want to delete the Video? This action can not be taken back.',
        deleteButton: 'Delete',
        cancelButton: 'Cancel',
    },
    tooltips: {
        share: 'Share Video',
        remove: 'Remove Video',
        settings: 'Settings',
    },
    highlights: {
        found: '{{count}} Highlights found',
        foundDesc:
            'We find the most important scenes in the video for you. You can also <highlights>upload</highlights> and <highlights>share</highlights> your own video.',
        threshold: 'Highlight Threshold',
        thresholdDesc: 'Tweak the threshold to find different highlights',
        results: 'Results',
        resultsDesc: 'Select scenes to navigate the video',
        modeSwitch: {
            highlights: {
                name: 'Play Highlights',
                description: 'Playing only Highlighted scenes',
            },
            entire: {
                name: 'Play Entire Video',
                description: 'Playing the full unedited video',
            },
        },
    },
    shot: 'Shot {{index}}',
    start: 'Start',
    end: 'End',
    duration: 'Duration',
    shotDetection: {
        found: '{{count}} Shot found',
        found_plural: '{{count}} Shots found',
        foundDesc:
            'Browse freely through every shot in the scene. You can also <highlights>upload</highlights> and <highlights>share</highlights> your own video. Check your shots.',
    },
};
