export const GET_USER_LIST = 'GET_USER_LIST';
export const UPDATE_USER_LIST = 'UPDATE_USER_LIST';
export const SET_USER_REQUEST = 'SET_USER_REQUEST';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
export const SET_USER_ERROR = 'SET_USER_ERROR';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const USER_REGISTERED = 'USER_REGISTERED';
export const USER_VERIFIED = 'USER_VERIFIED';
