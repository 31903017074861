import * as app from './app';
import * as auth from './auth';
import * as concepts from './concepts';
import * as dialog from './dialog';
import * as imageDemo from './imageDemo';
import * as search from './search';
import * as share from './share';
import * as upload from './upload';
import * as user from './user';
import * as video from './video';

const composeExports = (src, key) =>
    src.map(d => ({ ...d[key] })).reduce((acc, curr) => ({ ...acc, ...curr }), {});

export const actions = composeExports(
    [app, concepts, auth, dialog, upload, imageDemo, user, share, video, search],
    'actions'
);
