import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useProtectedImage } from '../../hooks/useProtectedImage';
import Zoom from '../Zoom';

const useStyles = makeStyles(theme => ({
    notFound: {
        width: '100%',
        height: '100%',
        fontSize: 13,
        padding: 3,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
    },
    image: {
        borderRadius: theme.shape.borderRadius,
        '&.selected': {
            border: `3px solid ${theme.palette.common.white}`,
        },
    },
    zoomedImage: {
        '&:hover': {
            transform: 'scale(2)',
            position: 'absolute',
            objectFit: 'contain',
            zIndex: 10,
            transitionDelay: '0.5s',
            transition: 'transform 0.1s',
            backgroundColor: theme.palette.grey['900'],
        },
    },
}));

type Positions = {
    firstRow: boolean;
    lastRow: boolean;
    firstColumn: boolean;
    lastColumn: boolean;
};

type Props = {
    src: string;
    onLoad?: ReactEventHandler<HTMLImageElement>;
    alt?: string;
    style?: React.CSSProperties;
    className?: string;
    Loader?: any;
    dataTest?: string;
    positions?: Positions;
    zoomable?: boolean;
    fullZoom?: boolean;
    selected?: boolean;
};

export const ProtectedImage = ({
    src,
    onLoad = () => {},
    Loader = <></>,
    dataTest = undefined,
    alt,
    className,
    positions,
    fullZoom = false,
    zoomable = false,
    selected = false,
    ...props
}: Props) => {
    const state = useProtectedImage(src);
    const classes = useStyles();
    const { t } = useTranslation();

    const mapGridPostionsToTransform = useCallback((positions?: Positions) => {
        if (!positions) {
            return 'center';
        }
        const { firstRow, lastRow, firstColumn, lastColumn } = positions;
        const column = firstColumn ? 'left' : lastColumn ? 'right' : 'center';
        const row = firstRow ? 'top' : lastRow ? 'bottom' : 'center';
        return `${column} ${row}`;
    }, []);

    if (!state.loaded) {
        return Loader;
    }

    if (state.error) {
        return (
            <div className={classes.notFound}>
                <Typography variant="body2">{t('common.imageNotFound')}</Typography>
            </div>
        );
    }

    if (fullZoom) {
        return (
            <Zoom>
                <img
                    className={clsx(selected ? 'selected' : '', classes.image, className)}
                    data-test={dataTest}
                    alt={alt}
                    src={state.blob}
                    onLoad={onLoad}
                    {...props}
                />
            </Zoom>
        );
    }
    return (
        <img
            className={clsx(
                zoomable ? classes.zoomedImage : '',
                selected ? 'selected' : '',
                classes.image,
                className
            )}
            style={{ transformOrigin: zoomable ? mapGridPostionsToTransform(positions) : '' }}
            data-test={dataTest}
            alt={alt}
            src={state.blob}
            onLoad={onLoad}
            {...props}
        />
    );
};
