export const searchEn = {
    pageHeader: {
        searchAdmin: 'Search Management',
        search: 'Mobius Search',
        uploadButton: 'Upload',
        retrainButton: 'Retrain Search Index',
    },
    confirmations: {
        deleteImages: 'Are you sure you want to delete selected images?',
    },
    admin: {
        message: `You can add and remove images and train search index to be able to see
        those images while searching. The search images appear in the search
        results only after the index is trained.`,
    },
    popover: {
        upload: 'Uploading Search Images: {{processed}}/{{total}}. Failed: {{failed}}',
        searchIndex: {
            starting: 'Starting Training Index...',
            training: 'Training Search Index: {{progress}}',
        },
    },
    cards: {
        visualSearch: {
            header: 'Visual Search',
            content: `Discover relevant visuals faster with an AI-powered visual search engine that analyzes the actual content and not the associated metadata.`,
        },
        unlimited: {
            header: 'Unlimited',
            content: `Implicitly understand any action, emotion, idea, or complex term`,
        },
        ai: {
            header: 'AI-Powered',
            content: `Find the perfect visuals using plain language`,
        },
    },
};
