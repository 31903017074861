import {
    DELETE_PREDICTION_REC,
    DEMO_THRESHOLD_POPUP,
    GET_GALERY,
    GET_UPLOADS,
    imageDemoPrefixes,
    PREDICT_GALLERY_IMAGE,
    REMOVE_FACE_IDENTITY,
    REPREDICT_UPLOAD_IMAGE,
    SAVE_FACE_IDENTITY,
    SET_IMAGE_DEMO_UPLOADS,
    SHOW_CONCLUSION_DIALOG,
    SHOW_TUTORIAL,
    START_TUTORIAL,
    UPDATE_IMAGE_DEMO_GALERY,
    UPDATE_IMAGE_DEMO_GALERY_ITEM,
    UPDATE_IMAGE_DEMO_UPLOADS,
    UPLOAD_IMAGE_DEMO_IMAGES,
    UPLOAD_IMAGE_REQUEST,
} from './actionTypes/imageDemo';

const wrapAction = (action, prefix) => {
    return `${prefix}_${action}`;
};

const actionsFactory = type => {
    const DEMO_THRESHOLD_POPUP_ACTION = wrapAction(DEMO_THRESHOLD_POPUP, type);
    const GET_GALERY_ACTION = wrapAction(GET_GALERY, type);
    const GET_UPLOADS_ACTION = wrapAction(GET_UPLOADS, type);
    const PREDICT_GALLERY_IMAGE_ACTION = wrapAction(PREDICT_GALLERY_IMAGE, type);
    const UPLOAD_IMAGE_DEMO_IMAGES_ACTION = wrapAction(UPLOAD_IMAGE_DEMO_IMAGES, type);
    const UPLOAD_IMAGE_REQUEST_ACTION = wrapAction(UPLOAD_IMAGE_REQUEST, type);
    const SHOW_TUTORIAL_ACTION = wrapAction(SHOW_TUTORIAL, type);
    const UPDATE_IMAGE_DEMO_UPLOADS_ACTION = wrapAction(UPDATE_IMAGE_DEMO_UPLOADS, type);
    const SET_IMAGE_DEMO_UPLOADS_ACTION = wrapAction(SET_IMAGE_DEMO_UPLOADS, type);
    const UPDATE_IMAGE_DEMO_GALERY_ACTION = wrapAction(UPDATE_IMAGE_DEMO_GALERY, type);
    const UPDATE_IMAGE_DEMO_GALERY_ITEM_ACTION = wrapAction(UPDATE_IMAGE_DEMO_GALERY_ITEM, type);
    const REPREDICT_UPLOAD_IMAGE_ACTION = wrapAction(REPREDICT_UPLOAD_IMAGE, type);
    const DELETE_PREDICTION_REC_ACTION = wrapAction(DELETE_PREDICTION_REC, type);
    const SHOW_CONCLUSION_DIALOG_ACTION = wrapAction(SHOW_CONCLUSION_DIALOG, type);

    const updateImageDemoUploads = (index = null, update = () => {}, id = null) => ({
        type: UPDATE_IMAGE_DEMO_UPLOADS_ACTION,
        payload: { index, update, id },
    });

    const setImageDemoUploads = uploads => ({
        type: SET_IMAGE_DEMO_UPLOADS_ACTION,
        payload: { uploads },
    });

    const updateImageDemoGallery = gallery => ({
        type: UPDATE_IMAGE_DEMO_GALERY_ACTION,
        payload: { gallery },
    });

    const updateImageDemoGalleryItem = (folder, name, prediction, predictionType) => ({
        type: UPDATE_IMAGE_DEMO_GALERY_ITEM_ACTION,
        payload: { folder, name, prediction, predictionType },
    });

    const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST_ACTION, params });

    const showDemoThresholdPopup = () => ({ type: DEMO_THRESHOLD_POPUP_ACTION });

    const showTutorialDialog = () => ({ type: SHOW_TUTORIAL_ACTION });

    const showConclusionDialog = () => ({ type: SHOW_CONCLUSION_DIALOG_ACTION });

    const startTutorialRequest = params => ({ type: START_TUTORIAL, payload: params });

    const uploadImageDemoImages = images => {
        return {
            type: UPLOAD_IMAGE_DEMO_IMAGES_ACTION,
            payload: { images },
        };
    };

    const getGallery = (ignoreCache = false) => {
        return {
            type: GET_GALERY_ACTION,
            payload: { ignoreCache },
        };
    };

    const getUploads = () => {
        return {
            type: GET_UPLOADS_ACTION,
        };
    };

    const predictGalleryImage = payload => {
        return {
            type: PREDICT_GALLERY_IMAGE_ACTION,
            payload,
        };
    };

    const repredictUploadImage = id => {
        return {
            type: REPREDICT_UPLOAD_IMAGE_ACTION,
            payload: { id },
        };
    };

    const deletePrediction = id => {
        return {
            type: DELETE_PREDICTION_REC_ACTION,
            payload: { id },
        };
    };

    const saveFaceIdentity = () => ({
        type: SAVE_FACE_IDENTITY,
    });

    const removeFaceIdentity = () => ({
        type: REMOVE_FACE_IDENTITY,
    });

    return {
        getUploads,
        updateImageDemoUploads,
        updateImageDemoGallery,
        updateImageDemoGalleryItem,
        uploadImageDemoImages,
        getGallery,
        uploadImageRequest,
        showDemoThresholdPopup,
        predictGalleryImage,
        setImageDemoUploads,
        repredictUploadImage,
        deletePrediction,
        showTutorialDialog,
        showConclusionDialog,
        startTutorialRequest,
        saveFaceIdentity,
        removeFaceIdentity,
    };
};

export const actions = {
    imageTaggingActions: actionsFactory(imageDemoPrefixes.tagging),
    imageFaceRecognitionActions: actionsFactory(imageDemoPrefixes.faceRecognition),
    imageLogoDetectionActions: actionsFactory(imageDemoPrefixes.logoDetection),
};
