import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Error';
import React from 'react';

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        maxWidth: '500px',
        padding: theme.spacing(4, 6)
    },

    bodyContainer: {
        padding: theme.spacing(1, 0)
    },

    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(0, 2)
    }
}));

function ThresholdConceptConfirmation(props) {
    const classes = useStyles();

    // console.log("ThresholdConceptConfirmation.props", props )
    const { onClose, action, actionParams } = props;
    const { name } = actionParams;
    const handleCancel = () => {
        onClose();
    };

    const handleConfirm = () => {
        onClose(action, actionParams);
    };

    return (
        <div className={classes.dialogContainer}>
            <div className={classes.iconContainer}>
                <InfoIcon />
            </div>
            <div className={classes.bodyContainer}>
                <Typography variant="body2">
                    {`Are you sure you want to update threshold for concept “${name}”?`}
                </Typography>
            </div>
            <div className={classes.buttonContainer}>
                <Button className={classes.button} variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={handleConfirm}>
                    Update
                </Button>
            </div>
        </div>
    );
}

ThresholdConceptConfirmation.propTypes = {};

export { ThresholdConceptConfirmation };
