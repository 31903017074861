import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

/**
 * On mobile margins are added to make it the same size as `contained` button
 */
const LinkContainer = withStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',

        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            padding: theme.spacing(1),
        },
    }
}))(({ classes, children }) => <Box className={classes.root}>{children}</Box>);

export { LinkContainer };
