import RouteParser from 'route-parser';
import {
    GOTO_PAGE,
    GO_BACK_PAGE,
    MODAL_CLOSE,
    MODAL_OPEN,
    PAGE_TRANSITION,
    SET_APP_CONFIG,
    SET_CURRENT_PAGE,
    SET_DRAWER_CLOSE,
    SET_DRAWER_OPEN
} from './actionTypes/app';

const getPage = (pages, pathname) => {
    const page = pages.find(p => new RouteParser(p.pathname).match(pathname));
    const params = new RouteParser(page.pathname).match(pathname);
    return {
        ...page,
        params
    };
};

export const setCurrentPage = page => ({ type: SET_CURRENT_PAGE, payload: page });
export const gotoPage = ({ pathname }) => (dispatch, getState) => {
    const page = getPage(getState().app.pages, pathname);
    dispatch({
        type: GOTO_PAGE,
        payload: { ...page, pathname }
    });
};
export const openDrawer = () => ({ type: SET_DRAWER_OPEN });
export const closeDrawer = () => ({ type: SET_DRAWER_CLOSE });

export const goBackPage = () => ({ type: GO_BACK_PAGE });
export const pageTransition = type => ({ type: PAGE_TRANSITION, payload: type });
export const setAppConfig = (features, config) => ({
    type: SET_APP_CONFIG,
    payload: { features, config }
});

export const openModal = props => ({ type: MODAL_OPEN, payload: props });
export const closeModal = () => ({ type: MODAL_CLOSE });

export const actions = {
    openDrawer,
    closeDrawer
};
