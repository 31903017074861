// MATERIAL UI
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import React from 'react';

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        maxWidth: '500px',
        padding: theme.spacing(4, 6),
    },

    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(2, 0),
    },

    bodyContainer: {
        padding: theme.spacing(1, 0),
    },

    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(0, 2),
    },
}));

const SuccessInfo = props => {
    const classes = useStyles();
    const { onClose, action, actionParams } = props;
    const { message } = actionParams;

    const handleConfirm = () => {
        onClose(action, actionParams);
    };

    return (
        <div className={classes.dialogContainer}>
            <div className={classes.iconContainer}>
                <SuccessIcon
                    style={{
                        color: '#21D19E',
                        marginRight: '8px',
                    }}
                />
                <Typography
                    variant="body2"
                    style={{
                        lineHeight: '15px',
                    }}
                >
                    Success !
                </Typography>
            </div>
            <div className={classes.bodyContainer}>
                <Typography variant="body2">{message}</Typography>
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    data-test="success-dialog-okay"
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={handleConfirm}
                >
                    Okay
                </Button>
            </div>
        </div>
    );
};

export { SuccessInfo };
