import { DASHBOARD, USER } from '../../routes/routes';
import { featureAccess } from '../../utils/constants';
import { isMobius } from '../../utils/env';
import {
    MODAL_CLOSE,
    MODAL_OPEN,
    PAGE_TRANSITION,
    SET_APP_CONFIG,
    SET_DRAWER_CLOSE,
    SET_DRAWER_OPEN,
} from '../actions/actionTypes/app';
import { AUTH_LOGOUT_SUCCESS } from '../actions/actionTypes/auth';

const defaultConfig = {
    baseUrl: '/',
    initialized: false,
    features: {
        userAdmin: false,
        ...Object.keys(featureAccess).reduce((acc, elem) => ({ ...acc, [elem]: false }), {}),
    },
    settings: {
        isMobius: isMobius(),
    },
};

const initialState = {
    pages: [],
    currentPage: null,
    transitionState: null,
    modalOpen: false,
    location: null,
    drawerOpen: true,
    config: defaultConfig,
};

const appReducer = (state = initialState, { type, payload }) => {
    let baseUrl;
    switch (type) {
        case SET_DRAWER_OPEN:
            return { ...state, drawerOpen: true };
        case SET_DRAWER_CLOSE:
            return { ...state, drawerOpen: false };

        case PAGE_TRANSITION:
            return {
                ...state,
                transitionState: payload,
            };

        case SET_APP_CONFIG:
            if (payload.features.cti || payload.features.imageDemo) {
                baseUrl = DASHBOARD.pathname;
            } else {
                baseUrl = USER.pathname;
            }

            return {
                ...state,
                config: {
                    ...state.config,
                    initialized: true,
                    features: payload.features,
                    baseUrl,
                    settings: payload.config,
                },
            };

        case AUTH_LOGOUT_SUCCESS: {
            return {
                ...state,
                config: defaultConfig,
            };
        }

        case MODAL_OPEN:
            return { ...state, modalOpen: true };

        case MODAL_CLOSE:
            return { ...state, modalOpen: false };

        // do reducer stuff
        default:
            return state;
    }
};

export { appReducer };
