/* eslint-disable react/jsx-filename-extension */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Root } from './containers/Root';
import './i18n';
import { configureStore, sagas } from './redux';
import { addCookieDisclaimer } from './utils/cookie';
import { isMobius } from './utils/env';
import { initGA } from './utils/ga';

import { hotjar } from 'react-hotjar';
import { initHubspot } from './utils/hubspot';

const history = createBrowserHistory();
// eslint-disable-next-line no-underscore-dangle
const store = configureStore(window.__INITIAL_STATE__ || {});
store.runSaga(sagas({ history, dispatch: store.dispatch }));

(async () => {
    await initGA();
    initHubspot();
    addCookieDisclaimer();
    if (isMobius() && process.env.REACT_APP_SENTRY_DSN) {
        await Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: 0.5,
            environment: process.env.REACT_APP_ENVIRONMENT,
        });
    }
    if (isMobius() && process.env.REACT_APP_HOTJAR_ID) {
        await hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, 6);
    }
    ReactDOM.render(
        <Root
            {...{
                history,
                store,
            }}
        />,
        document.getElementById('root')
    );
})();
