import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Error';
import React from 'react';

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        maxWidth: '500px',
        padding: theme.spacing(4, 6),
    },

    bodyContainer: {
        padding: theme.spacing(1, 0),
    },

    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(0, 2),
    },
}));

const RemoveImagesDialog = props => {
    const classes = useStyles();
    const { onClose, action, actionParams } = props;
    const handleCancel = () => {
        onClose();
    };

    const handleConfirm = () => {
        onClose(action, actionParams);
    };

    return (
        <div className={classes.dialogContainer}>
            <div className={classes.iconContainer}>
                <InfoIcon />
            </div>
            <div className={classes.bodyContainer}>
                <Typography variant="body2">
                    {`Are you sure you want to delete all selected images?`}
                </Typography>
            </div>
            <div className={classes.buttonContainer}>
                <Button className={classes.button} variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button
                    data-test="dialog-delete-confirmation"
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={handleConfirm}
                >
                    Delete
                </Button>
            </div>
        </div>
    );
};

export { RemoveImagesDialog };
