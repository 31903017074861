import { Box, makeStyles, ThemeProvider } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { CompanyName } from '../components/auth/styled/CompanyName';
import { LogoContainer } from '../components/auth/styled/LogoContainer';
import { selectors } from '../redux';
import { lightTheme } from '../theme/light';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    leftPanel: {
        width: '40%',
        minWidth: 520,
        padding: theme.spacing(12, 10, 4, 10),
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(6, 10, 2, 10),
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            minWidth: 0,
            padding: theme.spacing(11, 3, 3, 3),
        },
        overflowY: 'auto',
    },
    rightPanel: {
        width: '100%',
        backgroundImage: 'url("/assets/Sign-in-loop.gif")',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    content: {
        width: 360,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    childrenContainer: {
        width: '100%',
        '& .MuiInputBase-root': {
            color: 'black',
        },
    },
    footerLabel: {
        opacity: 0.4,
    },
}));

const AppContainerPublicBase = props => {
    const classes = useStyles();

    const { children } = props;

    return (
        <ThemeProvider theme={lightTheme}>
            <Box className={classes.root}>
                <Box className={classes.leftPanel}>
                    <Box className={classes.content}>
                        <Box className={classes.childrenContainer}>
                            <>
                                <CompanyName />
                                {children}
                            </>
                        </Box>
                        <Box>
                            <LogoContainer />
                            <Box className={classes.footerLabel}>
                                © 2023 Mobius Labs GmbH. All rights reserved.
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.rightPanel} />
            </Box>
        </ThemeProvider>
    );
};

AppContainerPublicBase.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
};

AppContainerPublicBase.defaultProps = {
    children: null,
};

const mapStateToProps = state => {
    const isLogged = selectors.isLogged(state);

    return { isLogged };
};

const AppContainerPublic = connect(mapStateToProps)(AppContainerPublicBase);
export { AppContainerPublic };
