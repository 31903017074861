import {
    DELETE_VIDEO,
    GET_VIDEO_GALLERY,
    GET_VIDEO_UPLOADS,
    NEW_VIDEO_UPLOAD,
    PREDICT_GALLERY_VIDEO,
    SET_VIDEO_UPLOADS,
    UPDATE_VIDEO_GALLERY,
    UPDATE_VIDEO_GALLERY_ITEM,
    UPDATE_VIDEO_UPLOADS,
    UPLOAD_VIDEO,
    UPLOAD_VIDEO_REQUEST,
    videoPrefixes,
} from './actionTypes/video';

const wrapAction = (action, prefix) => {
    return `${prefix}_${action}`;
};

const actionsFactory = type => {
    const UPDATE_VIDEO_UPLOADS_ACTION = wrapAction(UPDATE_VIDEO_UPLOADS, type);
    const NEW_VIDEO_UPLOAD_ACTION = wrapAction(NEW_VIDEO_UPLOAD, type);
    const SET_VIDEO_UPLOADS_ACTION = wrapAction(SET_VIDEO_UPLOADS, type);
    const UPDATE_VIDEO_GALLERY_ACTION = wrapAction(UPDATE_VIDEO_GALLERY, type);
    const UPLOAD_VIDEO_REQUEST_ACTION = wrapAction(UPLOAD_VIDEO_REQUEST, type);
    const UPLOAD_VIDEO_ACTION = wrapAction(UPLOAD_VIDEO, type);
    const UPDATE_VIDEO_GALLERY_ITEM_ACTION = wrapAction(UPDATE_VIDEO_GALLERY_ITEM, type);
    const GET_VIDEO_GALERY_ACTION = wrapAction(GET_VIDEO_GALLERY, type);
    const GET_VIDEO_UPLOADS_ACTION = wrapAction(GET_VIDEO_UPLOADS, type);
    const PREDICT_GALLERY_VIDEO_ACTION = wrapAction(PREDICT_GALLERY_VIDEO, type);
    const DELETE_VIDEO_ACTION = wrapAction(DELETE_VIDEO, type);

    const updateVideoUploads = (index, update) => ({
        type: UPDATE_VIDEO_UPLOADS_ACTION,
        payload: { index, update },
    });

    const newVideoUpload = upload => ({
        type: NEW_VIDEO_UPLOAD_ACTION,
        payload: { upload },
    });

    const setVideoUploads = uploads => ({
        type: SET_VIDEO_UPLOADS_ACTION,
        payload: { uploads },
    });

    const updateVideoGallery = gallery => ({
        type: UPDATE_VIDEO_GALLERY_ACTION,
        payload: { gallery },
    });

    const updateVideoGalleryItem = (folder, name, prediction, predictionType) => ({
        type: UPDATE_VIDEO_GALLERY_ITEM_ACTION,
        payload: { folder, name, prediction, predictionType },
    });

    const uploadVideoRequest = params => ({ type: UPLOAD_VIDEO_REQUEST_ACTION, params });

    const uploadVideo = videos => {
        return {
            type: UPLOAD_VIDEO_ACTION,
            payload: { videos },
        };
    };

    const getGallery = (ignoreCache = false) => {
        return {
            type: GET_VIDEO_GALERY_ACTION,
            payload: { ignoreCache },
        };
    };

    const getUploads = () => {
        return {
            type: GET_VIDEO_UPLOADS_ACTION,
        };
    };

    const predictGalleryVideo = payload => {
        return {
            type: PREDICT_GALLERY_VIDEO_ACTION,
            payload,
        };
    };
    const deleteVideo = (id, predictionType) => {
        return {
            type: DELETE_VIDEO_ACTION,
            payload: { id, predictionType },
        };
    };
    return {
        updateVideoUploads,
        setVideoUploads,
        updateVideoGallery,
        updateVideoGalleryItem,
        uploadVideoRequest,
        uploadVideo,
        getGallery,
        getUploads,
        predictGalleryVideo,
        deleteVideo,
        newVideoUpload,
    };
};

export const actions = {
    videoTaggingActions: actionsFactory(videoPrefixes.tagging),
    videoFaceRecognitionActions: actionsFactory(videoPrefixes.faceRecognition),
    videoShotDetectionActions: actionsFactory(videoPrefixes.shotDetection),
    videoHighlightsActions: actionsFactory(videoPrefixes.highlights),
};
