import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {
    authEn,
    commonEn,
    conceptsEn,
    dashboardEn,
    earthObservationEn,
    imageDemoEn,
    searchEn,
    shareEn,
    usersEn,
    videoEn,
    industriesEn,
} from './translations/en';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: {
            en: {
                common: commonEn,
                concepts: conceptsEn,
                imageDemo: imageDemoEn,
                share: shareEn,
                video: videoEn,
                users: usersEn,
                dashboard: dashboardEn,
                auth: authEn,
                search: searchEn,
                eo: earthObservationEn,
                industries: industriesEn,
                // refactor to namespaces, than it can be removed
                translation: {
                    common: commonEn,
                    concepts: conceptsEn,
                    imageDemo: imageDemoEn,
                    share: shareEn,
                    video: videoEn,
                    users: usersEn,
                    dashboard: dashboardEn,
                    auth: authEn,
                    search: searchEn,
                    eo: earthObservationEn,
                    industries: industriesEn,
                },
            },
        },
        debug: false,
        lng: 'en',
        fallbackLng: 'en',
    });

// eslint-disable-next-line import/no-default-export
export default i18n;
