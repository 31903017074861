export const GOTO_PAGE = 'app/router/go to page';
export const GO_BACK_PAGE = 'app/router/go back page';
export const PAGE_TRANSITION = 'app/router/page transition';

export const MODAL_OPEN = 'app/modal/open';
export const MODAL_CLOSE = 'app/modal/close';

export const SET_DRAWER_OPEN = 'app/drawer/open';
export const SET_DRAWER_CLOSE = 'app/drawer/close';

export const SET_CURRENT_PAGE = 'app/router/set current page';
export const SET_FEATURES = 'SET_FEATURES';

export const SET_APP_CONFIG = 'SET_APP_CONFIG';

export const APPLICATION_RELOAD = 'APPLICATION_RELOAD';
