import { FormControl, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { isEmail } from '../../../utils/validators';

const EmailFormControl = ({
    disabled,
    onEnter,
    username,
    onChangeUsername,
    skipValidation,
    placeholder = 'Email',
}) => {
    const setUsername = e => {
        const email = e.currentTarget.value ? e.currentTarget.value.trim() : '';
        onChangeUsername({
            value: email,
            error: skipValidation ? false : !isEmail(email),
            message: username.message,
        });
    };

    return (
        <FormControl margin="dense" disabled={disabled} fullWidth>
            <TextField
                data-test="login-username"
                inputProps={{
                    onKeyUp: onEnter,
                }}
                id="username"
                name="username"
                placeholder={placeholder}
                autoComplete="username"
                autoFocus
                value={username.value}
                onChange={setUsername}
                helperText={username.message}
                error={username.error}
            />
        </FormControl>
    );
};

EmailFormControl.propTypes = {
    onEnter: PropTypes.func.isRequired,
    onChangeUsername: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    username: PropTypes.object.isRequired,
    skipValidation: PropTypes.bool,
};

EmailFormControl.defaultProps = {
    disabled: false,
    skipValidation: false,
};

export { EmailFormControl };
