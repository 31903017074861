import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { SearchUploadStatusPopover } from '../components/Popovers/SearchUploadStatusPopover';
import { ShareClipboardPopover } from '../components/Popovers/ShareClipboardPopover';
import { TaskPopover } from '../components/Popovers/TaskPopover';
import { TrainSearchIndexPopover } from '../components/Popovers/TrainSearchIndexPopover';
import { UploadPopover } from '../components/Popovers/UploadPopover';
import { useIsTemporaryLeftDrawer } from '../hooks/mobile';
import { selectors, actions } from '../redux';
import { matchMobileOrTablet } from '../utils/markupHelpers';
import { DialogContainer } from './DialogContainer';
import Sidebar from '../components/Sidebar';
const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
    },
    appContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    contentContainer: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        [matchMobileOrTablet(theme)]: {
            width: '100%',
        },
    },
    contentContainerShift: {
        // width: `calc(100% - ${259 - 24}px)`,
    },
    content: {
        position: 'relative',
        flexGrow: 1,
        height: '100%',
        overflowX: 'hidden',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

const AppContainerBase = props => {
    const classes = useStyles();

    const { children, isLogged, isDrawerOpen } = props;

    const isTemporaryLeftDrawer = useIsTemporaryLeftDrawer();

    return (
        <div className={classes.root}>
            {isLogged && (
                <div className={classes.appContainer}>
                    <Sidebar />
                    <div
                        className={clsx(classes.contentContainer, {
                            [classes.contentContainerShift]: isTemporaryLeftDrawer
                                ? false
                                : isDrawerOpen,
                        })}
                    >
                        <div
                            className={clsx(classes.content, {
                                [classes.contentShift]: isTemporaryLeftDrawer
                                    ? false
                                    : isDrawerOpen,
                            })}
                        >
                            {children}
                            <DialogContainer />
                            <TaskPopover />
                            <UploadPopover />
                            <ShareClipboardPopover />
                            <TrainSearchIndexPopover />
                            <SearchUploadStatusPopover />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

AppContainerBase.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    isLogged: PropTypes.bool.isRequired,
    isDrawerOpen: PropTypes.bool.isRequired,
    openDrawer: PropTypes.func.isRequired,
    closeDrawer: PropTypes.func.isRequired,
};

AppContainerBase.defaultProps = {
    children: null,
};

const mapStateToProps = state => {
    const isLogged = selectors.isLogged(state);
    const isDrawerOpen = selectors.isDrawerOpen(state);

    return {
        isLogged,
        isDrawerOpen,
        appConfig: state.app.config,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openDrawer: () => dispatch(actions.openDrawer()),
        closeDrawer: () => dispatch(actions.closeDrawer()),
        logout: () => dispatch(actions.logout()),
    };
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(AppContainerBase);
export { AppContainer };
