import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const FormContainer = withStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'center',
    },
}))(({ classes, children }) => <Box className={classes.root}>{children}</Box>);

export { FormContainer };
